import { makeStyles } from 'tss-react/mui'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import AnimateHeight, { Height } from 'react-animate-height'

const animDurationMs = 200

const useStyles = makeStyles<{
  overflow: 'visible' | 'hidden' | 'auto'
  animateHeight: boolean
}>()((theme, { overflow, animateHeight }) => ({
  heightAnimate: {
    overflow: overflow + ' !important',
  },
  root: {
    position: 'relative',
    overflow: overflow,
  },
  enter: {
    position: 'relative',
    opacity: 0,
    transform: 'scale(0.98)',
  },
  enterActive: {
    position: 'relative',
    opacity: 1,
    transform: 'scale(1)',
    transition: `opacity ${animDurationMs / 1000}s ease-in-out, transform ${animDurationMs / 1000}s ease-in-out`,
  },
  enterDone: {
    position: 'relative',
    transform: 'scale(1)',
  },
  exit: {
    position: 'absolute !important' as any,
    top: 0,
    width: '100%',
    margin: '0 auto',
    opacity: 1,
    transform: 'scale(1)',
  },
  exitActive: {
    position: 'absolute !important' as any,
    opacity: 0,
    transform: 'scale(0.98)',
    transition: `opacity ${animDurationMs / 1000}s ease-in-out, transform ${animDurationMs / 1000}s ease-in-out`,
    height: animateHeight ? 'auto' : '100%',
  },
  exitDone: {
    position: 'absolute !important' as any,
    opacity: 0,
    transform: 'scale(0.98)',
  },
}))

/**
 * Generates a relatively unique hash for any JavaScript variable
 * @param {any} variable - The variable to hash (can be any type)
 * @return {string} A string representation of the hash
 */
function generateSimpleHash(variable) {
  // Convert the variable to a string representation
  let str

  if (variable === null || variable === undefined) {
    return String(variable)
  } else if (typeof variable === 'object') {
    try {
      // For objects, arrays, etc., convert to JSON and add type info
      str = JSON.stringify(variable) + Object.prototype.toString.call(variable)
    } catch (e) {
      // If JSON conversion fails (circular references, etc.)
      str = String(variable) + Object.keys(variable).join(',')
    }
  } else {
    // For primitives, convert to string
    str = String(variable)
  }

  // Simple hash function (djb2-like algorithm)
  let hash = 5381
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i)
    hash = hash & hash // Convert to 32-bit integer
  }

  // Convert to a hex string
  return (hash >>> 0).toString(16)
}

interface AnimatedStateContentProps {
  getContent: (state) => React.JSX.Element
  state: any
  animateHeight: boolean
  overflow?: 'visible' | 'hidden' | 'auto'
}

const AnimatedStateContent = ({
  getContent,
  state,
  animateHeight = true,
  overflow = 'visible',
}: AnimatedStateContentProps) => {
  const { classes } = useStyles({ overflow, animateHeight })

  const [height, setHeight] = useState<Height>('auto')
  const contentDiv = useRef<HTMLDivElement | null>(null)

  const [animating, setAnimating] = useState(false)

  useEffect(() => {
    if (!animateHeight) return
    const element = contentDiv.current as HTMLDivElement

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight)
    })

    resizeObserver.observe(element)

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    setAnimating(true)
    setTimeout(() => setAnimating(false), animDurationMs)
  }, [state])

  const stateKey = useMemo(() => {
    return generateSimpleHash(state)
  }, [state])

  if (!animateHeight)
    return (
      <TransitionGroup component={null}>
        <CSSTransition
          key={stateKey}
          timeout={animDurationMs}
          classNames={{
            enterActive: classes.enterActive,
            enterDone: classes.enterDone,
            enter: classes.enter,
            exit: classes.exit,
            exitActive: classes.exitActive,
            exitDone: classes.exitDone,
          }}
        >
          {getContent(state)}
        </CSSTransition>
      </TransitionGroup>
    )

  return (
    <AnimateHeight
      className={classes.heightAnimate}
      duration={animating ? animDurationMs : 0}
      height={animateHeight ? height : 'auto'}
      contentRef={contentDiv}
      contentClassName={classes.root}
      disableDisplayNone
    >
      <TransitionGroup component={null}>
        <CSSTransition
          key={stateKey}
          timeout={animDurationMs}
          classNames={{
            enterActive: classes.enterActive,
            enterDone: classes.enterDone,
            enter: classes.enter,
            exit: classes.exit,
            exitActive: classes.exitActive,
            exitDone: classes.exitDone,
          }}
        >
          {getContent(state)}
        </CSSTransition>
      </TransitionGroup>
    </AnimateHeight>
  )
}

export default AnimatedStateContent
