import { Box, Button, SvgIcon, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  openInviteGuestsModal,
  openModal,
  openScanner,
  setModalPage,
} from '../../../actions/modals'
import { getCurrentEvent } from '../../../selectors/event'
import { Add, PeopleAlt, PersonAdd, QrCodeScanner } from '@mui/icons-material'
import {
  mdiAccountCheck,
  mdiAccountClock,
  mdiBellCheck,
  mdiCurrencyEur,
  mdiFire,
  mdiTicket,
} from '@mdi/js'
import React from 'react'
import { guestManagementBreakpoint } from './GuestManagementModal'
import { EURO } from '../../EventCreation/TicketOption'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../../shared-components/buttons/BashButton'
import Row from '../../../components/Row'
import Column from '../../../components/Column'
import BashIconButton from 'shared-components/buttons/BashIconButton'

const GuestManagementMenuButton = ({ page, icon, count, disabled }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const activePage = useSelector(
    (state: any) => state.modals.page.guestManagement,
  )
  const pageForCopy = page === 'preRegister' ? 'waitingList' : page

  const onClick = () => {
    dispatch(setModalPage('guestManagement', page))
  }

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={(theme) => ({
        borderRadius: 1,
        p: 1.5,
        width: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        justifyContent: 'start',
        '& svg': {
          width: 20,
          height: 20,
        },
        ...(page === activePage && {
          background: `${theme.palette.grey[150]} !important`,
        }),
        ...(disabled && {
          color: `${theme.palette.grey[400]} !important`,
        }),
      })}
    >
      {icon}
      {`${t(`guestManagement.${pageForCopy}`)}${count != null && (typeof count !== 'number' || count > 0) ? ` (${count})` : ''}`}
    </Button>
  )
}

const GuestManagementMenu = ({}) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const isMobileApp = useSelector((state: any) => state.user.isMobileApp)

  const joinOptionsDisabled = !event.hasJoinOptions
  const showApproval =
    !joinOptionsDisabled && event.joinOptions?.some((jo) => jo.approvalRequired)
  const showWaitlist =
    !joinOptionsDisabled &&
    (event.statusCountsHost?.waitingList > 0 ||
      !event.joinOptions?.some((jo) => jo.available))
  const showHype = event.privacyType === 'PUBLIC'
  const showRevenue =
    !joinOptionsDisabled &&
    event.joinOptions?.some((jo) => jo.chooseYourOwnPrice || jo.price > 0)

  const isEventix = event.joinOptions?.every(
    (option) => option.idInfo?.source === 'EVENTIX',
  )

  const inviteClicked = () => {
    dispatch(openInviteGuestsModal())
  }

  const guestListClicked = () => {
    dispatch(openModal('guestList'))
  }

  const checkInClicked = () => {
    if (document && isMobileApp) {
      const customEvent = new CustomEvent('BashScan', {
        cancelable: true,
        detail: {
          eventId: event.id,
        },
      })
      document.dispatchEvent(customEvent)
    } else {
      dispatch(openScanner())
    }
  }

  return (
    <Column>
      <Column sx={{ p: 1.5, gap: 1.25 }}>
        <Row sx={{ alignItems: 'center', gap: 1.5, overflowX: 'auto' }}>
          <BashButton
            type={BashButtonType.PRIMARY_LIGHT}
            onClick={inviteClicked}
            extraView={<PersonAdd />}
            viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          >
            {t('guestManagement.invite')}
          </BashButton>

          <BashButton
            type={BashButtonType.PRIMARY_LIGHT}
            onClick={guestListClicked}
            extraView={<Add />}
            viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          >
            {isMobileApp ? t('guestListLiteral') : t('addToGuestlist')}
          </BashButton>

          {isMobileApp && (
            <BashIconButton
              type={BashButtonType.PRIMARY_LIGHT}
              onClick={checkInClicked}
            >
              <QrCodeScanner />
            </BashIconButton>
          )}
        </Row>
      </Column>

      <Box
        sx={(theme) => ({
          width: 1,
          height: '1px',
          background: theme.palette.divider,
          [theme.breakpoints.down(guestManagementBreakpoint)]: {
            background: theme.palette.grey[100],
            height: theme.spacing(1),
          },
        })}
      />

      <Column sx={{ p: 1.5, gap: 1.25 }}>
        <GuestManagementMenuButton
          page={'guestList'}
          count={
            (joinOptionsDisabled
              ? event.statusCountsHost?.going
              : event.statusCountsHost?.totalGoing) +
            event.statusCountsHost?.maybe +
            event.statusCountsHost?.cant +
            (event.type === 'PINNING'
              ? event.statusCountsHost?.gaveAvailability
              : 0)
          }
          icon={<PeopleAlt />}
          disabled={false}
        />
      </Column>

      <Box
        sx={(theme) => ({
          width: 1,
          height: '1px',
          background: theme.palette.divider,
          [theme.breakpoints.down(guestManagementBreakpoint)]: {
            background: theme.palette.grey[100],
            height: 8,
          },
        })}
      />

      <Column sx={{ p: 1.5, gap: 1.25 }}>
        {showApproval && (
          <GuestManagementMenuButton
            page={'approvalRequested'}
            count={event.statusCountsHost?.requested}
            icon={
              <SvgIcon>
                <path d={mdiAccountCheck} />
              </SvgIcon>
            }
            disabled={false}
          />
        )}

        <GuestManagementMenuButton
          page={'invitePending'}
          count={event.statusCountsHost?.invited}
          icon={
            <SvgIcon>
              <path d={mdiAccountClock} />
            </SvgIcon>
          }
          disabled={false}
        />

        {showWaitlist && (
          <GuestManagementMenuButton
            page={'preRegister'}
            count={event.statusCountsHost?.waitingList}
            icon={
              <SvgIcon>
                <path d={mdiBellCheck} />
              </SvgIcon>
            }
            disabled={false}
          />
        )}

        {showHype && (
          <GuestManagementMenuButton
            page={'hype'}
            count={event.statusCountsHost?.interested}
            icon={
              <SvgIcon>
                <path d={mdiFire} />
              </SvgIcon>
            }
            disabled={false}
          />
        )}
      </Column>

      <Box
        sx={(theme) => ({
          width: 1,
          height: '1px',
          background: theme.palette.divider,
          [theme.breakpoints.down(guestManagementBreakpoint)]: {
            background: theme.palette.grey[100],
            height: theme.spacing(1),
          },
        })}
      />

      <Column sx={{ p: 1.5, gap: 1.25 }}>
        <GuestManagementMenuButton
          disabled={joinOptionsDisabled}
          page={joinOptionsDisabled ? 'joinOptionsDisabled' : 'joinOptions'}
          count={
            event.statusCountsHost?.guestListTickets > 0
              ? event.joinOptions?.length + 1
              : event.joinOptions?.length
          }
          icon={
            <SvgIcon>
              <path d={mdiTicket} />
            </SvgIcon>
          }
        />
        {showRevenue && (
          <Tooltip
            title={isEventix ? 'View revenue on the Weeztix dashboard' : ''}
          >
            <Box
              component='span'
              sx={{
                width: 1,
                cursor: isEventix ? 'not-allowed' : 'default',
              }}
            >
              <GuestManagementMenuButton
                disabled={isEventix}
                page={'revenue'}
                count={
                  event.ticketStatsHost?.totalRevenue != null &&
                  event.ticketStatsHost?.totalRevenue > 0
                    ? EURO.format(event.ticketStatsHost.totalRevenue / 100)
                    : null
                }
                icon={
                  <SvgIcon>
                    <path d={mdiCurrencyEur} />
                  </SvgIcon>
                }
              />
            </Box>
          </Tooltip>
        )}
      </Column>
    </Column>
  )
}

export default GuestManagementMenu
