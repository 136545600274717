// Copied and modified from material-ui lab

import * as React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { openGuestModal } from 'actions/modals'
import { useDispatch } from 'react-redux'
import { headerFontFamily } from '../typography'
import cn from 'clsx'

const SPACINGS = {
  small: -16,
  medium: null,
}

const useStyles = makeStyles()((theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
  },
  /* Styles applied to the avatar elements. */
  avatar: {
    marginLeft: -8,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  avatarSmallGap: {
    marginLeft: -4,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  avatarClass: {
    border: `1px solid ${theme.palette.background.paper}`,
  },
  avatarClassMedium: {
    border: `1px solid ${theme.palette.background.paper}`,
  },
  greyBorder: {
    borderColor: theme.palette.grey[150],
  },
  lastAvatar: {
    marginLeft: -8,
    position: 'relative',
  },
  lastAvatarSmallGap: {
    marginLeft: -4,
  },
  lastAvatarOverlay: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '100%',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    cursor: 'pointer',
    border: `2px solid ${theme.palette.background.paper}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreButton: {
    color: 'white',
    width: 16,
    height: 16,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  moreButtonMedium: {
    width: 24,
    height: 24,
  },
  moreButtonText: {
    fontFamily: headerFontFamily,
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '11.93px',
    letterSpacing: '0.2px',
    textAlign: 'left',
    color: 'white',
  },
}))

function AvatarGroup(props) {
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const {
    children,
    className,
    max = 5,
    spacing = 'medium',
    size = 'small',
    noOverflow = false,
    treatments,
    isReady,
    respondedCount,
    greyBorder = false,
    smallGap = false,
    ...other
  } = props
  const clampedMax = max < 2 ? 2 : max

  const extraAvatars =
    children.length > clampedMax ? children.length - clampedMax + 1 : 0
  const endAvatarIdx = children.length - extraAvatars

  const marginLeft =
    spacing && SPACINGS[spacing] !== undefined ? SPACINGS[spacing] : -spacing

  return (
    (<div className={cn(classes.root, className)} {...other}>
      {children.slice(0, endAvatarIdx).map((child, index) =>
        React.cloneElement(child, {
          className: cn(
            child.props.className,
            smallGap ? classes.avatarSmallGap : classes.avatar,
          ),
          key: index,
          // avatarClass: cx(
          //   size === 'medium' ? classes.avatarClassMedium : classes.avatarClass,
          //   greyBorder && classes.greyBorder,
          // ),
          style: {
            // zIndex: children.length - index,
            marginLeft: index === 0 ? undefined : marginLeft,
            ...child.props.style,
          },
        }),
      )}
      {extraAvatars ? (
        <div
          className={smallGap ? classes.lastAvatarSmallGap : classes.lastAvatar}
          onClick={() => dispatch(openGuestModal())}
        >
          {React.cloneElement(children[endAvatarIdx], {
            className: cn(
              children[endAvatarIdx].props.className,
              classes.avatar,
            ),
            // avatarClass:
            //   size === 'medium'
            //     ? classes.avatarClassMedium
            //     : classes.avatarClass,
            style: {
              zIndex: 0,
              marginLeft,
              ...children[endAvatarIdx].props.style,
            },
          })}
          {!noOverflow && (
            <div className={classes.lastAvatarOverlay}>
              <Typography className={classes.moreButtonText}>
                +{respondedCount - max + 1}
              </Typography>
            </div>
          )}
        </div>
      ) : null}
    </div>)
  )
}

export default AvatarGroup
