import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import MixpanelLinkify from 'shared-components/common/MixpanelLinkify'
import { Checkbox } from '@mui/material'
import { BooleanQuestionProps } from './types'

const useStyles = makeStyles()((theme: Theme) => ({
  questionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  questionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  erroredTitleContainer: {
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.red.main}`,
  },
  questionTitle: {
    lineHeight: '16.71px',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  checkBox: {
    color: theme.palette.text.tertiary,
    padding: theme.spacing(1),
  },
}))

type QuestionContainerProps = Partial<BooleanQuestionProps> & {
  title: string
  required?: boolean
  isError?: boolean
  children?: React.ReactNode
}

const QuestionContainer: React.FC<QuestionContainerProps> = ({
  title,
  required,
  isBoolean,
  isError,
  booleanValue,
  onBooleanChange,
  children,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div className={classes.questionContainer}>
      <div
        onClick={isBoolean ? onBooleanChange : undefined}
        className={cx(
          classes.questionTitleContainer,
          isError && classes.erroredTitleContainer,
        )}
        style={{
          cursor: isBoolean ? 'pointer' : 'default',
        }}
      >
        {isBoolean && (
          <Checkbox
            checked={booleanValue}
            className={classes.checkBox}
            color='primary'
          />
        )}
        <Typography variant='body1' className={classes.questionTitle}>
          <MixpanelLinkify>
            {title} {required && '*'}
          </MixpanelLinkify>
        </Typography>
      </div>
      {children}
    </div>
  )
}

export default QuestionContainer
