import {
  Aref_Ruqaa,
  Caveat_Brush,
  Cherry_Bomb_One,
  Limelight,
  Open_Sans,
  Orbitron,
  Original_Surfer,
  Pirata_One,
  Playfair_Display,
  Raleway,
  Ubuntu,
  Yeseva_One,
} from 'next/font/google'
import localFont from 'next/font/local'

export const playfairDisplay = Playfair_Display({
  subsets: ['latin'],
  weight: ['400', '700', '800'],
})

export const raleway = Raleway({
  subsets: ['latin'],
  weight: ['400', '700'],
})

export const orbitron = Orbitron({
  subsets: ['latin'],
})

export const ubuntu = Ubuntu({
  subsets: ['latin'],
  weight: ['400', '700'],
})

export const yesevaOne = Yeseva_One({
  subsets: ['latin'],
  weight: ['400'],
})

export const limelight = Limelight({
  subsets: ['latin'],
  weight: ['400'],
})

export const caveatBrush = Caveat_Brush({
  subsets: ['latin'],
  weight: ['400'],
})

export const originalSurfer = Original_Surfer({
  subsets: ['latin'],
  weight: ['400'],
})

export const pirataOne = Pirata_One({
  subsets: ['latin'],
  weight: ['400'],
})

export const arefRuqaa = Aref_Ruqaa({
  subsets: ['latin'],
  weight: ['400', '700'],
})

export const cherryBombOne = Cherry_Bomb_One({
  subsets: ['latin'],
  weight: ['400'],
})

export const gintoBlack = localFont({
  src: './GintoBlack.otf',
  variable: '--font-ginto-black',
  display: 'swap',
})

// export const solarDisplay = localFont({
//   src: './SolarDisplay.woff2',
// })

export const openSans = Open_Sans({
  subsets: ['latin'],
  weight: ['400', '700'],
})

export const nextFonts = {
  'Playfair Display': playfairDisplay,
  raleway: raleway,
  Orbitron: orbitron,
  Ubuntu: ubuntu,
  'Yeseva One': yesevaOne,
  Limelight: limelight,
  'Caveat Brush': caveatBrush,
  'Original Surfer': originalSurfer,
  'Pirata One': pirataOne,
  'Aref Ruqaa': arefRuqaa,
  'Cherry Bomb One': cherryBombOne,
  'Ginto Black': gintoBlack,
  'Open Sans': openSans,
  // 'Solar Display': solarDisplay,
}

// Function to map backend font data to Next.js font instances
export const mapBackendFontToNext = (backendFont) => {
  const nextFont = nextFonts[backendFont.fontDisplay]
  if (!nextFont) {
    console.warn(`Font ${backendFont.name} not found in Next.js fonts`)
    return backendFont
  }

  return {
    ...backendFont,
    className: nextFont.className,
    size: backendFont.fontDisplaySize,
  }
}

// Function to enhance the backend fonts array
export const enhanceBackendFonts = (backendFonts) => {
  return backendFonts.map(mapBackendFontToNext)
}
