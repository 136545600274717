import * as React from 'react'
import Transition from 'react-transition-group/Transition'
import { useForkRef } from '@mui/material'
import getReactElementRef from '@mui/utils/getReactElementRef'

// Default CSS properties
const defaultStyle = {
  position: 'fixed',
  transform: 'translateY(30px) translateX(-50%)', // Initial off-screen position
  opacity: 0,
  overflow: 'hidden',
  left: '50%',
  width: '100%',
  transition: 'transform 150ms ease, opacity 100ms ease', // Smooth transition
}

// Custom styles for the four states: entering, entered, exiting, exited
const styles = {
  entering: {
    opacity: 1,
    transform: 'translateY(0) translateX(-50%)',
  },
  entered: {
    opacity: 1,
    transform: 'translateY(0) translateX(-50%)',
  },
  exiting: {
    opacity: 0,
    transform: 'translateY(30px) translateX(-50%)',
  },
  exited: {
    opacity: 0,
    transform: 'translateY(30px) translateX(-50%)',
  },
}

const FullScreenSheetTransition = React.forwardRef((props, ref) => {
  const { children, style: styleProp, ...other } = props

  const childrenRef = React.useRef(null)
  const handleRef = useForkRef(getReactElementRef(children), childrenRef, ref)

  const combinedStyle = {
    ...styleProp,
    ...(React.isValidElement(children) ? children.props.style : {}),
  }

  return (
    <Transition nodeRef={childrenRef} timeout={150} {...other}>
      {(state, childProps) =>
        React.cloneElement(children, {
          ref: handleRef, // Ensure the ref is correctly forwarded
          style: {
            ...defaultStyle, // Base styles
            ...styles[state], // Styles based on transition state
            ...combinedStyle, // Additional styles passed to the component
          },
          ...childProps,
        })
      }
    </Transition>
  )
})

export default FullScreenSheetTransition