import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Radio, Select, MenuItem, Typography } from '@mui/material'
import QuestionContainer from './QuestionContainer'
import WizardTextField from '../WizardTextField'
import { useTranslation } from 'react-i18next'
import { ChoiceQuestionProps } from './types'

const useStyles = makeStyles()((theme) => ({
  select: {
    width: '100%',
    backgroundColor: theme.palette.background.input,
    borderRadius: 6,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  option: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  radio: {
    color: theme.palette.text.tertiary,
    padding: theme.spacing(1),
  },
  optionText: {
    lineHeight: '21px',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}))

const RadioQuestion: React.FC<ChoiceQuestionProps> = ({
  title,
  required,
  isError,
  options,
  value,
  onChange,
  allowOther,
  otherValue,
  onOtherChange,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  const useSelect = options.length > 4

  if (useSelect) {
    return (
      <QuestionContainer title={title} required={required} isError={isError}>
        <Select
          className={classes.select}
          value={otherValue?.checked ? '_other_' : (value[0] ?? '')}
          onChange={(e) => {
            if (e.target.value === '_other_') {
              onOtherChange?.({
                checked: true,
                text: '',
              })
              onChange(null)
            } else {
              onOtherChange?.({
                checked: false,
                text: '',
              })
              onChange(e.target.value)
            }
          }}
          displayEmpty
        >
          <MenuItem value=''>
            <em>{t('questions.selectOption')}</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.translated}
            </MenuItem>
          ))}
          {allowOther && (
            <MenuItem value='_other_'>{t('questions.other')}</MenuItem>
          )}
        </Select>
        {allowOther && otherValue?.checked && (
          <WizardTextField
            placeholder={t('questions.otherHint')}
            value={otherValue.text}
            full
            noMargin
            onChange={(e) =>
              onOtherChange?.({
                checked: true,
                text: e.target.value,
              })
            }
          />
        )}
      </QuestionContainer>
    )
  }

  return (
    <QuestionContainer title={title} required={required} isError={isError}>
      <div className={classes.optionsContainer}>
        {options.map((option, index) => (
          <div
            key={index}
            className={classes.option}
            onClick={() => onChange(option.id)}
          >
            <Radio
              checked={value.includes(option.id)}
              className={classes.radio}
              color='primary'
            />
            <Typography variant='body1' className={classes.optionText}>
              {option.translated}
            </Typography>
          </div>
        ))}
        {allowOther && (
          <div className={classes.option}>
            <Radio
              checked={otherValue?.checked ?? false}
              className={classes.radio}
              color='primary'
              onClick={() => {
                onOtherChange?.({
                  checked: !otherValue?.checked,
                  text: otherValue?.text ?? '',
                })
                onChange(null)
              }}
            />
            <Typography
              className={classes.optionText}
              variant='body1'
              onClick={() => {
                onOtherChange?.({
                  checked: !otherValue?.checked,
                  text: otherValue?.text ?? '',
                })
                onChange(null)
              }}
            >
              {t('questions.other')}
            </Typography>
            {otherValue?.checked && (
              <WizardTextField
                placeholder={t('questions.otherHint')}
                value={otherValue.text}
                full
                noMargin
                onChange={(e) =>
                  onOtherChange?.({
                    checked: true,
                    text: e.target.value,
                  })
                }
              />
            )}
          </div>
        )}
      </div>
    </QuestionContainer>
  )
}

export default RadioQuestion
