import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import FlatButton from '../../../shared-components/buttons/FlatButton'
import BottomSheet from '../../common/BottomSheet'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(2),
    position: 'relative',
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.grey[800],
  },
  backText: {
    fontSize: '1.25rem',
    color: theme.palette.grey[800],
  },
  title: {
    top: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
  },
  wallet: {
    top: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
    cursor: 'pointer',
  },
  lightButton: {
    width: 40,
    height: 40,
    // backgroundColor: theme.palette.primary[100],
    color: '#111',
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary[100],
    },
    marginLeft: 'auto',
  },
  menuList: {
    padding: 0,
    '& > *': {
      padding: theme.spacing(2),
    },
  },
  dialogButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2.5),
  },
  cancelButton: {
    borderRadius: 100,
    border: `1px solid ${'#2E60DC'}`,
    color: '#2E60DC',
    height: 40,
    backgroundColor: theme.palette.background.paper,
  },
  confirmButton: {
    borderRadius: 100,
    border: `1px solid ${'#EE6C4D'}`,
    color: '#EE6C4D',
    height: 40,
    backgroundColor: theme.palette.background.paper,
  },
  refundPolicy: {
    padding: theme.spacing(1.5),
    background: theme.palette.grey[150],
    marginTop: theme.spacing(1),
    borderRadius: 8,
  },
}))

type SheetType = 'remove-me' | 'remove-me-with-tickets' | 'cancel-ticket'

interface RemoveMeOrTicketSheetProps {
  open: boolean
  onClose: () => void
  hostName: string
  refundPolicy: string | null | undefined
  loading: boolean
  onConfirm: () => void
  type: 'remove-me' | 'remove-me-with-tickets' | 'cancel-ticket'
}

type Copy = Record<SheetType, string>

const titleCopy: Copy = {
  'remove-me': 'areYouSureRemove',
  'remove-me-with-tickets': 'areYouSureRemove',
  'cancel-ticket': 'cancelTicket.sheetTitle',
}

const captionCopy: Copy = {
  'remove-me': 'removeMeFromEventConfirm',
  'remove-me-with-tickets': 'removeMeFromEventConfirmTickets',
  'cancel-ticket': 'cancelTicket.sheetCaption',
}

export const RemoveMeOrTicketSheet = ({
  open,
  onClose,
  onConfirm,
  loading,
  hostName,
  refundPolicy,
  type,
}: RemoveMeOrTicketSheetProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const revokingTickets =
    type === 'cancel-ticket' || type === 'remove-me-with-tickets'
  const removingMe = type === 'remove-me' || type === 'remove-me-with-tickets'
  return (
    <BottomSheet title={t(titleCopy[type])} open={open} onClose={onClose}>
      <Typography variant='body1' style={{ marginTop: -8, lineHeight: '24px' }}>
        {t(captionCopy[type])}
      </Typography>

      {revokingTickets && (
        <>
          <Typography variant='caption' style={{ marginTop: 8 }}>
            <Trans
              t={t}
              i18nKey={'cancelTicket.hostsRefundPolicy'}
              components={{
                b: <b />,
              }}
              values={{
                hostName,
              }}
            />
          </Typography>

          <Typography variant='caption' className={classes.refundPolicy}>
            {refundPolicy != null && refundPolicy.trim() !== '' ? (
              <i>“{refundPolicy}”</i>
            ) : (
              t('cancelTicket.noRefundPolicy')
            )}
          </Typography>
        </>
      )}

      <div className={classes.dialogButtons}>
        <FlatButton className={classes.cancelButton} onClick={onClose}>
          {t('cancelTicket.no')}
        </FlatButton>

        <FlatButton
          className={classes.confirmButton}
          onClick={onConfirm}
          disabled={loading}
          loading={loading}
        >
          {removingMe ? t('yesRemoveMe') : t('cancelTicket.confirm')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}
