import { makeStyles } from 'tss-react/mui'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import FullScreenSheet from './FullScreenSheet'
import EmbedEventCard from './EmbedEventCard'
import EmbedEventQR from './EmbedEventQR'
import EmbedEventButton from './EmbedEventButton'
import AnimatedStateContent from '../AnimatedStateContent'

const useStyles = makeStyles()((theme) => ({
  paper: {
    maxWidth: 642,
    overflow: 'hidden',
  },
  root: {
    padding: theme.spacing(3),
    overflowY: 'auto',
  },
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
  tabButton: {
    padding: theme.spacing(1),
    borderRadius: 0,
    fontWeight: 400,
    borderBottom: '1px solid transparent',
  },
  tabButtonSelected: {
    borderBottom: `1px solid ${theme.palette.grey[900]}`,
    fontWeight: 600,
  },
  tabButtonLabel: {},
}))

type EmbedTab = 'CARD' | 'QR' | 'BUTTON'

const EmbedEventModal = ({ open, onClose }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const [tab, setTab] = useState<EmbedTab>('CARD')

  const onTabClicked = (tab: EmbedTab) => {
    setTab(tab)
  }

  return (
    <FullScreenSheet
      open={open}
      onClose={onClose}
      className={classes.paper}
      title={t('embedModal.title')}
      onBack={null}
      rightContent={null}
    >
      <div className={classes.root}>
        <div className={classes.tabsContainer}>
          <Button
            className={cx(classes.tabButton, {
              [classes.tabButtonSelected]: tab === 'CARD',
              [classes.tabButtonLabel]: true,
            })}
            onClick={() => onTabClicked('CARD')}
          >
            {t('embedModal.embedCard')}
          </Button>

          <Button
            className={cx(classes.tabButton, {
              [classes.tabButtonSelected]: tab === 'QR',
              [classes.tabButtonLabel]: true,
            })}
            onClick={() => onTabClicked('QR')}
          >
            {t('embedModal.createQr')}
          </Button>

          <Button
            className={cx(classes.tabButton, {
              [classes.tabButtonSelected]: tab === 'BUTTON',
              [classes.tabButtonLabel]: true,
            })}
            onClick={() => onTabClicked('BUTTON')}
          >
            {t('embedModal.embedButton')}
          </Button>
        </div>

        <AnimatedStateContent
          getContent={(state) => {
            return (
              <>
                {state === 'CARD' && <EmbedEventCard />}

                {state === 'QR' && <EmbedEventQR />}

                {state === 'BUTTON' && <EmbedEventButton />}
              </>
            )
          }}
          state={tab}
        />
      </div>
    </FullScreenSheet>
  )
}

export default EmbedEventModal
