import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import { OpenInNew } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  eventixLogo: {
    // width: 20,
    // height: 20,
    color: theme.palette.primary.main,
  },
  eventixLogoContainer: {
    flexShrink: 0,
    width: '40px',
    height: '40px',
    borderRadius: 100,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 8,
    background: theme.palette.primary[100],
  },
}))

const GuestManagementExternalGuestsView = ({ className }) => {
  const { t } = useTranslation('common')
  const { classes, cx } = useStyles()
  const event = useSelector(getCurrentEvent)
  const onlyEventix = event.joinOptions
    ?.filter((j) => j.idInfo?.source !== 'INTERNAL')
    ?.every((j) => j.idInfo?.source === 'EVENTIX')

  return (
    <div className={cx(classes.root, className)}>
      {onlyEventix && (
        <div className={classes.eventixLogoContainer}>
          <img src='/images/eventixLogo.svg' className={classes.eventixLogo} />
        </div>
      )}
      {!onlyEventix && (
        <div className={classes.eventixLogoContainer}>
          <OpenInNew style={{}} />
        </div>
      )}
      <Typography variant='body2'>
        <Trans
          t={t}
          i18nKey={onlyEventix ? 'xExternalEventix' : 'xExternalGuests'}
          components={{
            b: <b />,
          }}
          values={{
            count: event.statusCountsHost?.externalGoing ?? 0,
          }}
        />
      </Typography>
    </div>
  )
}

export default GuestManagementExternalGuestsView
