import React, { useState } from 'react'
import mixpanel from 'shared-components/utils/mixpanel'
import qs from 'querystring'
import apiClient from './ApiClient'
import { getIsMobileOrTablet } from 'utils/isMobileOrTablet'
import { Modal, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Trans, useTranslation } from 'react-i18next'
import AppleIcon from '@mui/icons-material/Apple'
import GooglePlayBlank from 'svg/GooglePlayBlank'
import QRCode from 'react-qr-code'
import { gintoHeaderFontFamily } from 'shared-components/typography'

const downloadApp = (action, event) => {
  const guestId =
    window.localStorage && window.localStorage.getItem(`guest_${event?.code}`)
  const params = {
    eventCode: event?.code,
    utm_source: 'BASH',
    utm_campaign: action,
    guestId,
    distinctId: mixpanel.get_distinct_id(),
  }
  apiClient.general.analyticsEventEvent('download', {
    eventId: event.id,
    customProperties: {
      'Download Location': action,
    },
  })
  // mixpanel.trackEvent('Download', event, { 'Download Location': action, 'Download Platform': os })
  const url = `${process.env.NEXT_PUBLIC_API_HOST}/code/events/link?${qs.encode(params)}`
  window.open(url, '_blank')
}

const useStyles = makeStyles()((theme) => ({
  qrPopupContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    width: '100vw',
    background: 'rgba(0, 0, 0, 0.7)',
    zIndex: 100,
  },
  qrPopup: {
    background:
      'radial-gradient(203.5% 203.5% at 120% 7%, #F33DF3 0%, #9B40F9 33.85%, #3D44F7 82.29%, #3F6AD4 100%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    maxWidth: 700,
  },
  qrImageContainer: {
    padding: theme.spacing(4),
    background:
      `linear-gradient(to right, ${'white'} 4px, transparent 4px) 0 0,\n` +
      `    linear-gradient(to right, ${'white'} 4px, transparent 4px) 0 100%,\n` +
      `    linear-gradient(to left, ${'white'} 4px, transparent 4px) 100% 0,\n` +
      `    linear-gradient(to left, ${'white'} 4px, transparent 4px) 100% 100%,\n` +
      `    linear-gradient(to bottom, ${'white'} 4px, transparent 4px) 0 0,\n` +
      `    linear-gradient(to bottom, ${'white'} 4px, transparent 4px) 100% 0,\n` +
      `    linear-gradient(to top, ${'white'} 4px, transparent 4px) 0 100%,\n` +
      `    linear-gradient(to top, ${'white'} 4px, transparent 4px) 100% 100%`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '44px 44px',
  },
  qrPopupRight: {
    marginLeft: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  scanToGetContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  scanIcon: {
    color: '#fff',
    marginRight: theme.spacing(1.5),
    height: '32px',
    width: '32px',
  },
  scanText: {
    color: '#fff',
    fontFamily: gintoHeaderFontFamily,
    fontWeight: 800,
    fontSize: '32px',
    lineHeight: '35.2px',
    textTransform: 'uppercase',
  },
  storeIconsContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    marginTop: theme.spacing(4),
  },
  storeIconsDivider: {
    height: '32px',
    width: '2px',
    borderRadius: '100px',
    margin: theme.spacing(0, 1.5),
    background: '#fff',
  },
  appleIcon: {
    width: 32,
    height: 32,
  },
  playStoreIcon: {
    color: 'white',
    width: 32,
    height: 32,
    padding: theme.spacing(1),
  },
  qrCode: {
    border: '8px solid #fff',
  },
}))

const QRModal = ({ open, handleClose, url }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.qrPopupContainer} onClick={handleClose}>
        <div className={classes.qrPopup}>
          <div className={classes.qrImageContainer}>
            <QRCode
              value={url}
              className={classes.qrCode}
              bgColor='transparent'
              fgColor='white'
            />
          </div>
          <div className={classes.qrPopupRight}>
            <div className={classes.scanToGetContainer}>
              {/* <CropFree className={classes.scanIcon} /> */}
              <Typography variant='h6' className={classes.scanText}>
                <Trans
                  t={t}
                  i18nKey='newHomePage.scanToDownload'
                  components={{ span: <span /> }}
                />
              </Typography>
            </div>

            <div className={classes.storeIconsContainer}>
              <a
                href='https://apps.apple.com/app/bash/id1448137786'
                target='_blank'
                rel='noopener noreferrer'
              >
                <AppleIcon className={classes.appleIcon} />
              </a>
              <div className={classes.storeIconsDivider} />
              <a
                href='https://play.google.com/store/apps/details?id=com.vlinderstorm.bash'
                target='_blank'
                rel='noopener noreferrer'
              >
                <GooglePlayBlank className={classes.playStoreIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const useDownloadApp = ({
  utm_source = 'website',
  utm_medium,
  redirectPath = '',
  event,
  usingHref = false,
  linkGuest = false,
}) => {
  const [open, setOpen] = useState(false)

  const params = {
    utm_source,
    utm_medium,
    distinctId: mixpanel.get_distinct_id(),
  }

  let redirect = redirectPath
  if (redirectPath === '') {
    if (event?.code) {
      redirect = `/e/${event.code}`
    } else if (event?.id) {
      redirect = `/events/${event.id}`
    }
  }

  const url = `https://go.bash.social/${linkGuest ? 'link' : 'get'}${redirect}?${qs.encode(params)}`
  const QRCodeModal = () => (
    <QRModal open={open} handleClose={() => setOpen(false)} url={url} />
  )

  const onDownloadClick = () => {
    if (!getIsMobileOrTablet()) {
      setOpen(true)
      return
    }

    if (usingHref) {
      return
    }

    if (event?.id) {
      apiClient.general.analyticsEventEvent('download', {
        eventId: event.id,
        customProperties: {
          'Download Location': utm_medium,
        },
      })
    }
    // mixpanel.trackEvent('Download', event, { 'Download Location': action, 'Download Platform': os })
    window.open(url, '_blank')
  }
  return {
    onDownloadClick,
    QRCodeModal,
    downloadUrl: getIsMobileOrTablet() ? url : undefined,
  }
}

export default downloadApp
