import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import {
  getActiveGuestWithoutNew,
  getCurrentEvent,
} from '../../../selectors/event'
import {
  Avatar,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import FullScreenSheet from '../../modals/FullScreenSheet'
import apiClient from '../../../shared-components/utils/ApiClient'
import YesNoDialog from '../../dialog/YesNoDialog'
import { useUser } from '../../../utils/userFunctions'
import PostsMessage from './PostsMessage'
import Comment from './Comment'
import TextInput from 'shared-components/common/TextInput'
import { Close, Lock, Send } from '@mui/icons-material'
import { useIsLoggedIn } from '../../../utils/hooks'
import { openVerifyEmailModal } from '../../../actions/modals'
import { UserGuestMinimalDto } from '../../../types/types'
import FlatButton from '../../../shared-components/buttons/FlatButton'
import BottomSheet from '../../common/BottomSheet'

interface Comment {
  id: string
  createdAt: string
  threads?: Comment[]
  threadCount?: number
  poster?: any // Replace with proper type if available
}

const useStyles = makeStyles()((theme) => ({
  dropZone: {
    flexGrow: 1,
    height: 'unset',
    minHeight: '0px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 480,
    background: theme.palette.background.paper,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      maxHeight: 600,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
  },
  hostSelection: {
    display: 'flex',
    alignItems: 'center',
  },
  photoInsert: {
    color: theme.palette.primary[800],
    width: 20,
    height: 20,
  },
  boxBottom: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    // minHeight: 68,
    borderTop: '1px solid ' + theme.palette.divider,
  },
  sendButton: {
    cursor: 'pointer',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
  imageListContainer: {
    overflow: 'auto',
    display: 'flex',
    maxWidth: 136,
  },
  imageListItem: {
    overflow: 'hidden',
    '& > img': {
      objectFit: 'cover',
      border: '1px solid ' + theme.palette.grey.dark,
    },
    position: 'relative',
  },
  removeImageButton: {
    position: 'absolute',
    left: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
  },
  image: {
    width: 208,
    height: 208,
    borderRadius: 0,
    marginBottom: theme.spacing(3),
  },
  smallImage: {
    width: 48,
    height: 48,
    border: '1px solid ' + theme.palette.divider,
    marginRight: theme.spacing(1),
    objectFit: 'cover',
  },
  avatar: {
    height: 32,
    width: 32,
    border: '1px solid ' + theme.palette.divider,
  },
  disabledLine: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dividerTop: {
    background: theme.palette.divider,
    height: 1,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  hostBox: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginLeft: theme.spacing(1),
  },
  hostName: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  smallImageButton: {
    height: 40,
    borderRadius: 1000,
    padding: 0,
    marginRight: theme.spacing(1),
    background: theme.palette.primary[100],
  },
  selectedImage: {
    border: '2px solid ' + theme.palette.primary[950],
  },
  hostButton: {},
  disabledButton: {
    backgroundColor: theme.palette.divider,
  },
  nonHostMessageContainer: {
    marginTop: theme.spacing(10),
  },
  hostMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  hostMenuAvatar: {
    width: '20px',
    height: '20px',
    borderRadius: '100px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 2, 2),
    borderBottom: '1px solid ' + theme.palette.divider,
  },
  closeIcon: {
    cursor: 'pointer',
    padding: 2,
    backgroundColor: 'white',
    borderRadius: 1000,
    display: 'flex',
    width: 16,
    height: 16,
  },
  middle: {
    overflow: 'hidden',
    // height: '100%',
    flexGrow: 1,
    // overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  infoIcon: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  editorMenu: {
    margin: theme.spacing(0, 0),
  },
  mediaScroller: {
    padding: theme.spacing(0, 2, 2, 2),
    height: 'unset',
  },
  postAs: {
    color: theme.palette.grey[800],
    marginRight: theme.spacing(0.5),
  },
  chevronDown: {
    marginLeft: theme.spacing(0.5),
  },
  eventUpdatesContainer: {
    margin: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  postContainer: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  divider: {
    borderBottom: '1px solid ' + theme.palette.divider,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  commentsContainer: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    paddingTop: theme.spacing(3),
  },
  textInputContainer: {
    borderRadius: 22,
    width: '100%',
    overflow: 'hidden',
  },
  textInput: {
    padding: '0px 8px 0px 16px !important',
    minHeight: 44,
    backgroundColor: theme.palette.background.input,
    border: '1px solid ' + theme.palette.divider,
    borderRadius: 22,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0.25px',
    textAlign: 'center',
  },
  replyingTo: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.divider,
    padding: theme.spacing(1.5),
    color: theme.palette.text.tertiary,
  },
  gotItButton: {
    height: 40,
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  lockedTop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  lockIcon: {
    width: 24,
    height: 24,
  },
  lockedDivider: {
    width: '100%',
    height: '1px',
    background: theme.palette.grey[200],
    [theme.breakpoints.up('md')]: {
      height: 0,
    },
  },
}))

const PostModal = ({ open, onClose, post, setPost, onDelete }) => {
  const { user } = useUser()
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const myGuest = event.myGuest
  const isLoggedIn = useIsLoggedIn()

  const [selectedSender, setSelectedSender] = useState(null)
  const [sending, setSending] = useState(false)
  const [value, setValue] = useState('')
  const inputRef = useRef(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [comments, setComments] = useState<Comment[]>([])
  const [loadingComments, setLoadingComments] = useState(false)
  const [parentComment, setParentComment] = useState(null)

  const [lockedOpen, setLockedOpen] = useState(false)
  const activeGuest: UserGuestMinimalDto = useSelector(getActiveGuestWithoutNew)

  const loadComments = async () => {
    setLoadingComments(true)
    try {
      const response = await apiClient.event.getPostComments(post.id, 0, 1000)
      if (response && response.length > 0) {
        // Process each comment to ensure threads are limited to 2
        const processedComments = response.map((comment) => ({
          ...comment,
          threads: comment.threads
            ? comment.threads
                .sort(
                  (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime(),
                )
                .slice(-2)
            : [],
        }))
        setComments(
          processedComments.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          ),
        )
      }
    } catch (error) {
      console.error('Error fetching more posts:', error)
    } finally {
      setLoadingComments(false)
    }
  }

  useEffect(() => {
    if (post?.id) {
      loadComments()
    }
  }, [post?.id])

  useEffect(() => {
    if (event.hosts) {
      const firstHostThatIsMe = event.hosts.find(
        (host) =>
          (host.type === 'USER' && host.model.id === user.id) ||
          (host.type === 'ORGANISATION' && host.model.manager === true),
      )

      if (firstHostThatIsMe) {
        setSelectedSender(
          firstHostThatIsMe.type === 'ORGANISATION'
            ? firstHostThatIsMe.model
            : null,
        )
      }
    }
  }, [event.hosts, user.id])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOpen = Boolean(anchorEl)

  if (!post?.id) return null

  const hasGuestThatCanPost = ['JOINED', 'GOING', 'MAYBE', 'CANT'].includes(
    event.myGuest?.status,
  )

  const managingPageHosts =
    event.hosts?.filter(
      (host) => host.type === 'ORGANISATION' && host.model.manager,
    ) ?? []

  // if (hasGuestThatCanPost) {
  //   posters.push({ id: 'GUEST', type: 'GUEST', model: event.myGuest })
  // }
  const handleClick = (event) => {
    if (managingPageHosts.length > 0) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const postIsByHost =
    event.hosts?.some(
      (host) =>
        (host.type === 'USER' &&
          post.poster.type === 'USER' &&
          host.model.id === post.poster.model.id) ||
        (host.type === 'ORGANISATION' &&
          post.poster.type === 'ORGANISATION' &&
          host.model.id === post.poster.model.id),
    ) ?? false

  const send = async () => {
    setSending(true)
    const comment = await apiClient.event.sendComment(
      post.id,
      value,
      parentComment?.id,
      selectedSender?.id,
      selectedSender == null && !isLoggedIn && hasGuestThatCanPost
        ? myGuest?.code
        : null,
    )
    console.log(comment)
    setSending(false)
    setValue('')
    if (parentComment) {
      setParentComment(null)
      setComments(
        comments.map((c) => {
          if (c.id === parentComment.id) {
            // Limit threads to 2 comments
            const newThreads = [...(c.threads ?? []), comment]
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime(),
              )
              .slice(-2) // Keep only the last 2 comments
            return {
              ...c,
              threads: newThreads,
              threadCount: (c.threadCount ?? 0) + 1,
            }
          }
          return c
        }),
      )
      setPost({
        ...post,
        comments: comments.map((c) => {
          if (c.id === parentComment.id) {
            // Limit threads to 2 comments
            const newThreads = [...(c.threads ?? []), comment]
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime(),
              )
              .slice(-2) // Keep only the last 2 comments
            return {
              ...c,
              threads: newThreads,
              threadCount: (c.threadCount ?? 0) + 1,
            }
          }
          return c
        }),
      })
    } else {
      const newComments = [...comments, comment].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      )
      setComments(newComments)
      setPost({
        ...post,
        comments: newComments,
        commentCount: post.commentCount + 1,
      })
    }
  }

  const onCloseModal = () => {
    onClose()
  }

  const onDeleteConfirm = () => {
    setDeleteDialogOpen(false)
    // TODO
  }

  const setComment = (comment) => {
    setComments(
      comments.map((c) => {
        if (c.id === comment.id) {
          return comment
        }
        if (c.threads) {
          const newC = {
            ...c,
            threads: c.threads.map((t) => {
              if (t.id === comment.id) {
                return comment
              }
              return t
            }),
          }
          return newC
        }
        return c
      }),
    )
  }

  const inputClickedGuest = (e) => {
    if (activeGuest && activeGuest.emailAddress && !isLoggedIn) {
      dispatch(openVerifyEmailModal())
    } else if (activeGuest && isLoggedIn) {
      return
    } else if (event.host) {
      return
    } else {
      setLockedOpen(true)
    }
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <FullScreenSheet
      classes={{ paper: cx(classes.root) }}
      open={open}
      onClose={onCloseModal}
      title={
        t(postIsByHost ? 'updateBy' : 'commentBy') +
        ' ' +
        post.poster?.model?.name
      }
      smallTitle
    >
      <div className={classes.container}>
        <YesNoDialog
          title={t('deletePost')}
          subtitle={t('deletePostSub')}
          confirmText={t('delete')}
          cancelText={t('cancel')}
          destructive
          open={deleteDialogOpen}
          onConfirm={onDeleteConfirm}
          onClose={() => setDeleteDialogOpen(false)}
        />
        <div className={classes.content}>
          <div className={classes.postContainer}>
            <PostsMessage
              msg={post}
              onDelete={onDelete}
              setPost={setPost}
              showCommentButton={false}
              large
            />
          </div>
          {comments.length > 0 && (
            <>
              <div className={classes.divider}></div>
              <div className={classes.commentsContainer}>
                {loadingComments && (
                  <CircularProgress
                    style={{ margin: theme.spacing(2), width: 16, height: 16 }}
                  />
                )}
                {comments.map((comment) => {
                  return (
                    <Comment
                      key={comment.id}
                      comment={comment}
                      onDelete={(comment) => {
                        setComments(
                          comments
                            .filter((c) => c.id !== comment.id)
                            .map((c) => {
                              return {
                                ...c,
                                threads: (c.threads ?? []).filter(
                                  (t) => t.id !== comment.id,
                                ),
                              }
                            }),
                        )
                      }}
                      onReply={() => {
                        setParentComment(comment)
                        inputRef.current?.focus()
                      }}
                      setComment={setComment}
                      emojiLimit={3}
                    />
                  )
                })}
              </div>
            </>
          )}
        </div>

        <div className={classes.boxBottom} onClick={inputClickedGuest}>
          {managingPageHosts.length > 0 && (
            <div className={classes.hostSelection}>
              <Button
                className={classes.hostButton}
                onClick={handleClick}
                disabled={managingPageHosts.length === 0}
              >
                <Avatar
                  className={classes.avatar}
                  src={
                    selectedSender?.avatarUrls?.lg ??
                    selectedSender?.user?.avatarUrls?.lg ??
                    user.avatarUrls?.lg
                  }
                />
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {managingPageHosts.map((host) => {
                  return (
                    <MenuItem
                      key={host.id}
                      className={classes.hostMenuItem}
                      onClick={() => {
                        handleMenuClose()
                        setSelectedSender(
                          host.type === 'ORGANISATION' ? host.model : null,
                        )
                      }}
                    >
                      <img
                        src={
                          host.model?.avatarUrls?.lg ??
                          host.model?.user?.avatarUrls?.lg ??
                          (host.type === 'GUEST' && user.avatarUrls?.lg)
                        }
                        className={classes.hostMenuAvatar}
                        alt='logo'
                      />
                      <Typography variant='body2'>
                        {host.model?.name ?? host.model?.user?.name}
                      </Typography>
                    </MenuItem>
                  )
                })}

                <MenuItem
                  key={user.id}
                  className={classes.hostMenuItem}
                  onClick={() => {
                    handleMenuClose()
                    setSelectedSender(null)
                  }}
                >
                  <img
                    src={user?.avatarUrls?.lg}
                    className={classes.hostMenuAvatar}
                    alt='logo'
                  />
                  <Typography variant='body2'>{user?.name}</Typography>
                </MenuItem>
              </Menu>
            </div>
          )}
          <div className={classes.textInputContainer}>
            {parentComment && (
              <div className={classes.replyingTo}>
                <Typography variant='caption'>
                  Replying to {parentComment.poster?.model?.name}
                </Typography>
                <div
                  className={classes.closeIcon}
                  onClick={() => setParentComment(null)}
                >
                  <Close style={{ width: 12, height: 12 }} />
                </div>
              </div>
            )}
            <TextInput
              className={classes.textInput}
              inputRef={inputRef}
              multiline
              maxRows={4}
              value={value}
              onChange={(e) => {
                if (isLoggedIn) {
                  setValue(e.target.value)
                }
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if (e.shiftKey) {
                    // Allow line break
                    // setValue((prev) => prev + '\n')
                  } else {
                    e.preventDefault()
                    e.stopPropagation()
                    // Send comment
                    send()
                  }
                }
              }}
              placeholder={t('addAComment')}
              endAdornment={
                value.length > 0 && (
                  <div className={classes.sendButton} onClick={send}>
                    {sending ? (
                      <CircularProgress
                        style={{ color: 'white', marginLeft: 8 }}
                        size={16}
                      />
                    ) : (
                      <Send style={{ fontSize: 16 }} />
                    )}
                  </div>
                )
              }
            />
          </div>
        </div>
      </div>
      <BottomSheet open={lockedOpen} onClose={() => setLockedOpen(false)}>
        <div className={classes.lockedContainer}>
          <div className={classes.lockedTop}>
            <Lock className={classes.lockIcon} />
            <Typography align='center' variant='subtitle1'>
              {t('lockedDialog.title')}
            </Typography>
          </div>
          <Typography align='center' variant='body2'>
            {t('lockedDialog.commentDescription')}
          </Typography>

          <div className={classes.lockedDivider} />

          <FlatButton
            onClick={() => setLockedOpen(false)}
            color='primary'
            fullWidth
            className={classes.gotItButton}
          >
            {t('okGotIt')}
          </FlatButton>
        </div>
      </BottomSheet>
    </FullScreenSheet>
  )
}

export default PostModal
