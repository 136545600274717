import React, { useContext } from 'react'
import { appContext } from './App'
import { useTranslation } from 'react-i18next'

import { Button, SvgIcon, Typography } from '@mui/material'
import InfoRow from './InfoRow'
import { linkToMaps } from '../utils/outboundLinks'
import { makeStyles } from 'tss-react/mui'
import { getDateLines } from 'shared-components/utils/eventDisplay'
import { Edit } from '@mui/icons-material'
import Link from 'next/link'
import useMixpanelTrackEvent from '../../utils/useMixpanelTrackEvent'
import { setLocBeforeEdit } from '../../actions/event'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { setCurrentScrapeBotId } from 'actions/scraper'
import VerifiedIcon from '../../svg/CheckDecagram'

const useStyles = makeStyles()((theme) => ({
  locationRow: {
    // marginTop: theme.spacing(1)
  },
  linkRow: {
    // marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-all',
  },
  infoRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    flexGrow: 1,
    maxWidth: '100%',
  },
  tag: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  editButton: {
    alignItems: 'center',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  organiserP: {
    '& * p': {
      marginTop: 2,
    },
  },
}))

const isNullOrBlank = (str) => !str || !str.trim()

export const makeLocationString = (event, t) => {
  if (isNullOrBlank(event.address) && isNullOrBlank(event.location)) {
    if (event.coordinates && event.coordinates.coordinates) {
      return t('common:locationOnMap')
    }
    return ''
  }

  // If location is already part of the address, just return the address
  if (
    event.location &&
    event.address?.toLowerCase().includes(event.location.toLowerCase())
  ) {
    return event.address
  }

  if (!isNullOrBlank(event.location)) {
    return (
      event.location +
      (isNullOrBlank(event.address) ? '' : ', ' + event.address)
    )
  }

  if (isNullOrBlank(event.address)) return event.location
  return event.address
}

export const makeLocationUrl = (event) => {
  if (!isNullOrBlank(event.address)) return event.address
  if (!isNullOrBlank(event.location)) return event.location
  if (event.coordinates && event.coordinates.coordinates) {
    return `${event.coordinates.coordinates[1]}, ${event.coordinates.coordinates[0]}`
  }
  return event.location
}

const getHostName = (host) => host?.model.user?.name || host?.model.name

export const OrganiserString = ({ event, showOnlyFirst = false }) => {
  const { t } = useTranslation('common')

  const renderHost = (host, index) => {
    const hostName = getHostName(host)
    const isLastHost = index === event.hosts.length - 1 // D
    const comma = index === 0 ? ' and ' : ''
    return (
      <React.Fragment key={host.model?.id || index}>
        <b>
          {host.type === 'ORGANISATION' ? (
            <Link href={`/${host.model.username}`} legacyBehavior>
              {hostName}
            </Link>
          ) : (
            <b style={{ cursor: 'pointer' }} onClick={() => onClick(host)}>
              {hostName}
            </b>
          )}
          {host.model.verified && (
            <SvgIcon
              component={VerifiedIcon}
              style={{
                marginLeft: 4,
                marginBottom: -2,
                height: '16px',
                width: '16px',
              }}
              color='primary'
            />
          )}
        </b>
        {!isLastHost && ' and '}
      </React.Fragment>
    )
  }

  return (
    <Typography variant='body1' style={{ margin: 0 }}>
      {t('common:XBy', { x: 'Event' })}
      {event.hosts && event.hosts.length > 0 && (
        <>
          {showOnlyFirst
            ? event.hosts
                .slice(0, 2)
                .map((host, index) => renderHost(host, index))
            : event.hosts.map(renderHost)}
        </>
      )}
    </Typography>
  )
}

const EventDetails = (props, ref) => {
  const eventFromContext = useContext(appContext).event
  const dispatch = useDispatch()
  const router = useRouter()
  const event = props.event || eventFromContext
  const trackEvent = useMixpanelTrackEvent(event)
  const { classes, cx } = useStyles()
  const { t } = useTranslation('shared')
  const dateLines = getDateLines(event)

  const locationNotAllowed =
    event.privacyType === 'REQUEST' && event.myGuest?.state !== 'INVITED'
  const locationString = makeLocationString(event, t)

  const onEdit = () => {
    trackEvent('Edit Bash')
    dispatch(setLocBeforeEdit(router.asPath))
    dispatch(setCurrentScrapeBotId(null))
    router.push(`/edit/${event.code}`)
  }

  return (
    <div className={props.className} ref={ref}>
      <div className={classes.infoRowContainer}>
        <div className={classes.topRow}>
          <Typography variant='subtitle2'>Details</Typography>
          {props.canEdit && (
            <Button
              onClick={onEdit}
              color='primary'
              className={classes.editButton}
            >
              <Edit style={{ fontSize: 14, marginRight: 6, marginTop: -1 }} />
              {t('edit')}
            </Button>
          )}
        </div>
        <InfoRow
          key='date'
          type='date'
          text={
            event.dateToBeAnnounced || event.type === 'PINNING'
              ? t('toBeAnnounced')
              : event.startDate
                ? dateLines[0]
                : t('common:startDate')
          }
          secondLine={dateLines[1]}
        />
        {event.privacyType === 'PRIVATE' && (
          <InfoRow
            className={classes.privateRow}
            key='private'
            type='private'
            text={t('common:privateEvent')}
          />
        )}
        {!(
          event.ticketDisplayLink == null || event.ticketDisplayLink === ''
        ) && (
          <InfoRow
            className={classes.linkRow}
            key='ticketlink'
            type='ticketLink'
            link={`${event.ticketDisplayLink}`}
            text={event.ticketDisplayLink}
          />
        )}
        {locationString.trim() !== '' && !locationNotAllowed && (
          <InfoRow
            className={classes.locationRow}
            key='location'
            type='location'
            text={locationString}
            onClick={() => linkToMaps(makeLocationUrl(event))}
          />
        )}
        {locationNotAllowed && (
          <InfoRow
            className={classes.locationRow}
            key='location'
            type='location'
            text={t('common:locationNotAllowed')}
          />
        )}
        {!(event.callLink == null || event.callLink === '') && (
          <InfoRow
            className={classes.linkRow}
            key='link'
            type='link'
            text={event.callLink}
          />
        )}
        {/* <InfoRow */}
        {/*  className={clsx(classes.linkRow, classes.organiserP)} */}
        {/*  key='organiser' */}
        {/*  type='organiser' */}
        {/*  text={organiserString} */}
        {/* /> */}
        <InfoRow
          className={cx(classes.linkRow, classes.organiserP)}
          key='organiser'
          type='organiser'
          text={<OrganiserString event={event} />}
        />

        {/* {!(event.ticketLink == null || event.ticketLink === '') && */}
        {/*  <InfoRow */}
        {/*    className={classes.linkRow} */}
        {/*    key='ticketLink' */}
        {/*    type='link' */}
        {/*    text={event.ticketLink} */}
        {/*  />} */}
        {event.tags != null && event.tags.length > 0 && (
          <InfoRow
            className={classes.linkRow}
            key='tags'
            type='tags'
            text={
              <>
                {event.tags.map((tag) => (
                  <a
                    key={tag.id}
                    href={tag.url}
                    target='_blank'
                    rel='noreferrer'
                    className={classes.tag}
                  >
                    #{tag.name}{' '}
                  </a>
                ))}
              </>
            }
          />
        )}
      </div>
    </div>
  )
}

export default React.forwardRef(EventDetails)
