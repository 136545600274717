import React from 'react'
import PropTypes from 'prop-types'

import EventIcon from '@mui/icons-material/Event'
import TicketIcon from '@mui/icons-material/LocalActivity'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CreateIcon from '@mui/icons-material/Create'
import VideoCamIcon from '@mui/icons-material/Videocam'
import PersonIcon from '@mui/icons-material/Person'
import { mdiPound } from '@mdi/js'

import { SvgIcon, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Lock } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
    borderTop: theme.palette.grey.main + ' solid 1px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  small: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
  verticalCenter: {
    alignItems: 'center',
  },
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: theme.spacing(2),
    width: 20,
    height: 20,
    fill: theme.palette.secondary.main,
  },
  smallIcon: {
    fontSize: 12,
    marginRight: theme.spacing(1.5),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstLine: {
    display: 'block',
  },
  smallFirstLine: {
    fontSize: '0.85rem',
    lineHeight: 1.2,
    opacity: 0.7,
  },
  secondLine: {
    display: 'block',
    opacity: 0.7,
  },
  smallSecondLine: {
    fontSize: '0.75rem',
    lineHeight: 1.2,
    marginTop: theme.spacing(0.5),
    opacity: 0.5,
  },
  clickable: {
    cursor: 'pointer',
  },
}))

const PoundIcon = (props) => (
  <SvgIcon {...props}>
    <path d={mdiPound} />
  </SvgIcon>
)

const icon = {
  date: EventIcon,
  location: LocationOnIcon,
  description: CreateIcon,
  link: VideoCamIcon,
  ticketLink: TicketIcon,
  tags: PoundIcon,
  organiser: PersonIcon,
  private: Lock,
  none: null,
}

const InfoRow = ({
  className,
  type,
  text,
  secondLine,
  onClick,
  endAdornment,
  variant,
  link,
}) => {
  const { classes, cx } = useStyles()
  const IconComponent = icon[type]
  let href = ''
  if (type === 'link') {
    href = text
    if (!/^[a-z0-9]+:\/\//.test(href)) href = 'https://' + href
  }
  if (type === 'ticketLink') {
    href = link
  }

  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.clickable]: onClick,
          [classes.verticalCenter]:
            (secondLine && variant !== 'small') ||
            (!secondLine && variant === 'small'),
          [classes.small]: variant === 'small',
        },
        className,
      )}
      onClick={onClick}
    >
      {IconComponent != null && (
        <IconComponent
          className={cx(classes.icon, {
            [classes.smallIcon]: variant === 'small',
          })}
        />
      )}
      <div className={classes.main}>
        <div className={classes.textContainer}>
          <Typography
            variant='body2'
            className={cx(classes.firstLine, {
              [classes.smallFirstLine]: variant === 'small',
            })}
          >
            {type === 'link' || type === 'ticketLink' ? (
              <a href={href} target='_blank' rel='noopener noreferrer'>
                {text}
              </a>
            ) : (
              text
            )}
          </Typography>
          {secondLine !== undefined && (
            <Typography
              variant='body2'
              className={cx(classes.secondLine, {
                [classes.smallSecondLine]: variant === 'small',
              })}
            >
              {secondLine}
            </Typography>
          )}
        </div>
        {endAdornment}
      </div>
    </div>
  )
}

InfoRow.propTypes = {
  type: PropTypes.oneOf([
    'date',
    'location',
    'description',
    'link',
    'tags',
    'none',
    'ticketLink',
    'organiser',
    'private',
  ]).isRequired,
  text: PropTypes.any.isRequired,
}

export default InfoRow
