import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import FullScreenSheet from './FullScreenSheet'
import {
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
} from '@giphy/react-components'
import { IGif } from '@giphy/js-types'
import useWindowSize from '../../utils/windowSizeHook'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    height: '100%',
    maxHeight: 400,
    overflow: 'hidden',
    '& > div': {
      display: 'flex',
      overflow: 'hidden',
    },
  },
  searchBar: {},
  grid: {
    height: '100%',
    overflowY: 'auto',
    flexGrow: 1,
    '& picture': {
      cursor: 'pointer',
    },
  },
  componentsRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

interface GifComponentsProps {
  onGifSelected: (url: string) => void
}

const GifComponents = ({ onGifSelected }: GifComponentsProps) => {
  const { classes } = useStyles()
  const { fetchGifs, searchKey } = useContext(SearchContext)

  const windowSize = useWindowSize()

  const onGifClick = (gif: IGif, event) => {
    onGifSelected(gif.images.fixed_width.webp)
  }

  return (
    <div className={classes.componentsRoot}>
      <SearchBar className={classes.searchBar} />
      <Grid
        className={classes.grid}
        key={searchKey}
        columns={2}
        width={Math.min(480, windowSize.width)}
        // width={800}
        noLink
        fetchGifs={fetchGifs}
        onGifClick={onGifClick}
        hideAttribution
      />
    </div>
  )
}

interface GifPickerModalProps {
  open: boolean
  onClose: () => void
  onGifSelected: (url: string) => void
}

const GifPickerModal = ({
  open,
  onClose,
  onGifSelected,
}: GifPickerModalProps) => {
  const { classes } = useStyles()

  return (
    <FullScreenSheet className={classes.root} open={open} onClose={onClose}>
      <SearchContextManager apiKey='E6QE7O6ePVLZRTQ7gcclXi6WZaoM4bi2'>
        <GifComponents onGifSelected={onGifSelected} />
      </SearchContextManager>
    </FullScreenSheet>
  )
}

export default GifPickerModal
