import React, { useEffect, useState } from 'react'
import { Typography, Box } from '@mui/material'
import { withStyles } from 'tss-react/mui'
import { withTranslation } from 'react-i18next'
import cn from 'clsx'
import MixpanelLinkify from 'shared-components/common/MixpanelLinkify'
import mixpanel from 'shared-components/utils/mixpanel'
import { useCurrentEvent } from 'utils/hooks'
import { EditorComponent, Remirror, useRemirror } from '@remirror/react'
import {
  BoldExtension,
  BulletListExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  MarkdownExtension,
  OrderedListExtension,
  UnderlineExtension,
  IframeExtension,
} from 'remirror/extensions'
import EventDescriptionMarkdownUpdater from './EventDescriptionMarkdownUpdater'
import { remirrorStyles } from '../../components/event/posts/Comment'

const TEXT_MAX_HEIGHT = 200
const FADE_HEIGHT = 50

const styles = (theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    paddingBottom: theme.spacing(1.5),
  },
  textContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  fadeOut: {
    marginBottom: 0,
    '&:before': {
      content: "' '",
      width: '100%',
      height: FADE_HEIGHT,
      position: 'absolute',
      left: '0',
      top: TEXT_MAX_HEIGHT - FADE_HEIGHT,
      background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`,
      fallbacks: [
        {
          background: `-moz-linear-gradient(top, transparent 0%, ${theme.palette.background.default} 100%)`,
        },
        {
          background: `-webkit-linear-gradient(top, transparent 0%, ${theme.palette.background.default} 100%)`,
        },
      ],
    },
  },
  description: {
    whiteSpace: 'pre-wrap',
    lineHeight: '21px',
    letterSpacing: '0.25px',
  },
  readMore: {
    display: 'flex',
    alignSelf: 'flex-end',
    background: 'none !important',
    border: 'none',
    padding: '0 !important',
    cursor: 'pointer',
    width: 'fit-content',
    outline: 'none',
    marginLeft: 'auto',
    position: 'absolute',
    right: 0,
    bottom: 0,
    color: theme.palette.text.primary,
  },
  chatIcon: {
    color: theme.palette.secondary.main,
    width: 20,
    height: 20,
    marginRight: theme.spacing(2),
  },
  pointer: {
    cursor: 'pointer',
  },
  websiteButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.grey[900],
    border: `1px solid ${theme.palette.grey[200]}`,
    height: 40,
    fontSize: '0.75rem',
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(1),
  },
  remirror: {
    ...remirrorStyles(theme),
    '& .youtube-embed': {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    '& .youtube-embed iframe': {
      maxWidth: '100%',
      border: 'none',
      aspectRatio: '16/9',
      height: 'auto',
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  youtubeIframe: {
    width: '100%',
    maxWidth: '560px',
    height: '315px',
    border: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

// Helper function to detect YouTube URLs
const getYouTubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : null
}

const EventDescription = ({ classes, t, description, showFullDescription }) => {
  const [showReadMore, setShowReadMore] = useState(false)
  const [showAllText, setShowAllText] = useState(false)
  const [mounted, setMounted] = useState(false)
  const event = useCurrentEvent()
  const checkForReadMore = () => {
    const eventDescriptionEl = document.getElementById('event-description')
    if (eventDescriptionEl) {
      const descHeight = eventDescriptionEl.clientHeight
      if (descHeight >= TEXT_MAX_HEIGHT) setShowReadMore(true)
      if (descHeight < TEXT_MAX_HEIGHT) setShowReadMore(false)
    }
  }
  useEffect(() => {
    setTimeout(() => {
      checkForReadMore()
    }, 32)
    window.addEventListener('resize', checkForReadMore)

    // Add listener for custom event from EventDescriptionMarkdownUpdater
    const eventDescriptionEl = document.getElementById('event-description')
    if (eventDescriptionEl) {
      const handleReadMoreCheck = () => {
        checkForReadMore()
      }
      eventDescriptionEl.addEventListener(
        'checkForReadMore',
        handleReadMoreCheck,
      )

      return () => {
        window.removeEventListener('resize', checkForReadMore)
        eventDescriptionEl.removeEventListener(
          'checkForReadMore',
          handleReadMoreCheck,
        )
      }
    }

    return () => {
      window.removeEventListener('resize', checkForReadMore)
    }
  }, [description, mounted])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    mixpanel.track_links('#event-description a', 'Outbound Link Description', {
      referrer: document.referrer,
    })
  }, [description])

  const onSourceUrlClick = () => {
    window.open(event.sourceUrl, '_blank')
  }

  const linkExtension = new LinkExtension({
    autoLink: false,
    selectTextOnClick: false,
    defaultTarget: '_blank',
    defaultProtocol: 'https:',
  })

  const iframeExtension = new IframeExtension({
    enableResizing: false,
    validateUrl: (url) => {
      // Only allow YouTube embed URLs
      return url.includes('youtube.com/embed/') || url.includes('youtu.be/')
    },
  })

  // Process description to convert YouTube links to iframe syntax
  const processDescription = (content) => {
    if (!content) return content

    // We won't process YouTube links here anymore
    // since it's now handled in EventDescriptionMarkdownUpdater
    return content
  }

  const { manager, state, onChange } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new HeadingExtension(),
      new HardBreakExtension(),
      new BulletListExtension(),
      new OrderedListExtension(),
      new MarkdownExtension(),
      linkExtension,
      iframeExtension,
    ],
    stringHandler: 'markdown',
    content: processDescription(event.descriptionMarkdown),
  })

  event.descriptionMarkdown = event.descriptionMarkdown?.replace(
    /(^\s*[*_~`]+)(.*?)([*_~`]+)/,
    '$2',
  )

  return (
    <div
      onClick={() => setShowAllText(true)}
      className={cn(classes.root, {
        [classes.pointer]:
          showReadMore && !(showAllText || showFullDescription),
      })}
    >
      {/* <ChatBubbleIcon className={classes.chatIcon} /> */}
      <div
        className={cn(classes.textContainer, {
          [classes.fadeOut]:
            showReadMore && !(showAllText || showFullDescription),
        })}
        style={{
          maxHeight:
            showAllText || showFullDescription ? 'none' : TEXT_MAX_HEIGHT,
        }}
        id='event-description'
      >
        {mounted &&
          event.descriptionMarkdown != null &&
          event.descriptionMarkdown.trim() !== '' && (
            <Remirror
              state={state}
              // initialContent={state}
              manager={manager}
              editable={false}
              classNames={[classes.remirror]}
              onChange={onChange}
            >
              <EditorComponent />
              <EventDescriptionMarkdownUpdater />
            </Remirror>
          )}
        {(event.descriptionMarkdown == null ||
          event.descriptionMarkdown.trim() === '') && (
          <Typography variant='body2' className={classes.description}>
            <MixpanelLinkify>{description}</MixpanelLinkify>
          </Typography>
        )}

        {/*<Typography*/}
        {/*  variant='body2'*/}
        {/*  className={classes.description}*/}
        {/*  id='event-description'*/}
        {/*>*/}
        {/*  <MixpanelLinkify>{description}</MixpanelLinkify>*/}
        {/*</Typography>*/}
      </div>
      {showReadMore && !showFullDescription && !showAllText && (
        <button
          className={classes.readMore}
          onClick={() => setShowAllText(!showAllText)}
        >
          <b>...{t('more').toLocaleLowerCase()}</b>
        </button>
      )}
    </div>
  )
}

export default withTranslation('shared')(withStyles(EventDescription, styles))
