import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { IconButton, SvgIcon, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import BottomSheet from '../../components/common/BottomSheet'
import { closeScanner } from 'actions/modals'
import FlatButton from 'shared-components/buttons/FlatButton'
import { Close } from '@mui/icons-material'
import { useDownloadApp } from 'shared-components/utils/downloadApp'
import { mdiQrcodeScan } from '@mdi/js'
import BashButton, {
  BashButtonType,
} from 'shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  downloadButton: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    height: 40,
    width: 40,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    lineHeight: '19.09px',
  },
  closeIcon: {
    position: 'absolute',
    left: 16,
    top: 16,
  },
}))

const ScannerModal = () => {
  const { t } = useTranslation('common')

  const dispatch = useDispatch()
  const { classes } = useStyles()
  const open = useSelector((state) => state.modals.open.scanner)

  const { onDownloadClick, QRCodeModal, downloadUrl } = useDownloadApp({
    utm_medium: 'scanner',
    usingHref: true,
  })

  return (
    <BottomSheet open={open} onClose={() => dispatch(closeScanner())}>
      <div className={classes.root}>
        <IconButton
          className={classes.closeIcon}
          onClick={() => dispatch(closeScanner())}
          size='large'
        >
          <Close />
        </IconButton>
        <SvgIcon className={classes.logo}>
          <path d={mdiQrcodeScan} />
        </SvgIcon>
        <Typography variant='h6' className={classes.title}>
          {t('scanner')}
        </Typography>
        <Typography variant='body1' className={classes.subtitle}>
          {t('scannerExplanation')}
        </Typography>
      </div>
      <BashButton
        type={BashButtonType.PRIMARY}
        href={downloadUrl}
        onClick={onDownloadClick}
        className={classes.downloadButton}
      >
        {t('downloadBash')}
      </BashButton>
      <QRCodeModal />
    </BottomSheet>
  )
}

export default ScannerModal
