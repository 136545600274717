import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import EventSection from './EventSection'
import {
  makeLocationString,
  makeLocationUrl,
} from '../../shared-components/event/EventDetails'
import { linkToMaps } from '../../shared-components/utils/outboundLinks'
import BottomSheet from '../common/BottomSheet'
import FlatButton from 'shared-components/buttons/FlatButton'
import { Lock } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  noMargin: {
    margin: 0,
  },
  location: {
    lineHeight: '1.3125rem',
  },
  gotItButton: {
    height: 40,
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  lockedTop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  lockIcon: {
    width: 24,
    height: 24,
  },
  lockedDivider: {
    width: '100%',
    height: '1px',
    background: theme.palette.grey[200],
    [theme.breakpoints.up('md')]: {
      height: 0,
    },
  },
  locationAfterRsvp: {
    fontStyle: 'italic',
  },
}))

const EventLocationCard = ({ desktopMode }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const [open, setOpen] = useState(false)
  const locationString = makeLocationString(event, t)
  const locationAfterJoining =
    event.placeName?.trim() !== '' && event.hideLocationUntilJoined === true
  const hasLocationValue = locationString.trim() !== ''

  if (!hasLocationValue && !locationAfterJoining) return null

  return (
    <>
      <EventSection
        title={t('location')}
        desktopMode={desktopMode}
        hasExternalLink
        fullClickArea
        onMoreClicked={() => {
          if (hasLocationValue) {
            linkToMaps(makeLocationUrl(event))
          } else if (locationAfterJoining) {
            console.log('here')
            setOpen(true)
          }
        }}
        blocker={!hasLocationValue && locationAfterJoining}
      >
        <div className={cx(classes.flex, classes.marginTop1, classes.pointer)}>
          <Typography variant='body2' className={classes.location}>
            {hasLocationValue ? locationString : event.placeName}
            {locationAfterJoining && !hasLocationValue && (
              <span className={classes.locationAfterRsvp}>
                {' '}
                ({t('locationAfterRsvp')})
              </span>
            )}
          </Typography>
        </div>
      </EventSection>
      <BottomSheet open={open} onClose={() => setOpen(false)}>
        <div className={classes.lockedContainer}>
          <div className={classes.lockedTop}>
            <Lock className={classes.lockIcon} />
            <Typography
              className={classes.lockedTitle}
              align='center'
              variant='subtitle1'
            >
              {t('lockedDialog.title')}
            </Typography>
          </div>
          <Typography
            className={classes.lockedCaption}
            align='center'
            variant='body2'
          >
            {t('lockedDialog.locationDescription')}
          </Typography>

          <div className={classes.lockedDivider} />

          <FlatButton
            onClick={() => setOpen(false)}
            color='primary'
            fullWidth
            className={classes.gotItButton}
          >
            {t('okGotIt')}
          </FlatButton>
        </div>
      </BottomSheet>
    </>
  )
}

export default EventLocationCard
