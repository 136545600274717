import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CantIcon from 'svg/status/CantOutline'
import MaybeIcon from 'svg/status/MaybeOutline'
import ClickableAvatar from './ClickableAvatar'

import { SvgIcon } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { appContext } from '../event/App'
import { isCurrentEventPinning } from 'selectors/event'
import { mdiFire } from '@mdi/js'
import FireGreyCircle from '../../svg/FireGreyCircle'

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    width: 'fit-content',
  },
  badge: {
    width: 24,
    height: 24,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  badgeSmall: {
    width: 16,
    height: 16,
  },
  cant: {
    color: theme.palette.red.main,
  },
  maybe: {
    color: theme.palette.yellow.main,
  },
  going: {
    color: theme.palette.secondary.main,
  },
  interested: {
    color: theme.palette.red.main,
    padding: '2px',
  },
  like: {
    color: 'white',
    backgroundColor: theme.palette.red.main,
    borderRadius: '50%',
    padding: 2,
    right: -2,
    bottom: -2,
  },
  whiteBackground: {
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  bashBadge: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}))

const sizeToNum = {
  medium: 60,
  small: 40,
  small32: 32,
  small24: 24,
  small20: 20,
}

export const StatusBadge = ({
  status,
  hype,
  gaveDateOptions,
  pinningDate,
  size,
}) => {
  const { classes, cx } = useStyles()
  const sizeClass = size !== 'medium' && classes.badgeSmall
  const badges = {
    MAYBE: (
      <SvgIcon
        component={MaybeIcon}
        className={cx(classes.badge, classes.maybe, sizeClass)}
      />
    ),
    GOING: (
      <CheckCircleIcon
        className={cx(
          classes.badge,
          classes.going,
          classes.whiteBackground,
          sizeClass,
        )}
      />
    ),
    JOINED: (
      <CheckCircleIcon
        className={cx(
          classes.badge,
          classes.going,
          classes.whiteBackground,
          sizeClass,
        )}
      />
    ),
    CANT: (
      <SvgIcon
        component={CantIcon}
        className={cx(classes.badge, classes.cant, sizeClass)}
      />
    ),
    HYPE: (
      <FireGreyCircle
        className={cx(classes.badge, classes.interested, sizeClass)}
      >
        <path d={mdiFire} />{' '}
      </FireGreyCircle>
    ),
    // INTERESTED: <Favorite className={classNames(classes.badge, classes.interested, classes.whiteBackground, sizeClass)} />,
    NONE: null,
    REQUESTED: null,
    REQUEST_DENIED: null,
  }
  if (pinningDate && gaveDateOptions) return badges.GOING
  return badges[status] ?? (hype ? badges.HYPE : null)
}

const GuestAvatarBadge = ({
  className,
  guest,
  size = 'medium',
  style,
  avatarClass,
  customBadge,
  event,
  status,
  hype,
  hideBadge,
  showBlocker,
}) => {
  const { classes, cx } = useStyles()
  const pinningDate = useSelector(isCurrentEventPinning)
  const useEvent = useContext(appContext).event
  const eventToUse = event || useEvent
  const isPublicEvent = eventToUse?.privacyType === 'PUBLIC'

  return (
    <div className={cx(classes.root, className)} style={style}>
      <ClickableAvatar
        className={avatarClass}
        userOrGuest={guest}
        size={sizeToNum[size]}
        disableClick={showBlocker}
      />
      {!customBadge && !hideBadge && (
        <StatusBadge
          status={status === undefined ? guest.status : status}
          hype={hype === undefined ? guest.hype : hype}
          gaveDateOptions={guest.gaveDateOptions}
          size={size}
          pinningDate={pinningDate}
          classes={classes}
          isPublicEvent={isPublicEvent}
        />
      )}
      {customBadge}
    </div>
  )
}

export default GuestAvatarBadge
