import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { AddAPhotoOutlined } from '@mui/icons-material'
import Row from '../Row'

export default function DropZone({
  className,
  fileInputRef,
  overlayClassName,
  onNewFile,
  children,
  allowVideo,
  customAccept,
  multiple,
  title,
  fullScreen,
  disabled,
  withPaste,
  sx = [],
}) {
  const [highlighted, setHighlighted] = useState(false)

  const onDrop = (evt) => {
    setHighlighted(false)
    if (disabled) {
      return
    }
    evt.preventDefault()
    if (evt.dataTransfer && evt.dataTransfer.files) {
      if (multiple) {
        onNewFile(evt.dataTransfer.files)
      } else {
        onNewFile(evt.dataTransfer.files[0])
      }
    }
  }

  const onFileSelected = (evt) => {
    if (disabled) {
      return
    }
    if (evt.target && evt.target.files) {
      if (multiple) {
        onNewFile(evt.target.files)
      } else {
        onNewFile(evt.target.files[0])
      }
    }
  }

  const handlePaste = useCallback(
    (event) => {
      if (disabled || !withPaste) {
        return
      }
      const items = event.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        if (
          items[i].type.indexOf('image') !== -1 ||
          (allowVideo && items[i].type.indexOf('video') !== -1)
        ) {
          const file = items[i].getAsFile()
          onNewFile([file])
          event.preventDefault()
          break
        }
      }
    },
    [onNewFile, allowVideo, disabled],
  )

  useEffect(() => {
    window.addEventListener('paste', handlePaste)
    return () => {
      window.removeEventListener('paste', handlePaste)
    }
  }, [handlePaste])

  return (
    <Box
      className={className}
      onDragOver={(evt) => {
        setHighlighted(true)
        evt.preventDefault()
      }}
      onDragLeave={() => setHighlighted(false)}
      onDrop={onDrop}
      sx={[
        {
          position: 'relative',
          width: '100%',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
      {highlighted && !disabled && (
        <Row
          sx={[
            {
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(63, 106, 212, 0.6)',
              zIndex: 6666,
              pointerEvents: 'none',
            },
            fullScreen &&
              ((theme) => ({
                border: '3px dashed ' + theme.palette.primary[950],
                WebkitBoxSizing: 'border-box',
                MozBoxSizing: 'border-box',
                boxSizing: 'border-box',
              })),
          ]}
          className={overlayClassName}
        >
          <AddAPhotoOutlined
            sx={{
              color: 'primary.950',
              mr: 1,
            }}
            color='inherit'
          />
          {title && (
            <Typography variant='button' sx={{ color: 'primary.950' }}>
              {title}
            </Typography>
          )}
        </Row>
      )}
      <Box
        ref={fileInputRef}
        type='file'
        multiple={multiple}
        accept={
          customAccept ||
          (allowVideo
            ? 'video/mp4,image/jpeg,image/png,image/gif,image/webp'
            : 'image/jpeg,image/png,image/gif,image/webp')
        }
        onInput={onFileSelected}
        component='input'
        sx={{ display: 'none' }}
      />
    </Box>
  )
}
