import { SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Close } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'
import Column from '../../Column'

interface Props {
  onClose: () => void
  sx?: SxProps<Theme>
}

const GuestManagementTooltip = ({ onClose, sx = [] }: Props) => {
  const { t } = useTranslation('common')

  return (
    <Column
      sx={[
        {
          alignItems: 'flex-end',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <svg
        style={{
          fill: '#3D3D3DCC',
          marginRight: 24,
          marginBottom: -2,
        }}
        height='24'
        width='24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <polygon points='12,8 24,24 0,24' />
      </svg>
      <Column
        sx={[
          {
            background: '#3D3D3DCC',
            borderRadius: 1.5,
            gap: 1.25,
            boxShadow:
              '0px 11px 15px -7px #0000000F, 0px 24px 38px 3px #0000000A, 0px 9px 46px 8px #0000000A',
            backdropFilter: 'blur(11.600000381469727px)',
            padding: 2.5,
            maxWidth: 320,
            position: 'relative',
          },
        ]}
      >
        <Typography
          variant='h6'
          sx={{
            fontWeight: 900,
            fontSize: '1rem',
            color: 'white',
          }}
        >
          {t('guestManagementTooltip.title')}
        </Typography>
        <Typography
          variant='body2'
          sx={{
            lineHeight: '1.3125rem',
            color: 'white',
          }}
        >
          {t('guestManagementTooltip.caption')}
        </Typography>
        <Close
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: 20,
            height: 20,
            color: 'white',
            cursor: 'pointer',
          }}
          onClick={onClose}
        />
      </Column>
    </Column>
  )
}

export default GuestManagementTooltip
