import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { IconButton, Menu, MenuItem, SvgIcon, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getCurrentEvent, getCurrentUser } from '../../../selectors/event'
import apiClient from '../../../shared-components/utils/ApiClient'
import BottomSheet from '../../common/BottomSheet'
import { LikedByModal } from 'components/modals/LikedByModal'
import PostEventUpdates from './PostEventUpdates'
import relativeTime from 'dayjs/plugin/relativeTime'
import MixpanelLinkify from '../../../shared-components/common/MixpanelLinkify'
import { MoreVert } from '@mui/icons-material'
import YesNoDialog from 'components/dialog/YesNoDialog'
import { openCreatePostModal, setEditingPost } from '../../../actions/modals'
import {
  EditorComponent,
  Remirror,
  useChainedCommands,
  useExtensionEvent,
  useRemirror,
  useRemirrorContext,
} from '@remirror/react'
import {
  BoldExtension,
  BulletListExtension,
  extractHref,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  MarkdownExtension,
  OrderedListExtension,
  UnderlineExtension,
} from 'remirror/extensions'
import EmojiResponses from '../../common/EmojiResponses'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MaybeIcon from '../../../svg/status/Maybe'
import CantIcon from '../../../svg/status/Cant'
import MultimediaRow from '../media/MultimediaRow'
import { useRouter } from 'next/router'
import PostModal from './PostModal'
import Comment, { remirrorStyles } from './Comment'
import VerifiedIcon from 'svg/CheckDecagram'

dayjs.extend(relativeTime)

// export const remirrorStyles = (theme) => ({
//   '& *:first-child': {
//     paddingTop: 0,
//     marginTop: 0,
//   },
//   '& *:last-child': {
//     paddingBottom: 0,
//     marginBottom: 0,
//   },
//   '& h1': {
//     fontSize: '1.25rem',
//     fontWeight: 700,
//     lineHeight: '23.87px',
//   },
//   '& h3': {
//     fontSize: '16px',
//     lineHeight: '21px',
//     fontWeight: 700,
//   },
//   '& p': {
//     fontSize: '14px',
//     lineHeight: '21px',
//   },
//   '& a': {
//     color: theme.palette.primary.main,
//     textDecoration: 'underline',
//     cursor: 'pointer',
//   },
//   '& a:active': {
//     pointerEvents: 'none',
//   },
// })

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },
  content: {
    gap: theme.spacing(1.5),
    paddingLeft: theme.spacing(5),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0.5),
  },
  largeContent: {
    paddingLeft: 0,
    marginTop: theme.spacing(2),
  },
  rootAvatarTop: {
    alignItems: 'flex-start',
  },
  userAvatarTop: {},
  userAvatar: {
    width: 32,
    height: 32,
    borderRadius: '16px',
    border: '1px solid ' + theme.palette.divider,
    alignSelf: 'start',
    background: 'white',
  },
  largeAvatar: {
    width: 40,
    height: 40,
    borderRadius: '20px',
  },
  name: {
    // marginLeft: theme.spacing(0.5),
    fontWeight: 500,
    fontSize: '14px',
    display: 'flex',
  },
  date: {
    textAlign: 'left',
    marginTop: theme.spacing(0),
    // marginLeft: theme.spacing(0.5),
    fontWeight: 400,
    fontSize: '12px',
    flexShrink: 0,
  },
  simpleText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '1.3125rem',
    '& a': {
      color: '#2E60DC',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
  },
  innerNameContainer: {
    marginLeft: theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  raspEmoji: {
    marginTop: theme.spacing(1),
  },
  remirror: {
    ...remirrorStyles(theme),
    '& a': {
      color: theme.palette.mode === 'dark' ? '#F2F5FD' : '#2E60DC',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  goingIcon: {
    flexShrink: 0,
    width: 12,
    height: 12,
    color: theme.palette.secondary.main,
    marginLeft: 6,
  },
  maybeIcon: {
    flexShrink: 0,
    width: 12,
    height: 12,
    color: '#2E60DC',
    marginLeft: 6,
  },
  cantIcon: {
    flexShrink: 0,
    width: 12,
    height: 12,
    color: theme.palette.red.main,
    marginLeft: 6,
  },
  moreButton: {
    marginLeft: 'auto',
  },
  rsvpText: {
    textWrap: 'nowrap',
  },
  clickable: {
    cursor: 'pointer',
  },
  comments: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    cursor: 'pointer',
  },
  verifiedIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
  },
}))

const PostsMessage = ({
  msg: post,
  onDelete,
  setPost,
  showCommentButton = true,
  large = false,
}) => {
  const message = post.message
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const sender = post.poster.model.name ?? post.poster.model?.user?.name
  const senderAvatarUrl = post.poster.model.avatarUrls.lg
  const verified = post.poster.model.verified
  const ownUser = useSelector(getCurrentUser)
  const router = useRouter()
  const time = dayjs(post.createdAt).fromNow(true)

  const [mounted, setMounted] = useState(false)
  const [likedByOpen, setLikedByOpen] = useState(false)
  const [moreAnchor, setMoreAnchor] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deletingState, setDeletingState] = useState('NONE')
  const [commentModalOpen, setCommentModalOpen] = useState(false)

  const showMoreButton =
    event.host === true ||
    (post.poster.type === 'GUEST' &&
      post.poster.model.id === event.myGuest?.id) ||
    (post.poster.type === 'USER' && post.poster.model.id === ownUser.id)

  const showEditButton =
    (post.poster.type === 'GUEST' &&
      post.poster.model.id === event.myGuest?.id) ||
    (post.poster.type === 'USER' && post.poster.model.id === ownUser.id) ||
    (post.poster.type === 'ORGANISATION' && event.host === true)

  post.textMarkdown = post.textMarkdown?.replace(
    /(^\s*[*_~`]+)(.*?)([*_~`]+)/,
    '$2',
  )

  const linkExtension = new LinkExtension({
    autoLink: false,
    selectTextOnClick: false,
    defaultTarget: '_blank',
    defaultProtocol: 'https:',
  })
  const { manager, state, onChange } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new HeadingExtension(),
      new HardBreakExtension(),
      new BulletListExtension(),
      new OrderedListExtension(),
      new MarkdownExtension(),
      linkExtension,
    ],
    stringHandler: 'markdown',
    content: post.textMarkdown,
  })

  const deletePost = async () => {
    setDeletingState('LOADING')
    try {
      await apiClient.event.deletePost(post.id)
      onDelete()
      setDeletingState('SUCCESS')
    } catch (e) {
      setDeletingState('FAILED')
      console.error(e)
    }
    setDeleteDialogOpen(false)
  }

  const moreClicked = (e) => {
    e.stopPropagation()
    setMoreAnchor(e.currentTarget)
  }

  const onDeletePostClicked = () => {
    setMoreAnchor(null)
    setDeleteDialogOpen(true)
  }

  const onEditPostClicked = () => {
    dispatch(setEditingPost(post))
    // Todo, set postAsHost to true if needed
    dispatch(openCreatePostModal())
    setMoreAnchor(null)
  }

  const onReact = (emoji) => {
    apiClient.event.reactPost(post.id, emoji)
    const index = post.emojiResponses.findIndex(
      (response) => response.emoji === emoji,
    )
    if (index !== -1) {
      post.emojiResponses[index].reactedByYou = true // Set reactedByYou to true
      post.emojiResponses[index].count += 1 // Increment the count
      post.emojiResponses[index].users.push(ownUser) // Add the current user to the users array
    } else {
      // Emoji not found, create a new response
      post.emojiResponses.push({
        emoji: emoji,
        users: [ownUser], // Add the current user to the users array
        reactedByYou: true, // Set reactedByYou to true
        count: 1, // Initialize count to 1
      })
    }
    setPost({ ...post }) // Update the post state
  }
  const onRemoveReaction = (emoji) => {
    apiClient.event.removeReactionPost(post.id, emoji)

    const index = post.emojiResponses.findIndex(
      (response) => response.emoji === emoji,
    )
    if (index !== -1) {
      if (post.emojiResponses[index].count === 1) {
        post.emojiResponses.splice(index, 1) // Remove the emoji response
      } else {
        post.emojiResponses[index].reactedByYou = false
        post.emojiResponses[index].count -= 1
        post.emojiResponses[index].users = post.emojiResponses[
          index
        ].users.filter((user) => user.id !== ownUser?.id)
      }
      setPost({ ...post }) // Update the post state
    }
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  const setComment = (comment) => {
    // const updatedComments = comments.map((c) =>
    //   c.id === comment.id
    //     ? comment
    //     : c.threads?.map((t) => (t.id === comment.id ? comment : t)),
    // )
    setPost({
      ...post,
      comments: post.comments.map((c) => {
        if (c.id === comment.id) {
          return comment
        }
        if (c.threads) {
          const newC = {
            ...c,
            threads: c.threads.map((t) => {
              if (t.id === comment.id) {
                return comment
              }
              return t
            }),
          }
          return newC
        }
        return c
      }),
    })
  }
  const userId = post.poster.type === 'USER' ? post.poster.model.id : null
  const orgId =
    post.poster.type === 'ORGANISATION' ? post.poster.model.id : null

  const onUserClicked = (e) => {
    if (userId) {
      router.push(`/users/${userId}`)
    } else if (orgId) {
      router.push(`/pages/${orgId}`)
    }
  }

  const commentsClicked = (e) => {
    setCommentModalOpen(true)
  }

  return (
    <div className={classes.root}>
      <div className={cx(classes.topRow)}>
        <img
          alt='user avatar'
          src={senderAvatarUrl}
          className={cx(
            classes.userAvatar,
            (userId || orgId) && classes.clickable,
            large && classes.largeAvatar,
          )}
          onClick={onUserClicked}
        />

        <div className={classes.innerNameContainer}>
          <Typography
            className={cx(classes.name, classes.clickable)}
            noWrap
            variant='body2'
            onClick={onUserClicked}
          >
            {sender}
            {verified && (
              <SvgIcon
                component={VerifiedIcon}
                className={classes.verifiedIcon}
                color='primary'
              />
            )}
          </Typography>
          {post.activityLog != null && post.activityLog.type === 'RSVP' && (
            <>
              <Typography className={classes.rsvpText} variant='body2'>
                {` ${t(`eventLogUpdate.NEW_RSVP_${post.activityLog.model.status}`)}`}
              </Typography>

              {['JOINED', 'GOING'].includes(post.activityLog.model.status) && (
                <CheckCircleIcon
                  className={classes.goingIcon}
                  style={{
                    color: event.theme.colourScheme?.colorButtonPrimary,
                  }}
                />
              )}
              {post.activityLog.model.status === 'MAYBE' && (
                <SvgIcon component={MaybeIcon} className={classes.maybeIcon} />
              )}
              {post.activityLog.model.status === 'CANT' && (
                <SvgIcon component={CantIcon} className={classes.cantIcon} />
              )}
            </>
          )}
          <Typography
            className={classes.date}
            variant='caption'
            color='textSecondary'
          >
            {' · '}
            {time}
          </Typography>
        </div>
        {showMoreButton && (
          <IconButton
            className={classes.moreButton}
            size='small'
            onClick={moreClicked}
          >
            <MoreVert />
          </IconButton>
        )}
        <Menu
          id='more-menu'
          anchorEl={moreAnchor}
          open={!!moreAnchor}
          onClose={() => setMoreAnchor(null)}
          disableAutoFocusItem
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {showEditButton && (
            <MenuItem onClick={onEditPostClicked}>
              <Typography variant='body2'>{t('edit')}</Typography>
            </MenuItem>
          )}
          <MenuItem onClick={onDeletePostClicked}>
            <Typography variant='body2' color='error'>
              {t('delete')}
            </Typography>
          </MenuItem>
        </Menu>
        <YesNoDialog
          title={t('removePost')}
          subtitle={t('removePostSub')}
          confirmText={t('delete')}
          cancelText={t('cancel')}
          destructive
          open={deleteDialogOpen}
          loading={deletingState === 'LOADING'}
          onConfirm={deletePost}
          onClose={() => setDeleteDialogOpen(false)}
        />
      </div>
      <div className={cx(classes.content, large && classes.largeContent)}>
        {mounted &&
          post.textMarkdown != null &&
          post.textMarkdown.trim() !== '' && (
            <Remirror
              state={state}
              onChange={onChange}
              manager={manager}
              editable={false}
              classNames={[classes.remirror]}
            >
              <EditorComponent />
              <PostsMessageMarkdownUpdater message={post} />
            </Remirror>
          )}

        {post.message && !post.textMarkdown && (
          <MixpanelLinkify>
            <Typography variant='body2' className={classes.simpleText}>
              {post.message}
            </Typography>
          </MixpanelLinkify>
        )}

        <PostEventUpdates post={post} />

        <MultimediaRow media={post.media ?? []} limit={4} />

        <EmojiResponses
          responses={post.emojiResponses}
          onReact={onReact}
          onRemoveReaction={onRemoveReaction}
          onComment={
            showCommentButton ? () => setCommentModalOpen(true) : undefined
          }
          commentCount={post.commentCount}
        />
        {post.comments != null &&
          post.comments.length > 0 &&
          showCommentButton && (
            <div className={classes.comments} onClick={commentsClicked}>
              {post.comments.slice(0, 2).map((comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  onDelete={() => {
                    setPost({
                      ...post,
                      comments: post.comments.filter(
                        (c) => c.id !== comment.id,
                      ),
                    })
                  }}
                  setComment={setComment}
                  tiny={true}
                />
              ))}
              {post.commentCount > 2 && (
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => setCommentModalOpen(true)}
                  variant='caption'
                  color='textSecondary'
                >
                  {t('viewAllComments')}
                </Typography>
              )}
            </div>
          )}
      </div>
      <BottomSheet
        className={classes.sheet}
        open={likedByOpen}
        onClose={() => {
          setLikedByOpen(false)
        }}
      >
        <LikedByModal message={message} />
      </BottomSheet>
      <PostModal
        open={commentModalOpen}
        onClose={() => setCommentModalOpen(false)}
        post={post}
        setPost={setPost}
        onDelete={() => {
          setCommentModalOpen(false)
          onDelete()
        }}
      />
    </div>
  )
}

// This component resets the content of a Remirror node everytime the textMarkdown updates
// This is necessary, as otherwise the Remirror node isn't aware its content has changed and doesn't rerender
const PostsMessageMarkdownUpdater = ({ message }) => {
  const { setContent } = useRemirrorContext()
  const chain = useChainedCommands()

  useExtensionEvent(
    LinkExtension,
    'onClick',
    useCallback((e, data) => {
      const ref = extractHref({
        url: data.href,
        defaultProtocol: 'https:',
      })
      window.open(ref, '_blank')
      return true
    }, []),
  )

  useEffect(() => {
    if (message.textMarkdown && message.textMarkdown.length > 0) {
      // Clear description and set content to markdown

      setContent({
        type: 'doc',
        content: [],
      })
      chain.insertMarkdown(message.textMarkdown).run()
    }
  }, [message.textMarkdown])

  return null
}

export default PostsMessage