import React from 'react'
// import FireThin from '../../../svg/FireThin'
import { SvgIcon } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CantIconOutline from 'svg/status/CantOutline'
import MaybeIconOutline from 'svg/status/MaybeOutline'
import CantIcon from 'svg/status/Cant'
import MaybeIcon from 'svg/status/Maybe'
import { mdiCrown, mdiFire } from '@mdi/js'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  small: {
    width: 24,
    height: 24,
  },
  medium: {
    width: 32,
    height: 32,
  },
  large: {
    width: 48,
    height: 48,
  },
  CANT: {
    color: theme.palette.red.main,
  },
  MAYBE: {
    color: theme.palette.yellow.main,
    marginRight: theme.spacing(0.1),
  },
  GOING: {
    color: theme.palette.secondary.main,
  },
  INTEREST: {
    color: theme.palette.red.main,
  },
  HOST: {
    color: theme.palette.primary.main,
  },
  whiteBackground: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    padding: 2,
  },
}))

const icon = {
  INTERESTED: ({ transparent, ...props }) => (
    <SvgIcon {...props}>
      <path d={mdiFire} />
    </SvgIcon>
  ),
  MAYBE: ({ transparent, ...props }) => (
    <SvgIcon
      component={transparent ? MaybeIcon : MaybeIconOutline}
      {...props}
    />
  ),
  GOING: ({ transparent, ...props }) => <CheckCircleIcon {...props} />,
  CANT: ({ transparent, ...props }) => (
    <SvgIcon component={transparent ? CantIcon : CantIconOutline} {...props} />
  ),
  HOST: ({ transparent, ...props }) => (
    <SvgIcon {...props}>
      <path d={mdiCrown} />
    </SvgIcon>
  ),
  NONE: null,
}

const StatusIcon = ({ status, size = 'small', className }) => {
  const { classes, cx } = useStyles()
  const useStatus = status
  const Icon = icon[useStatus]
  if (Icon == null) return null
  const useWhiteBg =
    useStatus === 'INTEREST' || useStatus === 'HOST' || useStatus === 'GOING'
  return (
    <Icon
      className={cx(
        classes.small,
        classes[useStatus],
        {
          [classes.whiteBackground]: useWhiteBg,
          [classes.medium]: size === 'medium',
          [classes.large]: size === 'large',
        },
        className,
      )}
    />
  )
}

export default StatusIcon
