import React, { useState } from 'react'
import { getVideoUrl } from '../../../shared-components/utils/media'
import { Typography } from '@mui/material'
import { ViewMediaModal } from '../../modals/ViewMediaModal'
import { Close } from '@mui/icons-material'
import Row from '../../Row'
import Column from '../../Column'

interface MultimediaRowProps {
  media: any[]
  onRemoveImageClicked: ((media: any) => void) | undefined
  limit: number | undefined
}

const MultimediaRow = ({ media, onRemoveImageClicked }: MultimediaRowProps) => {
  const [fullscreenOpen, setFullscreenOpen] = useState(false)
  const [activeImageIdx, setActiveImageIdx] = useState(0)

  const onMediaClicked = (idx: number) => {
    setActiveImageIdx(idx)
    setFullscreenOpen(true)
  }

  if (media.length === 0) {
    return null
  }

  // Limit the number of items to show
  // If there are exactly 3 images, only show 2 of them with a +1 overlay
  const displayMedia =
    media.length === 3 ? media.slice(0, 2) : media.slice(0, Math.min(4))
  const mediaCount = displayMedia.length
  const totalCount = media.length

  // Generate media items with proper styling based on count
  const renderMediaItem = (m: any, index: number) => {
    const videoUrl = m.type === 'VIDEO' ? getVideoUrl(m) : null
    // Check if this is a GIF image
    const isGif =
      m.type === 'IMAGE' &&
      (m.content.format === 'image/gif' ||
        m.content.imageUrls.lg?.toLowerCase().endsWith('.gif'))

    return (
      <Column
        key={index}
        sx={{
          position: 'relative',
          width:
            // Show GIFs at half width even when they're the only image
            totalCount === 1 && !isGif
              ? 1
              : totalCount === 3 && index === 2
                ? 1
                : 0.5,
          aspectRatio: '1',
          p: onRemoveImageClicked ? 1 : 0.5,
        }}
        onClick={() => onMediaClicked(index)}
      >
        <Column
          sx={{
            position: 'relative',
            width: 1,
            height: 1,
            borderRadius: 1,
            cursor: 'pointer',
          }}
        >
          {onRemoveImageClicked && (
            <Close
              sx={{
                position: 'absolute',
                top: -8,
                right: -8,
                cursor: 'pointer',
                width: 20,
                height: 20,
                p: 0.25,
                bgcolor: 'rgba(166, 166, 166, 0.9)',
                borderRadius: 100,
                zIndex: 20,
                color: 'white',
                boxShadow: '0px 1px 3px rgba(0,0,0,0.3)',
              }}
              onClick={(e) => {
                e.stopPropagation()
                onRemoveImageClicked(m)
                setActiveImageIdx(0)
              }}
            />
          )}

          {m.type === 'VIDEO' && (
            <video
              playsInline
              autoPlay
              src={videoUrl || undefined}
              muted
              loop
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 8,
              }}
            />
          )}

          {m.type === 'IMAGE' && (
            <img
              src={m.content.imageUrls.lg}
              alt='image'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 8,
              }}
            />
          )}

          {/* Overlay for "more images" indicator */}
          {((mediaCount === 4 && index === 3 && totalCount > 4) ||
            (totalCount === 3 && index === 1)) && (
            <Column
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 1,
                height: 1,
                bgcolor: 'rgba(0,0,0,0.6)',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
              }}
            >
              <Typography variant='h6' sx={{ color: 'white' }}>
                {totalCount === 3 ? '+1' : `+${totalCount - 4}`}
              </Typography>
            </Column>
          )}
        </Column>
      </Column>
    )
  }

  return (
    <Column sx={{ width: 1 }}>
      <Row sx={{ width: 1, flexWrap: 'wrap', m: -0.5 }}>
        {displayMedia.map(renderMediaItem)}
      </Row>

      <ViewMediaModal
        open={fullscreenOpen}
        onClose={() => setFullscreenOpen(false)}
        media={media}
        activeImageIndex={activeImageIdx}
        setActiveImageIndex={setActiveImageIdx}
        useKeypress={true}
        onDeleteImage={
          onRemoveImageClicked &&
          (() => {
            if (media.length === 1) {
              setFullscreenOpen(false)
            }
            onRemoveImageClicked(media[activeImageIdx])
            setActiveImageIdx(0)
          })
        }
      />
    </Column>
  )
}

export default MultimediaRow
