import Row from '../../Row'
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  SxProps,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SendPostBody } from '../../../types/post'
import { isArray } from 'remirror'
import { ChevronRight } from '@mui/icons-material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Theme } from '@mui/material/styles'
import { RsvpStatus } from '../../../types/types'
import AnimatedStateContent from '../../AnimatedStateContent'
import { useCurrentEvent } from '../../../utils/hooks'
import dayjs from 'dayjs'
import { EventDto } from '../../../types/event'
import apiClient from '../../../shared-components/utils/ApiClient'
import { editEvent } from '../../../actions/event'
import { useDispatch } from 'react-redux'

interface Props {
  sendBody: SendPostBody
  setSendBody: React.Dispatch<React.SetStateAction<SendPostBody>>
  sx?: SxProps<Theme>
}

enum PostOption {
  GUESTS = 'GUESTS',
  MAYBE = 'MAYBE',
  CANT = 'CANT',
  INVITED = 'INVITED',
  WAITLIST = 'WAITLIST',
}

const PostRecipientSelection = ({ sendBody, setSendBody, sx = [] }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event: EventDto = useCurrentEvent()

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const ticketsAvailableLater = event.joinOptions?.find(
    (e) => e.availableFrom && dayjs(e.availableFrom).isAfter(dayjs()),
  )

  const loadStatusCounts = async () => {
    const statusCounts = await apiClient.event.getStatusCounts(event.id)
    if (statusCounts) {
      dispatch(
        editEvent({
          ...event,
          statusCountsHost: statusCounts,
        }),
      )
    }
  }

  const onDropdownClick = (e) => {
    // Create a copy of the currentTarget's bounding box to avoid position changes
    const targetCopy = e.currentTarget.getBoundingClientRect()

    // Create a new element to hold the position of the button statically
    const staticAnchor = document.createElement('div')
    staticAnchor.style.position = 'absolute'
    staticAnchor.style.top = `${targetCopy.top + window.scrollY}px`
    staticAnchor.style.left = `${targetCopy.left + window.scrollX}px`
    staticAnchor.style.width = `${targetCopy.width}px`
    staticAnchor.style.height = `${targetCopy.height}px`

    // Append the static element to the body
    document.body.appendChild(staticAnchor)

    setMenuAnchor(staticAnchor)
  }

  const getIsSelected = (option) => {
    if (option === PostOption.WAITLIST) return sendBody.includeWaitingList
    if (option === PostOption.INVITED) return sendBody.includeInvited
    if (option === PostOption.MAYBE)
      return sendBody.statusesToSendTo.includes(RsvpStatus.MAYBE)
    if (option === PostOption.CANT)
      return sendBody.statusesToSendTo.includes(RsvpStatus.CANT)
    if (option === PostOption.GUESTS)
      return (
        sendBody.statusesToSendTo.includes(RsvpStatus.JOINED) &&
        sendBody.statusesToSendTo.includes(RsvpStatus.GOING)
      )
    return false
  }

  const selectedOptionCount = useMemo(
    () => Object.keys(PostOption).filter((o) => getIsSelected(o)).length,
    [sendBody],
  )

  const onOptionClicked = (option: PostOption) => {
    const wasSelected = getIsSelected(option)

    if (selectedOptionCount === 1 && wasSelected) {
      if (option === PostOption.GUESTS) return
      onOptionClicked(PostOption.GUESTS)
    }

    setSendBody((prevSendBody) => {
      const updatedSendBody = { ...prevSendBody }

      switch (option) {
        case PostOption.WAITLIST:
          updatedSendBody.includeWaitingList = !wasSelected
          break

        case PostOption.INVITED:
          updatedSendBody.includeInvited = !wasSelected
          break

        case PostOption.MAYBE:
          updatedSendBody.statusesToSendTo = wasSelected
            ? updatedSendBody.statusesToSendTo.filter(
                (status) => status !== RsvpStatus.MAYBE,
              )
            : [...updatedSendBody.statusesToSendTo, RsvpStatus.MAYBE]
          break

        case PostOption.CANT:
          updatedSendBody.statusesToSendTo = wasSelected
            ? updatedSendBody.statusesToSendTo.filter(
                (status) => status !== RsvpStatus.CANT,
              )
            : [...updatedSendBody.statusesToSendTo, RsvpStatus.CANT]
          break

        case PostOption.GUESTS:
          updatedSendBody.statusesToSendTo = wasSelected
            ? updatedSendBody.statusesToSendTo.filter(
                (status) =>
                  status !== RsvpStatus.JOINED && status !== RsvpStatus.GOING,
              )
            : [
                ...updatedSendBody.statusesToSendTo,
                RsvpStatus.JOINED,
                RsvpStatus.GOING,
              ]
          break

        default:
          break
      }

      return updatedSendBody
    })
  }

  const getOptionGuestCount = (option: PostOption) => {
    if (option === PostOption.GUESTS) return event.statusCountsHost?.totalGoing
    if (option === PostOption.MAYBE) return event.statusCountsHost?.maybe
    if (option === PostOption.CANT) return event.statusCountsHost?.cant
    if (option === PostOption.WAITLIST)
      return event.statusCountsHost?.waitingList
    if (option === PostOption.INVITED) return event.statusCountsHost?.invited
  }

  useEffect(() => {
    loadStatusCounts()
  }, [])

  return (
    <Row
      sx={{
        gap: 1.5,
        alignItems: 'flex-start',
      }}
    >
      <Typography
        variant='button'
        sx={{
          mt: 2,
        }}
      >
        {t('to')}:
      </Typography>

      <Button
        sx={[
          {
            flexGrow: 1,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: 1,
          },
          ...(isArray(sx) ? sx : [sx]),
        ]}
        ref={buttonRef}
        onClick={onDropdownClick}
      >
        <AnimatedStateContent
          getContent={(sendBody) => (
            <Row
              sx={{
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              {Object.keys(PostOption)
                .filter((o) => getIsSelected(o))
                .map((oKey) => (
                  <Typography
                    key={oKey}
                    variant='body2'
                    sx={{
                      p: 1.25,
                      whiteSpace: 'nowrap',
                      bgcolor: 'grey.150',
                      borderRadius: 1,
                    }}
                  >
                    {t(
                      `postRecipient_${oKey === 'WAITLIST' && ticketsAvailableLater ? 'PREREGISTER' : oKey}`,
                    )}
                  </Typography>
                ))}
            </Row>
          )}
          state={sendBody}
          animateHeight={true}
        />

        <ChevronRight
          sx={{
            transform: 'rotate(90deg)',
            width: 20,
            height: 20,
            mt: 1,
            ml: 'auto',
          }}
        />
      </Button>

      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 6,
              width: buttonRef.current?.clientWidth,
              maxHeight: 300,
            },
          },
        }}
      >
        {Object.keys(PostOption).map((oKey) => {
          const option = PostOption[oKey]
          const translationKey =
            option === PostOption.WAITLIST && ticketsAvailableLater
              ? 'PREREGISTER'
              : option
          return (
            <MenuItem
              key={oKey}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1.5,
              }}
              onClick={() => onOptionClicked(option)}
            >
              <Row
                sx={{
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography variant='body2'>
                  {t(`postRecipient_${translationKey}`)}
                </Typography>

                <Typography
                  variant='body2'
                  sx={{
                    color: 'grey.600',
                  }}
                >
                  | {getOptionGuestCount(option)}
                </Typography>
              </Row>

              <Checkbox
                sx={{
                  p: 0,
                  '&.Mui-disabled': {
                    color: 'grey.800',
                  },
                }}
                disabled={
                  selectedOptionCount === 1 &&
                  getIsSelected(option) &&
                  option === PostOption.GUESTS
                }
                disableRipple
                size={'small'}
                value={getIsSelected(option)}
                checked={getIsSelected(option)}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </Row>
  )
}

export default PostRecipientSelection
