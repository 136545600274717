import { SvgIcon, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React from 'react'
import { Check, People, PersonAdd } from '@mui/icons-material'
import { openInviteGuestsModal, openShareModal } from '../../../actions/modals'
import CantIcon from '../../../svg/status/Cant'
import { mdiAccountMultiplePlus, mdiFire } from '@mdi/js'
import { setSnackbar } from '../../../shared-components/redux/notifications/actions'
import MaybeIcon from '../../../svg/status/Maybe'
import GuestManagementExternalGuestsView from './GuestManagementExternalGuestsView'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../../shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(5),
  },
  icon: {
    color: theme.palette.grey[500],
    width: 40,
    height: 40,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    lineHeight: '24px',
    marginTop: 2,
  },
  button: {
    marginTop: theme.spacing(3),
  },
  qrLink: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  hypeText: {
    marginTop: theme.spacing(2),
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  link: {
    flexGrow: 1,
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 40,
    backgroundColor: theme.palette.grey[150],
    textAlign: 'center',
  },
  copyButton: {
    height: 40,
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[800],
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
  },
  othersDivider: {
    width: '100%',
    height: 1,
    background: theme.palette.grey[200],
    margin: theme.spacing(3, 0, 2, 0),
  },
}))

const GuestManagementEmpty = ({ status, query }) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const showButton = ['GOING', 'JOINED', 'MAYBE', 'CANT', 'NONE'].includes(
    status,
  )
  const showHypeActions = !showButton && status === 'HYPE'

  const event = useSelector(getCurrentEvent)

  const eventUrl = `${process.env.NEXT_PUBLIC_RSVP}/${event.code}`

  const showOthers =
    ['JOINED', 'GOING'].includes(status) &&
    event.statusCountsHost?.externalGoing > 0

  const copyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(eventUrl)
      dispatch(setSnackbar('info', t('shared:linkCopiedToClipboard')))
    } else {
      dispatch(setSnackbar('error', 'Failed to copy'))
    }
  }

  const inviteClicked = () => {
    dispatch(openInviteGuestsModal())
  }

  const onOpenEmbedClicked = () => {
    dispatch(openShareModal())
  }

  const icon = {
    GOING: <People className={classes.icon} />,
    JOINED: <People className={classes.icon} />,
    MAYBE: <SvgIcon component={MaybeIcon} className={classes.icon} />,
    CANT: <SvgIcon component={CantIcon} className={classes.icon} />,
    REQUESTED: <Check className={classes.icon} />,
    REQUEST_DENIED: <Check className={classes.icon} />,
    WAITLIST: <Check className={classes.icon} />,
    NONE: (
      <SvgIcon className={classes.icon}>
        <path d={mdiAccountMultiplePlus} />
      </SvgIcon>
    ),
    HYPE: (
      <SvgIcon className={classes.icon}>
        <path d={mdiFire} />
      </SvgIcon>
    ),
  }

  return (
    <div className={classes.root}>
      {icon[status]}
      <Typography variant='subtitle1' className={classes.title}>
        {t(`guestsEmptyTitle.${status}`, { query })}
      </Typography>
      <Typography variant='body1' className={classes.subtitle}>
        {t(`guestsEmptySubtitle.${status}`)}
      </Typography>
      {showButton && (
        <BashButton
          className={cx(classes.button, classes.buttonLabel)}
          type={BashButtonType.PRIMARY_LIGHT}
          onClick={inviteClicked}
          extraView={<PersonAdd />}
          viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
        >
          {t('guestsEmptyButton')}
        </BashButton>
      )}
      {showHypeActions && (
        <>
          <div onClick={copyLink} className={classes.linkContainer}>
            <Typography variant='body2' className={classes.link}>
              {eventUrl}
            </Typography>
            <BashButton type={BashButtonType.PRIMARY_LIGHT} onClick={onOpenEmbedClicked}>
              {t('share')}
            </BashButton>
          </div>
          <Typography variant='body2' className={classes.hypeText}>
            <Trans
              t={t}
              i18nKey={'hypeEmptyText'}
              components={{
                bold: (
                  <a className={classes.qrLink} onClick={onOpenEmbedClicked} />
                ),
              }}
            />
          </Typography>
        </>
      )}
      {showOthers && (
        <>
          <div className={classes.othersDivider} />
          <GuestManagementExternalGuestsView
            className={classes.moreUnknownView}
          />
        </>
      )}
    </div>
  )
}

export default GuestManagementEmpty
