import { makeStyles } from 'tss-react/mui'
import { InputBase, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Check, ChevronRight, ContentCopy } from '@mui/icons-material'
import { debounce } from 'underscore/modules/index-all'
import { setSnackbar } from '../../shared-components/redux/notifications/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import Row from '../Row'
import Column from '../Column'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  caption: {
    color: theme.palette.text.tertiary,
  },
  inputContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    '& > p': {
      fontWeight: 500,
    },
    '& > div': {
      height: 40,
    },
  },
  themeDropdown: {
    // maxWidth: 200,
    backgroundColor: theme.palette.grey[150],
    border: 'none',
    borderRadius: 8,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  textInput: {
    height: 40,
    background: theme.palette.grey[150],
    padding: theme.spacing(0, 2),
    borderRadius: 8,
    '& > div': {
      height: 40,
    },
  },
  menuItem: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  menuList: {
    padding: 0,
  },
  inputsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  copyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: theme.spacing(0, 1),
    lineHeight: '40px',
    height: 40,
    background: theme.palette.grey[150],
    borderRadius: 8,
  },
  copyTextContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  },
  copyButton: {
    height: 40,
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.grey[200],
  },
  previewTitle: {
    fontWeight: 500,
  },
  iframeContainer: {
    width: '100%',
  },
}))

const defaultWidth = 422
const version = '1'

const EmbedEventCard = ({}) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)

  const [width, setWidth] = useState(defaultWidth)
  const [theme, setTheme] = useState<'light' | 'dark' | 'custom'>(
    event.theme.colourScheme != null
      ? 'custom'
      : event.theme.darkMode
        ? 'dark'
        : 'light',
  )

  const [themeAnchor, setThemeAnchor] = useState(null)

  const themeRef = useRef(null)

  const embedHtml = `
          <a
          id='bash-event-a'
          ${`data-width="${width ?? defaultWidth}"`}
          data-version="${version}"
          ${
            ['beta', 'local'].includes(
              process.env.NEXT_PUBLIC_ENVIRONMENT_STAGE,
            )
              ? 'data-beta=true'
              : ''
          }
          data-eventcode="${event.code}"
          data-theme="${theme}"
          ${
            ['local'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT_STAGE)
              ? 'data-localhost=true'
              : ''
          }
          href="${process.env.NEXT_PUBLIC_WEBSITE}/e/${event.code}"
        >
          BASH event
        </a>
        <script src="${
          process.env.NEXT_PUBLIC_WEBSITE
        }/event-widget.js" charset="utf-8" id="iframe-script"></script>
          `

  const update = () => {
    const elem = document.getElementById('insert-here')

    const node = document.getElementById('iframe-script')
    if (node?.parentNode) {
      node.parentNode.removeChild(node)
    }

    const parent = elem?.parentNode
    const script = document.createElement('script')
    script.src = '/event-widget.js'
    script.charset = 'utf-8'
    script.id = 'iframe-script'
    parent?.insertBefore(script, elem)
  }

  const debouncedUpdate = useCallback(
    debounce((width) => {
      onWidthBlur(width)
      update()
    }, 1000),
    [],
  )

  const onCopy = (e) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(embedHtml)
      dispatch(setSnackbar('info', t('copiedHtmlToClipboard')))
    }
  }

  const themeClicked = (newTheme) => {
    setTheme(newTheme)
    setThemeAnchor(null)
    update()
  }

  const onWidthBlur = (newWidth) => {
    const _width = newWidth ?? width
    if (!_width || parseInt(_width) < 320) {
      setWidth(320)
    }
  }

  const onWidthChange = (e) => {
    setWidth(e.target.value)
    debouncedUpdate(e.target.value, null)
  }

  useEffect(() => {
    if (document.getElementById('insert-here')) {
      update()
    } else {
      setTimeout(() => {
        update()
      }, 200)
    }
  }, [])

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.caption} variant='body2'>
          {t('embedModal.cardCaption')}
        </Typography>

        <Row
          sx={{
            gap: 2,
          }}
        >
          <Column
            sx={{
              gap: 1,
              flexGrow: 1,
              flexBasis: '50%',
            }}
          >
            <Typography variant='body2Medium'>{t('width')}</Typography>

            <InputBase
              sx={(theme) => ({
                height: 40,
                px: 1,
                borderRadius: 1,
                background: theme.palette.grey[100] + ' !important',
              })}
              value={width}
              onBlur={onWidthBlur}
              onChange={onWidthChange}
              type='number'
              inputProps={{
                min: 320,
              }}
            />
          </Column>

          <Column
            sx={{
              gap: 1,
              flexGrow: 1,
              flexBasis: '50%',
            }}
          >
            <Typography variant='body2Medium'>{t('theme')}</Typography>

            <div ref={themeRef}>
              <Row
                sx={{
                  height: 40,
                  px: 1,
                  borderRadius: 1,
                  bgcolor: 'grey.100',
                  alignItems: 'center',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                }}
                onClick={(e) => setThemeAnchor(e.currentTarget)}
              >
                <Typography variant='body1'>
                  {theme === 'light'
                    ? t('themeLight')
                    : theme === 'custom'
                      ? t('themeCustom')
                      : t('themeDark')}
                </Typography>

                <ChevronRight
                  style={{
                    transform: 'rotate(90deg)',
                  }}
                />
              </Row>
            </div>
          </Column>
        </Row>

        <Menu
          id='theme-menu'
          anchorEl={themeAnchor}
          keepMounted
          classes={{
            list: classes.menuList,
          }}
          slotProps={{
            paper: {
              style: {
                width: themeRef?.current?.clientWidth,
              },
            },
          }}
          open={!!themeAnchor}
          onClose={() => setThemeAnchor(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => themeClicked('light')}
          >
            {t('themeLight')}
            {theme === 'light' && <Check fontSize='small' />}
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={() => themeClicked('dark')}
          >
            {t('themeDark')}
            {theme === 'dark' && <Check fontSize='small' />}
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={() => themeClicked('custom')}
            disabled={event.theme?.colourScheme == null}
          >
            {t('themeCustom')}
            {theme === 'custom' && <Check fontSize='small' />}
          </MenuItem>
        </Menu>

        <div>
          <Typography variant='body1'>{t('copyThisHtml')}</Typography>
          <Row
            sx={{
              pt: 2,
              gap: 1,
              alignItems: 'center',
              position: 'static',
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Typography
              variant='body2'
              sx={(theme) => ({
                px: 1,
                py: 1.5,
                bgcolor: 'grey.100',
                border: `1px solid ${theme.palette.divider}`,
                color: 'grey.800',
                borderRadius: 1,
                flexGrow: 1,
              })}
              noWrap
            >
              {embedHtml}
            </Typography>

            <BashButton
              extraView={<ContentCopy />}
              viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
              type={BashButtonType.PRIMARY}
              onClick={onCopy}
            >
              {t('copy')}
            </BashButton>
          </Row>
        </div>

        <div className={classes.divider} />

        <Typography variant='body2' className={classes.previewTitle}>
          {t('embedModal.preview')}
        </Typography>

        <div className={classes.iframeContainer}>
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    width: '100%',*/}
          {/*    height: 800,*/}
          {/*    background: 'red',*/}
          {/*  }}*/}
          {/*/>*/}
          <div id='insert-here' />

          <a
            id='bash-event-a'
            data-beta={
              ['beta', 'local'].includes(
                process.env.NEXT_PUBLIC_ENVIRONMENT_STAGE,
              )
                ? 'true'
                : undefined
            }
            data-preview='true'
            data-width={`${width ?? undefined}`}
            data-eventcode={`${event.code}`}
            data-theme={`${theme}`}
            data-version={`${version}`}
            data-localhost={
              ['local'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT_STAGE)
                ? 'true'
                : undefined
            }
            className='bash-timeline'
            href={`${process.env.NEXT_PUBLIC_WEBSITE}/e/${event.code}`}
          >
            Event on BASH
          </a>
        </div>
      </div>
    </>
  )
}

export default EmbedEventCard
