import { useCurrentEvent } from '../../../utils/hooks'
import { idInfoIsEqual } from '../../../utils/eventFunctions'
import { EventDto } from '../../../types/event'
import Row from '../../Row'
import { Box, SxProps, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Theme } from '@mui/material/styles'

interface Props {
  sx?: SxProps<Theme>
}

const InvitedToJoinOptionView = ({ sx = [] }: Props) => {
  const { t } = useTranslation('common')
  const event: EventDto = useCurrentEvent()
  const invitedToJoinOption = event.joinOptions?.find((j) =>
    idInfoIsEqual(j.idInfo, event.myGuest?.invitedToJoinOptionIdInfo ?? null),
  )
  const eventOwner = event.hosts?.find((h) => h.role === 'OWNER')

  if (!invitedToJoinOption || !eventOwner) return null

  return (
    <Row
      sx={[
        {
          gap: 1,
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        component='img'
        src={eventOwner.model.avatarUrls?.lg ?? ''}
        sx={(theme) => ({
          width: 32,
          height: 32,
          borderRadius: 100,
          border: `1px solid ${theme.palette.divider}`,
        })}
      />
      <Typography variant='body2'>
        <Trans
          t={t}
          i18nKey={'nameInvitesYouTo'}
          components={{
            b: (
              <Box
                component='span'
                sx={{
                  fontWeight: 700,
                }}
              />
            ),
          }}
          values={{
            name: eventOwner.model.name,
          }}
        />
      </Typography>
    </Row>
  )
}

export default InvitedToJoinOptionView
