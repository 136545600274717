import { debounce } from 'lodash'

export const validateVatId = (vatId: string, t: (key: string) => string) => {
  // Remove spaces and make uppercase
  vatId = vatId.replace(/\s/g, '').toUpperCase()

  // Check if it starts with NL, followed by 9 digits, B, and 2 digits
  if (!vatId.match(/^NL\d{9}B\d{2}$/)) {
    return t('billingInfo.invalidVatFormat')
  }

  return null
}

export const validateKvkNumber = (kvk: string, t: (key: string) => string) => {
  // Remove any spaces or special characters
  kvk = kvk.replace(/\D/g, '')

  // KvK numbers must be exactly 8 digits
  if (!kvk.match(/^\d{8}$/)) {
    return t('billingInfo.invalidKvkFormat')
  }

  return null
}

export const validatePhoneNumber = (
  phone: string,
  t: (key: string) => string,
) => {
  // Remove spaces, dashes, and parentheses
  phone = phone.replace(/[\s\-()]/g, '')

  // International phone number regex
  const phoneRegex =
    /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

  if (!phoneRegex.test(phone)) {
    return t('billingInfo.invalidPhoneFormat')
  }

  return null
}

export const validateZipCode = (zipCode: string): boolean => {
  return /^[1-9][0-9]{3}\s?[A-Z]{2}$/i.test(zipCode)
}

export const validateHouseNumber = (houseNumber: string): boolean => {
  return /^\d+.*$/.test(houseNumber)
}

export const lookupAddress = async (
  zipCode: string,
  houseNumber: string,
  isCompany: boolean,
  onSuccess: (address: string, city: string, isCompany: boolean) => void,
  onError: (isCompany: boolean) => void,
) => {
  try {
    const formattedZipCode = zipCode.replace(/\s+/g, '').toUpperCase()

    const validZipCode = /^[1-9][0-9]{3}[A-Z]{2}$/.test(formattedZipCode)
    const validHouseNumber = /^\d+.*$/.test(houseNumber)

    if (!validZipCode || !validHouseNumber) {
      return
    }

    const suggestResponse = await fetch(
      `https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=${formattedZipCode}+${houseNumber}`,
    )

    if (!suggestResponse.ok) throw new Error('Suggest API request failed')

    const suggestData = await suggestResponse.json()

    if (!suggestData.response.docs?.[0]?.id) {
      console.log('No suggestions found for this address')
      onError(isCompany)
      return
    }

    const lookupResponse = await fetch(
      `https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup?id=${suggestData.response.docs[0].id}`,
    )

    if (!lookupResponse.ok) throw new Error('Lookup API request failed')

    const lookupData = await lookupResponse.json()

    if (lookupData.response.docs?.[0]) {
      const address = lookupData.response.docs[0]
      onSuccess(address.straatnaam, address.woonplaatsnaam, isCompany)
    }
  } catch (error) {
    console.error('Error fetching address:', error)
    onError(isCompany)
  }
}

export const createDebouncedAddressLookup = (
  onSuccess: (address: string, city: string, isCompany: boolean) => void,
  onError: (isCompany: boolean) => void,
) => {
  return debounce(
    (zipCode: string, houseNumber: string, isCompany: boolean) => {
      const validZipCode = /^[1-9][0-9]{3}\s?[A-Z]{2}$/i.test(zipCode)
      const validHouseNumber = /^\d+.*$/.test(houseNumber)

      if (validZipCode && validHouseNumber) {
        lookupAddress(zipCode, houseNumber, isCompany, onSuccess, onError)
      }
    },
    500,
  )
}
