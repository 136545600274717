import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { InputBase } from '@mui/material'
import QuestionContainer from './QuestionContainer'
import { NumberQuestionProps } from './types'

const useStyles = makeStyles()((theme) => ({
  iconisedInput: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    background: `${theme.palette.background.input} !important`,
    borderRadius: theme.spacing(1),
    width: 'fit-content',
    height: '40px !important',
  },
  noStyleInput: {
    '& input': {
      padding: 0,
      background: `${theme.palette.background.input} !important`,
    },
  },
}))

const NumberQuestion: React.FC<NumberQuestionProps> = ({
  title,
  required,
  isError,
  value,
  onChange,
}) => {
  const { classes } = useStyles()

  return (
    <QuestionContainer title={title} required={required} isError={isError}>
      <div className={classes.iconisedInput}>
        <InputBase
          className={classes.noStyleInput}
          type='number'
          value={value}
          placeholder='0'
          onChange={(e) => onChange(e.target.value)}
          inputProps={{
            min: 0,
            style: { textAlign: 'end' },
          }}
        />
      </div>
    </QuestionContainer>
  )
}

export default NumberQuestion
