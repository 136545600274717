import React from 'react'
import { makeStyles } from 'tss-react/mui'
import QuestionContainer from './QuestionContainer'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { DateQuestionProps } from './types'

const useStyles = makeStyles()((theme) => ({
  datePickerRoot: {
    width: '200px',
  },
  datePicker: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.input,
      borderRadius: 6,
      border: 'none',
    },
    '& .Mui-error': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 1.5),
    },
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
}))

const DateQuestion: React.FC<DateQuestionProps> = ({
  title,
  required,
  isError,
  value,
  onChange,
  validations,
}) => {
  const { classes } = useStyles()

  return (
    <QuestionContainer title={title} required={required} isError={isError}>
      <DatePicker
        onChange={(e) => {
          if (e) {
            onChange(e.format('YYYY-MM-DD'))
          }
        }}
        maxDate={validations?.includes('BEFORE_TODAY') ? dayjs() : undefined}
        value={dayjs(value)}
        format='DD/MM/YYYY'
        className={classes.datePickerRoot}
        slotProps={{
          textField: {
            className: classes.datePicker,
          },
        }}
      />
    </QuestionContainer>
  )
}

export default DateQuestion
