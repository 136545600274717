import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '8px 12px',
    border: '1px solid #E9E9E9',
    borderRadius: '8px',
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 160,
    minWidth: 80,
    wordWrap: 'break-word',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.5,
  },
  caption: {
    color: theme.palette.text.secondary,
    marginTop: 8,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  name: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  unlockedIcon: {
    width: 12,
    height: 12,
    marginLeft: 4,
  },
}))

export const EURO = new Intl.NumberFormat('en-EN', {
  style: 'currency',
  currency: 'EUR',
})

// const TicketOption = ({ ticketOption }) => {
//   const classes = useStyles()
//   const { t } = useTranslation('common')

//   const text = () => {
//     const priceText = EURO.format(ticketOption.price / 100)

//     if (ticketOption.quantityAvailable === 0) {
//       return t('ticketOption.soldOut').toUpperCase()
//     }
//     if (
//       ticketOption.availableFrom &&
//       dayjs(ticketOption.availableFrom).isAfter(dayjs())
//     ) {
//       return `${t('externalTickets.availableFromDate', {
//         date: dayjs(ticketOption.availableFrom).format('d MMM HH:mm'),
//       })}`
//     } else if (ticketOption.price) {
//       if (
//         ticketOption.availableFrom &&
//         dayjs(ticketOption.availableFrom).isAfter(dayjs())
//       ) {
//         return `${priceText} - ${t('externalTickets.availableFromDate', {
//           date: dayjs(ticketOption.availableFrom).format('D MMM HH:mm'),
//         })}`
//       }
//       if (ticketOption.description) {
//         return `${priceText} - ${ticketOption.description}`
//       }
//       return `${priceText}`
//     } else if (ticketOption.description) {
//       return ticketOption.description
//     } else if (ticketOption.chooseYourOwnPrice) {
//       return t('newCreation.ticketsAdvanced.nameYourOwnPrice')
//     }
//     return ''
//   }

//   return (
//     <div
//       className={cn(
//         classes.root,
//         ticketOption.available === false && classes.disabled,
//       )}
//     >
//       <Typography className={classes.name} variant='body2'>
//         {ticketOption.name}
//         {ticketOption.hidden && (
//           <SvgIcon className={classes.unlockedIcon}>
//             <path d={mdiLockOpen} />
//           </SvgIcon>
//         )}
//       </Typography>
//       <Typography className={classes.caption} variant='caption'>
//         {text()}
//       </Typography>
//     </div>
//   )
// }

// export default TicketOption
