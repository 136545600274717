import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ClickableAvatar from 'shared-components/avatar/ClickableAvatar'
import apiClient from 'shared-components/utils/ApiClient'
import { getGuestName } from 'shared-components/utils/eventDisplay'
import BashButton, {
  BashButtonType,
} from 'shared-components/buttons/BashButton'
import ConfirmBottomSheet from './ConfirmBottomSheet'

const useStyles = makeStyles()((theme) => ({
  title: {
    textAlign: 'center',
    padding: theme.spacing(3, 2, 1, 2),
  },
  content: {
    padding: theme.spacing(0, 2),
    height: 250,
    overflowY: 'auto',
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  ticketItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'space-between',
  },
  errorText: {
    color: theme.palette.error.main,
    margin: theme.spacing(1, 0),
  },
}))

const CancelTicketDialog = ({ open, onClose, guest, refresh }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedTickets, setSelectedTickets] = useState([])
  const [activeTickets, setActiveTickets] = useState([])
  const [confirmOpen, setConfirmOpen] = useState(false)

  // Load active tickets when dialog opens
  useEffect(() => {
    if (open && guest && guest.tickets) {
      const active = guest.tickets.filter(
        (ticket) => ticket.status === 'ACTIVE',
      )
      setActiveTickets(active)
      setSelectedTickets([])
    }
  }, [open, guest])

  const handleToggleTicket = (ticket) => {
    setSelectedTickets((prevSelected) => {
      const isSelected = prevSelected.some((t) => t.id === ticket.id)
      if (isSelected) {
        return prevSelected.filter((t) => t.id !== ticket.id)
      } else {
        return [...prevSelected, ticket]
      }
    })
  }

  const handleConfirmCancelTickets = async () => {
    setLoading(true)
    try {
      const ticketIds = selectedTickets.map((ticket) => ticket.id)
      await apiClient.tickets.revokeTicket(ticketIds)

      onClose()
      refresh()
    } catch (err) {
      console.error(err)
      setError(t('somethingWentWrong'))
    } finally {
      setLoading(false)
      setConfirmOpen(false)
    }
  }

  const handleCancelTickets = () => {
    if (selectedTickets.length === 0) return
    setConfirmOpen(true)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={loading ? null : onClose}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle className={classes.title}>
          <Typography variant='h6'>{t('cancelTickets')}</Typography>
          <div className={classes.nameContainer}>
            <ClickableAvatar
              userOrGuest={guest}
              size={24}
              disableClick
              className={classes.avatar}
            />
            <Typography variant='body2'>{getGuestName(guest)}</Typography>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
          {error && (
            <Typography className={classes.errorText}>{error}</Typography>
          )}
          <List>
            {activeTickets.map((ticket) => (
              <ListItem
                key={ticket.id}
                className={classes.ticketItem}
                button
                onClick={() => !loading && handleToggleTicket(ticket)}
              >
                <ListItemText primary={`Ticket ${ticket.id}`} />
                <ListItemIcon>
                  <Checkbox
                    checked={selectedTickets.some((t) => t.id === ticket.id)}
                    color='primary'
                    disabled={loading}
                  />
                </ListItemIcon>
              </ListItem>
            ))}
            {activeTickets.length === 0 && (
              <Typography
                variant='body2'
                style={{ padding: 16, textAlign: 'center' }}
              >
                {t('noActiveTickets')}
              </Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <BashButton
            type={BashButtonType.CLEAR_PRIMARY}
            onClick={onClose}
            enabled={!loading}
            height={40}
          >
            {t('back')}
          </BashButton>
          <BashButton
            type={BashButtonType.WHITE_RED_BORDERED}
            onClick={handleCancelTickets}
            enabled={!loading && selectedTickets.length > 0}
            height={40}
          >
            {t('cancelTickets')}
          </BashButton>
        </DialogActions>
      </Dialog>

      <ConfirmBottomSheet
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title={t('cancelTicketsTitle')}
        description={t('cancelTicketsDescription')}
        cancelText={t('no')}
        confirmText={t('cancelTicketsConfirm')}
        onConfirm={handleConfirmCancelTickets}
        loading={loading}
        isDanger={true}
      />
    </>
  )
}

export default CancelTicketDialog
