import React from 'react'
import { IconButton, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import PinDateSubtitle from '../../pinning/PinDateSubtitle'
import { setDateResponse } from '../../../actions/pinning'
import { CancelRounded, CheckCircle, HelpRounded } from '@mui/icons-material'
import PinResponseBar from '../../../shared-components/event/pinning/PinResponseBar'
import AvatarGroup from '../../../shared-components/avatar/AvatarGroup'
import ClickableAvatar from '../../../shared-components/avatar/ClickableAvatar'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
  },
  pinningTopDivider: {
    marginTop: theme.spacing(3),
  },
  rsvpTopDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  rsvpDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  rsvpBottomDivider: {
    marginTop: theme.spacing(2),
  },
  rsvpOption: {
    cursor: 'pointer',
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  rsvpOptionText: {
    fontWeight: 600,
    marginRight: 'auto',
  },
  rsvpIcon: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1.5),
  },
  rsvpRadio: {
    padding: 0,
  },
  dateOptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    backgroundColor:
      theme.extras.name === 'custom' && theme.palette.mode === 'dark'
        ? theme.palette.background.secondary
        : theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  dateOptionSelectRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    fontSize: 24,
    color: '#ccc',
  },
  statusIconButton: {
    padding: 6,
  },
  cantColor: {
    color: '#EE6C4D',
  },
  goingColor: {
    color: '#8B30F3',
  },
  maybeColor: {
    color: '#2E60DC',
  },
  nextButton: {
    marginTop: theme.spacing(3),
  },
  pinningScrollContainer: {
    overflowY: 'auto',
    margin: theme.spacing(0, -3),
    padding: theme.spacing(0, 3),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    cursor: 'pointer',
    marginRight: theme.spacing(2),
  },
  dateOptionClickArea: {
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 116,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(3),
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  notSelected: {
    border: '2px solid transparent',
  },
  clickable: {
    cursor: 'pointer !important',
  },
}))

export const DateOption = ({ dateOption }) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const { id } = dateOption
  const event = useSelector(getCurrentEvent)
  const dateResponses = useSelector((state) => state.pinning.dateResponses)
  const status = dateResponses[id]

  if (!dateOption.date && dateOption.hiddenIfNull) return null
  return (
    <div key={id} className={classes.dateOptionContainer}>
      <div className={classes.dateOptionSelectRow}>
        <PinDateSubtitle dateOption={dateOption} />
        <div className={classes.statusContainer}>
          <IconButton
            className={classes.statusIconButton}
            onClick={() => dispatch(setDateResponse(id, 'CANT'))}
            size='large'
          >
            <CancelRounded
              className={cx(classes.statusIcon, {
                [classes.cantColor]: status === 'CANT',
              })}
            />
          </IconButton>
          <IconButton
            className={classes.statusIconButton}
            onClick={() => dispatch(setDateResponse(id, 'MAYBE'))}
            size='large'
          >
            <HelpRounded
              className={cx(classes.statusIcon, {
                [classes.maybeColor]: status === 'MAYBE',
              })}
            />
          </IconButton>
          <IconButton
            className={classes.statusIconButton}
            onClick={() => dispatch(setDateResponse(id, 'GOING'))}
            size='large'
          >
            <CheckCircle
              className={cx(classes.statusIcon, {
                [classes.goingColor]: status === 'GOING',
              })}
            />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export const DateOptionResponses = ({
  dateOption,
  onClick,
  selected,
}: {
  dateOption: any
  onClick?: () => void
  selected?: boolean
}) => {
  const { id } = dateOption
  const { classes, cx } = useStyles()

  if (!dateOption.date && dateOption.hiddenIfNull) return null

  return (
    <div
      key={id}
      className={cx(
        classes.dateOptionContainer,
        selected ? classes.selected : classes.notSelected,
        onClick != null && classes.clickable,
      )}
      onClick={onClick}
    >
      <div className={classes.leftContainer}>
        <PinDateSubtitle dateOption={dateOption} />
        <PinResponseBar dateOption={dateOption} />
      </div>
      {dateOption?.guestStatuses?.length > 0 && (
        <div className={classes.rightContainer}>
          <AvatarGroup max={3} noOverflow>
            {dateOption?.guestStatuses?.map((g, index) => (
              <ClickableAvatar key={index} userOrGuest={g.guest} size={24} />
            ))}
          </AvatarGroup>
        </div>
      )}
    </div>
  )
}
