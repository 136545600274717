import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useEffect, useState } from 'react'
import { formatShortNumber } from '../../../utils/eventFunctions'
import GuestManagementScroller from './GuestManagementScroller'
import GuestManagementEmpty from './GuestManagementEmpty'
import { usePrevious } from '../../../shared-components/utils/hooks'
import Column from '../../../components/Column'
import SwipeableViews from 'react-swipeable-views-react-18-fix'

const GuestManagementList = ({ sortType }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const refreshList = useSelector((state) => state.modals.refreshList)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDatepoll = event.type === 'PINNING'
  const goingCount = event.hasJoinOptions
    ? event.statusCountsHost?.totalGoing
    : event.statusCountsHost?.going

  const hasJoinOptions = !isDatepoll && event.hasJoinOptions
  const fullyEmpty =
    event.statusCountsHost == null ||
    (isDatepoll
      ? event.statusCountsHost?.gaveAvailability === 0
      : (hasJoinOptions
          ? event.statusCountsHost?.joined
          : event.statusCountsHost?.going) +
          event.statusCountsHost?.maybe +
          event.statusCountsHost?.cant ===
        0)
  const [tab, setTab] = useState(
    fullyEmpty ? '' : hasJoinOptions ? 'JOINED' : 'GOING',
  )

  const prevStatusCounts = usePrevious(event.statusCountsHost)

  useEffect(() => {
    if (
      tab === '' &&
      (refreshList.name === 'JOINED' || refreshList.name === 'GOING')
    ) {
      setTab(hasJoinOptions ? 'JOINED' : 'GOING')
    }
  }, [refreshList.counter, refreshList.name])

  useEffect(() => {
    if (!prevStatusCounts && event.statusCountsHost && !fullyEmpty) {
      setTab(hasJoinOptions ? 'JOINED' : 'GOING')
    }
  }, [event.statusCountsHost, prevStatusCounts, fullyEmpty])

  const handleChangeIndex = (index) => {
    const tabs = hasJoinOptions ? ['JOINED', 'MAYBE', 'CANT'] : ['GOING', 'MAYBE', 'CANT']
    setTab(tabs[index])
  }

  const getTabIndex = () => {
    const tabs = hasJoinOptions ? ['JOINED', 'MAYBE', 'CANT'] : ['GOING', 'MAYBE', 'CANT']
    return tabs.indexOf(tab)
  }

  const renderTabs = () => (
    <>
      <Tabs
        sx={{
          '& .MuiTabs-indicator': {
            bgcolor: 'text.primary',
          },
        }}
        variant='fullWidth'
        value={tab}
        onChange={(e, newTab) => setTab(newTab)}
        aria-label='attendees-tabs'
        textColor='inherit'
        indicatorColor='inherit'
      >
        <Tab
          key={hasJoinOptions ? 'JOINED' : 'GOING'}
          value={hasJoinOptions ? 'JOINED' : 'GOING'}
          sx={{
            minWidth: 120,
            '&.Mui-selected': {
              color: 'text.primary',
            },
          }}
          disabled={fullyEmpty}
          label={`${t(
            'shared:rsvp.going',
          )} ${goingCount ? `(${formatShortNumber(goingCount)})` : ''}`}
        />
        <Tab
          key='MAYBE'
          value='MAYBE'
          sx={{
            minWidth: 120,
            '&.Mui-selected': {
              color: 'text.primary',
            },
          }}
          disabled={fullyEmpty}
          label={`${t(
            'shared:rsvp.maybe',
          )} ${event.statusCountsHost?.maybe ? `(${formatShortNumber(event.statusCountsHost?.maybe)})` : ''}`}
        />
        <Tab
          key='CANT'
          value='CANT'
          sx={{
            minWidth: 120,
            '&.Mui-selected': {
              color: 'text.primary',
            },
          }}
          disabled={fullyEmpty}
          label={`${t('shared:rsvp.cant')} ${
            event.statusCountsHost?.cant
              ? `(${formatShortNumber(event.statusCountsHost?.cant)})`
              : ''
          }`}
        />
      </Tabs>
      <Box sx={{ width: 1, height: '1px', bgcolor: 'grey.200' }} />
    </>
  )

  return (
    <Column sx={{ height: '100%' }}>
      {!isDatepoll && renderTabs()}

      {fullyEmpty && <GuestManagementEmpty status={'GOING'} />}

      {isMobile ? (
        <SwipeableViews
          index={getTabIndex()}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
          resistance
          style={{ width: '100%', height: '100%' }}
          containerStyle={{ height: '100%' }}
        >
          <GuestManagementScroller
            event={event}
            status={event.hasJoinOptions ? 'JOINED' : 'GOING'}
            tab={tab}
            sortType={sortType}
          />
          <GuestManagementScroller
            event={event}
            status={'MAYBE'}
            tab={tab}
            sortType={sortType}
          />
          <GuestManagementScroller
            event={event}
            status={'CANT'}
            tab={tab}
            sortType={sortType}
          />
        </SwipeableViews>
      ) : (
        <>
          <GuestManagementScroller
            event={event}
            status={event.hasJoinOptions ? 'JOINED' : 'GOING'}
            tab={tab}
            sortType={sortType}
          />
          <GuestManagementScroller
            event={event}
            status={'MAYBE'}
            tab={tab}
            sortType={sortType}
          />
          <GuestManagementScroller
            event={event}
            status={'CANT'}
            tab={tab}
            sortType={sortType}
          />
        </>
      )}
    </Column>
  )
}

export default GuestManagementList
