import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Paper } from '@mui/material'
import Logo from 'svg/logo/Icon'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { useDownloadApp } from '../../shared-components/utils/downloadApp'
import { appContext } from '../../shared-components/event/App'
import { withSplitTreatments } from '@splitsoftware/splitio-react'
import { useTranslation } from 'react-i18next'
import { getIsMobileOrTablet } from 'utils/isMobileOrTablet'

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  logo: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  logoContainer: {
    width: '26px',
    height: '26px',
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.grey.main,
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  textContainer: {},
  title: {
    fontWeight: '500',
    fontSize: '.75rem',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: '0.6875rem',
  },
  getButton: {
    marginLeft: 'auto',
    minWidth: '50px',
    fontWeight: '600',
  },
  headerRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    background: theme.palette.grey[100],
    borderBottom: '.5px #DEDEDE solid',
    width: '100%',
    marginTop: theme.spacing(-2),
  },
}))

export const GetBashContent = ({ onGet }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const { event } = useContext(appContext)
  const { onDownloadClick, QRCodeModal, downloadUrl } = useDownloadApp({
    utm_medium: 'get-bash-card',
    event,
    usingHref: true,
  })

  return (
    <>
      <div className={classes.logoContainer}>
        <Logo className={classes.logo} />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.title}>{t('getBashCard.title')}</div>
        <div className={classes.subtitle}>{t('getBashCard.subtitle')}</div>
      </div>
      <FlatButton
        href={downloadUrl}
        onClick={onDownloadClick}
        size='small'
        className={classes.getButton}
      >
        {t('getBashCard.button')}
      </FlatButton>
      <QRCodeModal />
    </>
  )
}

const GetBashContentSplit = withSplitTreatments(
  ['get_bash_app_store_variant_2'],
  { isMobileOrTablet: getIsMobileOrTablet() },
)(({ treatments, isReady, onGet, useHeader }) => {
  const treatment = treatments.get_bash_app_store_variant_2.treatment
  const { classes } = useStyles()

  return (
    <>
      {useHeader && treatment === 'header' && (
        <div className={classes.headerRoot}>
          <GetBashContent onGet={onGet} />
        </div>
      )}
      {!useHeader && treatment === 'card' && (
        <Paper elevation={0} className={classes.root}>
          <GetBashContent onGet={onGet} />
        </Paper>
      )}
    </>
  )
})

const GetBashCard = ({ useHeader }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (window.localStorage && !window.localStorage.getItem('hasSeenBash')) {
      setVisible(true)
    }
  }, [visible])

  if (!visible) return null

  // return useHeader ?
  //   (
  //     <div className={classes.headerRoot}>
  //       <GetBashContent onGet={onGet} />
  //     </div>
  //   ) :
  //   (showCardVariant && (
  //     <Paper elevation={2} className={classes.root}>
  //       <GetBashContent onGet={onGet} />
  //     </Paper>
  //   ))
  return <GetBashContentSplit useHeader={useHeader} />
}

export default GetBashCard
