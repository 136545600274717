import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import mixpanel from 'shared-components/utils/mixpanel'

export const checkForFirstName = (
  user,
  redirectTo,
  goHome,
  redirectIfName = true,
) => {
  // identify must be called along with every instance of people.set
  mixpanel.identify(user.id)

  mixpanel.people.set({ 'User ID': user.id })
  mixpanel.people.set({ 'Sign up date': user.joined })

  if (!user.firstName || !user.lastName) {
    if (redirectTo) {
      // Router.replace(`/profile?redirectTo=${redirectTo}`)
      if (
        typeof window !== 'undefined' &&
        !window.location.pathname.startsWith('/profile')
      ) {
        window.location.replace(`/profile?redirectTo=${redirectTo}`)
      }
    } else {
      if (
        typeof window !== 'undefined' &&
        !window.location.pathname.startsWith('/profile')
      ) {
        window.location.replace('/profile')
      }
      // Router.replace('/profile')
    }
    // } else if (user.hasAvatar !== null && !user.hasAvatar) {
    //   console.log('no avatar')
    //   console.log('hasAvatar: ' + user.hasAvatar)
    //   console.log(user)
    //   console.log(redirectTo, goHome)
    //   if (redirectTo) {
    //     // Router.replace(`/profile?redirectTo=${redirectTo}`)
    //     if (
    //       typeof window !== 'undefined' &&
    //       !window.location.pathname.startsWith('/profile')
    //     ) {
    //       window.location.replace(`/profile/avatar?redirectTo=${redirectTo}`)
    //     }
    //   } else {
    //     if (
    //       typeof window !== 'undefined' &&
    //       !window.location.pathname.startsWith('/profile')
    //     ) {
    //       window.location.replace('/profile/avatar')
    //     }
    //     // Router.replace('/profile')
    //   }
  } else if (redirectIfName && redirectTo) {
    // Router.replace(redirectTo)
    if (
      typeof window !== 'undefined' &&
      !window.location.pathname.startsWith('/profile')
    ) {
      window.location.replace(redirectTo)
    }
  } else if (redirectIfName && goHome) {
    if (
      typeof window !== 'undefined' &&
      !window.location.pathname.startsWith('/profile')
    ) {
      window.location.replace('/home')
    }
    // Router.push('/home')
  }
}

export const getNextBirthday = (birthday) => {
  if (!birthday) {
    return null
  }
  const now = dayjs()
  const birthdayThisYear = dayjs(birthday).year(now.year())

  // If the birthday has already occurred this year, calculate for next year
  if (birthdayThisYear.isAfter(now)) {
    return birthdayThisYear // Birthday is this year
  } else {
    return birthdayThisYear.add(1, 'year') // Next year
  }
}

export const useUser = () => {
  const { user, isRejected, isFulfilled, pending } = useSelector(
    (state) => state.user,
  )
  const ready = (isRejected || isFulfilled) && !pending
  const isLoggedIn = ready && !!user?.id
  return { user, ready, isLoggedIn }
}

export const showSocialProof = (event) => {
  if (event.socialProof && event.privacyType === 'PUBLIC') {
    if ((event.socialProof.count ?? 0) > 0) {
      return true
    }
    if ((event.hype?.hypeScore ?? 0) > 99) {
      return true
    }
  }
  return false
}
