import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIcon,
  Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    color: theme.palette.red.main,
    width: 63,
    height: 57,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  destructive: {
    color: theme.palette.red.main,
  },
  progress: {
    marginLeft: theme.spacing(1),
  },
  body1High: {
    lineHeight: '24px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const YesNoDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  subtitle,
  description,
  cancelText,
  confirmText,
  icon,
  loading,
  destructive,
}) => {
  const { classes } = useStyles()
  return (
    <Dialog
      aria-labelledby='confirm-action-dialog'
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        <Typography className={classes.title} variant='h5'>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant='body1' className={classes.body1High}>
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      {(icon || description) && (
        <DialogContent>
          {icon && (
            <SvgIcon className={classes.image}>
              <path d={icon} />
            </SvgIcon>
          )}
          <DialogContentText color='textPrimary' variant='body1'>
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          {cancelText}
        </Button>
        <Button
          disabled={loading}
          className={destructive ? classes.destructive : null}
          onClick={onConfirm}
          color='primary'
        >
          {confirmText}
          {loading && (
            <CircularProgress
              className={classes.progress}
              size={20}
              color='inherit'
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default YesNoDialog
