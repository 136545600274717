import React from 'react'

import { Backdrop, Box, Button, Dialog, Slide, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import FullScreenSheetTransition from '../modals/FullScreenSheetTransition'
import { ChevronLeft, Close } from '@mui/icons-material'

/**
 * Prevents scrolling of content behind the backdrop.
 */
export class BackDropIOSWorkaround extends React.PureComponent {
  onTouchMove(event) {
    event.preventDefault()
  }

  render() {
    return <Backdrop {...this.props} onTouchMove={this.onTouchMove} />
  }
}

export const SlideTransition = React.forwardRef(
  function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
  },
)

const BottomSheet = ({
  title,
  children,
  open,
  onClose,
  onBack,
  disableBackdropClick,
  className,
  fullScreen,
  topRightContent,
  contentClassName,
  maxWidth,
  contentSx = [],
  keepMounted = false,
  noTitleBorder = false,
}) => {
  const theme = useTheme()

  const onCloseOverride = (e, reason) => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      return
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      classes={{
        paper: className,
      }}
      PaperProps={{
        sx: (theme) => ({
          transition: 'all 200ms',
          maxHeight: fullScreen ? 'none' : '90vh',
          minWidth: theme.breakpoints.values.container,
          background: `${theme.palette.background.paper} !important`,
          border: theme.palette.background.modalBorder,
          [theme.breakpoints.down(theme.breakpoints.values.container + 1)]: {
            position: 'fixed',
            bottom: 0,
            maxWidth: '100%',
            minWidth: '100%',
            margin: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            paddingBottom: 'env(safe-area-inset-bottom)',
          },
          ...(fullScreen && {
            height: '100vh',
            maxHeight: 'none',
          }),
        }),
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: 'background.backdrop',
          },
        },
      }}
      BackdropComponent={BackDropIOSWorkaround}
      open={open ?? false}
      onClose={onCloseOverride}
      TransitionComponent={FullScreenSheetTransition}
      transitionDuration={150}
      maxWidth={maxWidth}
      keepMounted={keepMounted}
    >
      {title && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(2),
            position: 'relative',
            borderBottom: !noTitleBorder
              ? `1px solid ${theme.palette.divider}`
              : 'none',
            width: '100%',
            minHeight: 56,
          }}
        >
          <Typography
            variant='h6'
            sx={{
              width: '100%',
              textAlign: 'center',
              padding: theme.spacing(0, 5),
            }}
          >
            {title}
          </Typography>
          {onClose && (
            <Button
              onClick={onClose}
              sx={{
                minHeight: 0,
                minWidth: 0,
                width: 40,
                height: 40,
                borderRadius: '50%',
                position: 'absolute',
                left: theme.spacing(1),
                top: 8,
              }}
            >
              <Close />
            </Button>
          )}
          {onBack && (
            <Button
              onClick={onBack}
              sx={{
                minHeight: 0,
                minWidth: 0,
                width: 40,
                height: 40,
                borderRadius: '50%',
                position: 'absolute',
                left: theme.spacing(1),
                top: 8,
              }}
            >
              <ChevronLeft />
            </Button>
          )}
          {topRightContent && (
            <Box
              sx={{
                position: 'absolute',
                right: theme.spacing(1),
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              {topRightContent}
            </Box>
          )}
        </Box>
      )}
      <Box
        className={contentClassName}
        sx={[
          {
            padding: theme.spacing(3, 3, 3, 3),
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          },
          ...(Array.isArray(contentSx) ? contentSx : [contentSx]),
        ]}
      >
        {children}
      </Box>
    </Dialog>
  )
}

export default BottomSheet
