import React from 'react'
import { Typography } from '@mui/material'
import BottomSheet from '../common/BottomSheet'
import BashButton, {
  BashButtonType,
} from 'shared-components/buttons/BashButton'
import Row from '../Row'

/**
 * A reusable bottom sheet for confirmation dialogs
 */
const ConfirmBottomSheet = ({
  open,
  onClose,
  title,
  description,
  confirmText,
  cancelText,
  onConfirm,
  loading = false,
  isDanger = true,
}) => {
  return (
    <BottomSheet open={open} onClose={onClose} title={title}>
      <Typography variant='body1' sx={{ lineHeight: '24px' }}>
        {description}
      </Typography>

      <Row
        sx={{
          gap: 2,
          mt: 4,
          justifyContent: 'flex-end',
        }}
      >
        <BashButton
          type={BashButtonType.WHITE_PRIMARY_BORDERED}
          onClick={onClose}
          enabled={!loading}
        >
          {cancelText}
        </BashButton>

        <BashButton
          type={
            isDanger
              ? BashButtonType.WHITE_RED_BORDERED
              : BashButtonType.PRIMARY
          }
          onClick={onConfirm}
          loading={loading}
        >
          {confirmText}
        </BashButton>
      </Row>
    </BottomSheet>
  )
}

export default ConfirmBottomSheet
