import { withStyles } from 'tss-react/mui'
import React, { forwardRef } from 'react'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import classNames from 'clsx'
import { InputBase, Typography } from '@mui/material'

const styles = (theme) => ({
  inputLabel: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1.5),
    fontSize: '0.875rem',
    fontWeight: '500',
    marginTop: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(3),
  },
})

const TextInputBase = withStyles(InputBase, (theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[150],
    backgroundColor: theme.palette.divider,
    border: 'none',
    borderRadius: 8,
    width: '100%',
    padding: '15px',
    fontSize: '16px',
    lineHeight: '24px',
    '& input': {
      padding: 0,
      height: '20px',
    },
  },
  multiline: {
    padding: theme.spacing(2) + ' !important',
  },
}))

export const InputHelperText = withStyles(FormHelperText, (theme) => ({
  root: {
    color: theme.palette.text.secondary,
    display: 'none',
    marginTop: theme.spacing(1),
  },
  error: {
    display: 'block',
  },
}))

const TextInput = forwardRef(
  (
    {
      label,
      customClass,
      error,
      helperText,
      errorText,
      classes,
      rowAndFixed,
      fixedLabelSize,
      ...other
    },
    ref,
  ) => {
    return (
      <FormControl
        variant='standard'
        error={error}
        className={classNames(
          classes.fullWidth,
          rowAndFixed && classes.row,
          customClass,
        )}
      >
        {label && (
          <Typography
            variant='body2'
            style={{
              width: fixedLabelSize,
            }}
            className={classNames(classes.inputLabel)}
          >
            {label}
          </Typography>
        )}
        <TextInputBase
          ref={ref}
          {...other}
          inputProps={{ ...other.inputProps }}
        />
        <InputHelperText>{helperText || errorText}</InputHelperText>
      </FormControl>
    )
  },
)

export default withStyles(TextInput, styles)
