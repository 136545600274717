import { Avatar, Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { setEditingPost } from '../../../actions/modals'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrganisationDto } from '../../../types/organisation'
import { PostDto, PosterDto } from '../../../types/post'
import { useCurrentEvent } from '../../../utils/hooks'
import { EventDto } from '../../../types/event'
import { RsvpStatus } from '../../../types/types'
import { UserDto } from '../../../types/user'
import { useUser } from '../../../utils/userFunctions'
import { getIsSuperAdmin } from '../../../selectors/event'

interface Props {
  open: boolean
}

const PostPosterDropdown = ({ open }: Props) => {
  const { user } = useUser()
  const isSuperAdmin = useSelector(getIsSuperAdmin)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const event: EventDto = useCurrentEvent()
  const editingPost: PostDto = useSelector(
    (state: any) => state.modals.editingPost,
  )
  const postAsHost: boolean = useSelector(
    (state: any) => state.modals.postAsHost,
  )

  const hasGuestThatCanPost = (
    [
      RsvpStatus.JOINED,
      RsvpStatus.GOING,
      RsvpStatus.MAYBE,
      RsvpStatus.CANT,
    ] as (RsvpStatus | null | undefined)[]
  ).includes(event.myGuest?.status)

  const managerHosts = event.hosts.filter(
    (host) =>
      host.state === 'ACTIVE' &&
      ((host.type === 'ORGANISATION' &&
        ((host.model as OrganisationDto).manager || isSuperAdmin)) ||
        (host.type === 'USER' && (host.model as UserDto).id === user.id)),
  )

  const posters: PosterDto[] = postAsHost
    ? (managerHosts.map((h) => ({
        type: h.type,
        model: h.model,
      })) ?? [])
    : hasGuestThatCanPost
      ? [
          {
            type: 'GUEST',
            model: event.myGuest!,
          },
        ]
      : []

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (editingPost.poster.type === 'USER' && !editingPost.id && postAsHost) {
      if (managerHosts.filter((h) => h.type === 'ORGANISATION').length > 0) {
        // New post, select first managing organisation
        dispatch(
          setEditingPost({
            ...editingPost,
            poster: managerHosts.filter((h) => h.type === 'ORGANISATION')[0],
          }),
        )
      } else {
        // New post, fill user poster
        dispatch(
          setEditingPost({
            ...editingPost,
            poster: {
              type: 'USER',
              model: user,
            },
          }),
        )
      }
    }
  }, [open])

  // if (posters.length < 1) return

  return (
    <>
      <Button
        sx={{
          bgcolor: 'background.input',
        }}
        onClick={handleClick}
        disabled={posters.length <= 1}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
            border: '1px solid',
            borderColor: 'grey.dark',
            bgcolor: 'white',
          }}
          src={editingPost.poster.model.avatarUrls?.lg ?? ''}
        />
        <Box
          sx={{
            textAlign: 'left',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            ml: 1,
          }}
        >
          <Typography
            sx={{
              color: 'text.primary',
              fontWeight: 500,
            }}
            variant='body2'
          >
            {editingPost.poster.model.name}
          </Typography>
        </Box>

        {posters.length > 1 && <ArrowDropDown sx={{ ml: 0.5 }} />}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {posters.map((poster) => {
          return (
            <MenuItem
              key={poster.model.id}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
              }}
              onClick={() => {
                handleMenuClose()
                dispatch(
                  setEditingPost({
                    ...editingPost,
                    poster: poster,
                    // fromOrganisation:
                    //   host.type === 'ORGANISATION' ? host.model : null,
                    // fromGuest: host.type === 'GUEST' ? host.model : null,
                  }),
                )
              }}
            >
              <Box
                component='img'
                src={poster.model.avatarUrls?.lg ?? ''}
                sx={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '100px',
                }}
                alt='logo'
              />
              <Typography variant='body2'>{poster.model.name}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default PostPosterDropdown
