import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ClickableAvatar from 'shared-components/avatar/ClickableAvatar'
import apiClient from 'shared-components/utils/ApiClient'
import { getGuestName } from 'shared-components/utils/eventDisplay'
import BashButton, {
  BashButtonType,
} from 'shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  title: {
    textAlign: 'center',
    padding: theme.spacing(3, 2, 1, 2),
  },
  content: {
    padding: theme.spacing(0, 2),
    height: 250,
    overflowY: 'auto',
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  ticketItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'end',
  },
  errorText: {
    color: theme.palette.error.main,
    margin: theme.spacing(1, 0),
  },
}))

const CheckInDialog = ({ open, onClose, guest, setGuest }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState(false)
  const [loadingTicketId, setLoadingTicketId] = useState(null)
  const [error, setError] = useState(null)
  const [tickets, setTickets] = useState([])

  // Load tickets when dialog opens
  useEffect(() => {
    if (open && guest) {
      if (guest.tickets) {
        setTickets([...guest.tickets])
      }
    }
  }, [open, guest])

  const handleToggleTicket = async (ticket) => {
    setLoading(true)
    setLoadingTicketId(ticket.id)
    setError(null)

    try {
      const newStatus = ticket.status === 'USED' ? 'ACTIVE' : 'USED'
      const updatedTicket = await apiClient.tickets.patchTicket(
        ticket.id,
        newStatus,
      )

      // Update local state
      const newTickets = tickets.map((t) =>
        t.id === updatedTicket.id ? updatedTicket : t,
      )
      setTickets(newTickets)

      // Update guest object if needed
      if (setGuest && guest) {
        setGuest({
          ...guest,
          tickets: newTickets,
          activeTicketCount: newTickets.filter(
            (ticket) => ticket.status === 'ACTIVE',
          ).length,
        })
      }
    } catch (err) {
      console.error(err)
      setError(t('somethingWentWrong'))
    } finally {
      setLoading(false)
      setLoadingTicketId(null)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={loading ? null : onClose}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle className={classes.title}>
        <Typography variant='h6'>{t('manualCheckin')}</Typography>
        <div className={classes.nameContainer}>
          <ClickableAvatar
            userOrGuest={guest}
            size={24}
            disableClick
            className={classes.avatar}
          />
          <Typography variant='body2'>{getGuestName(guest)}</Typography>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        {error && (
          <Typography className={classes.errorText}>{error}</Typography>
        )}
        <List>
          {tickets.map((ticket) => (
            <ListItem
              key={ticket.id}
              className={classes.ticketItem}
              button
              onClick={() => !loading && handleToggleTicket(ticket)}
            >
              <ListItemText primary={`Ticket ${ticket.id}`} />
              <ListItemIcon sx={{ height: 40, width: 40 }}>
                {loadingTicketId === ticket.id ? (
                  <CircularProgress size={24} />
                ) : (
                  <Checkbox
                    checked={ticket.status === 'USED'}
                    color='primary'
                    disabled={loading}
                  />
                )}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <BashButton
          type={BashButtonType.CLEAR_PRIMARY}
          onClick={onClose}
          height={40}
        >
          {t('done')}
        </BashButton>
      </DialogActions>
    </Dialog>
  )
}

export default CheckInDialog
