import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '6px 8px',
    letterSpacing: '0.015rem',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    // textTransform: 'uppercase',
    fontFamily:
      '-apple-system, "BlinkMacSystemFont", "Roboto", "Arial", sans-serif',
    fontWeight: 400,
    borderRadius: 4,
    backgroundColor: theme.palette.secondary[400],
    color: theme.palette.secondary.main,
    // width: 'min-content',
    '& > svg': {
      width: 12,
      height: 12,
      marginRight: 6,
    },
  },
  bold: {
    fontWeight: 700,
  },
  clickAble: {
    cursor: 'pointer',
  },
  primary: {
    background: theme.palette.mode === 'dark' ? '#0B1B41' : '#F2F5FD',
    color: theme.palette.mode === 'dark' ? '#D3DEF8' : '#18398C',
  },
  secondary: {
    background: theme.palette.mode === 'dark' ? '#240448' : '#F7F1FE',
    color: theme.palette.mode === 'dark' ? '#E9D8FD' : '#240448',
  },
  tertiary: {
    background: theme.palette.mode === 'dark' ? '#5C1100' : '#FFF2F0',
    color: theme.palette.mode === 'dark' ? '#FFF2F0' : '#5C1100',
    border:
      '1px solid ' + (theme.palette.mode === 'dark' ? '#1F1F1F' : '#FAFAFA'),
  },
}))

const TextBadge = ({
  className,
  children,
  color = 'secondary',
  bold = true,
  ...props
}) => {
  const { classes, cx } = useStyles()

  return (
    <span
      className={cx(
        classes.root,
        bold && classes.bold,
        props.onClick && classes.clickAble,
        className,
        classes[color],
      )}
      {...props}
    >
      {children}
    </span>
  )
}

export default TextBadge
