import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { setPostToAdd, showConfetti } from 'actions/rsvp'
import { makeStyles } from 'tss-react/mui'
import { getCurrentEvent } from '../../selectors/event'
import { useRouter } from 'next/router'
import FullScreenSheet from '../modals/FullScreenSheet'
import { useTranslation } from 'react-i18next'
import WizardTextField from './WizardTextField'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { editEvent, fetchEvent } from 'actions/event'
import ApiClient from '../../shared-components/utils/ApiClient'
import AddRsvpMessageInput from 'components/event/posts/AddRsvpMessageInput'
import { useMultimediaLogic } from 'components/EventCreation/MultimediaLogic'

const useStyles = makeStyles()((theme) => ({
  donePaper: {
    minWidth: '400px',
    [theme.breakpoints.down(401)]: {
      minWidth: '100%',
    },
  },
  root: {
    width: '100%',
    // maxWidth: theme.breakpoints.values.container,
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '0px !important',
  },
  backdropRoot: {
    backgroundColor: theme.palette.background['backdrop'],
  },
  container: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // minHeight: 600
  },
  nextButton: {
    flexGrow: 1,
    height: 40,
  },
  buttonsContainer: {
    display: 'flex',
    paddingTop: theme.spacing(1.5),
  },
  nameFields: {
    marginBottom: theme.spacing(0.5),
  },
}))

const AddNameSheet = ({ open, onClose }) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const [name, setName] = useState('')
  const [postText, setPostText] = useState('')
  const [_postMedia, setPostMedia] = useState([])
  const postMedia = _postMedia.filter((m) => !m.removed)
  const [loading, setLoading] = useState(false)
  const event = useSelector(getCurrentEvent)
  const router = useRouter()
  let gcToUse = router.query.gc
  if (typeof window !== 'undefined' && !gcToUse) {
    gcToUse =
      window.localStorage.getItem(`gc_${event.id}`) ??
      window.localStorage.getItem(`gc_${event.code}`)
  }

  const onSubmitName = async () => {
    setLoading(true)
    await ApiClient.guest.finishProfile(event.myGuest?.id, { name }, gcToUse)
    await onPost()
    if (event.code) {
      await dispatch(fetchEvent(event.code, gcToUse))
    }
    setLoading(false)
    onClose()
    dispatch(showConfetti())
  }

  const { uploadMedia } = useMultimediaLogic({
    media: postMedia,
    setMedia: (newMedia) => {
      setPostMedia(newMedia)
    },
    dispatch: dispatch,
    event: event,
  })

  const onPost = async () => {
    if (postText === '' && postMedia.length === 0) {
      return
    }

    const body = {
      text: postText,
      parentEventId: event.id,
      reactToRsvp: true,
    }
    let res = null
    try {
      res = await ApiClient.event.createPost(body, gcToUse, true)
      if (res.id) {
        await uploadMedia(null, res.id)
        const sendRes = await ApiClient.event.sendPostNotif(res.id, gcToUse)
      }
    } catch (e) {
      console.error(e)
      return
    }

    dispatch(
      setPostToAdd({
        ...res,
        media: postMedia.map((m) => ({
          ...m,
          uploaded: true,
        })),
        content: {
          ...res.content,
          model: {
            ...res.content?.model,
            guest: {
              ...res.content?.model?.guest,
            },
          },
        },
      }),
    )

    dispatch(
      editEvent({
        ...event,
        myGuest: {
          ...event.myGuest,
          hasRsvpPosted: true,
        },
      }),
    )
  }

  return (
    <FullScreenSheet
      className={classes.root}
      classes={{ paper: cx(classes.root) }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.container}>
        <div className={classes.nameFields}>
          <WizardTextField
            title={t('fullName')}
            placeholder={t('fullNamePlaceholder')}
            value={name}
            full
            onChange={(e) => {
              setName(e.target.value)
            }}
            onSubmit={onSubmitName}
            className={classes.nameField}
            autoFocus={true}
          />
        </div>
        <AddRsvpMessageInput
          text={postText}
          setText={setPostText}
          media={postMedia}
          setMedia={setPostMedia}
        />
        <div className={classes.buttonsContainer}>
          <FlatButton
            onClick={onSubmitName}
            className={classes.nextButton}
            disabled={name.length < 2}
            loading={loading}
            color='secondary'
          >
            {t('accept')}
          </FlatButton>
        </div>
      </div>
    </FullScreenSheet>
  )
}

export default AddNameSheet
