import React, { MouseEventHandler } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  SxProps,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { BashButtonType, getButtonStyles } from './BashButton'
import AnimatedContent from 'components/AnimatedContent'

interface BashIconButtonProps {
  type: BashButtonType
  loading?: boolean
  enabled?: boolean
  onClick: MouseEventHandler | undefined
  children: any
  size?: 48 | 40 | 32
  sx?: SxProps<Theme>
  href?: string | undefined
  download?: string | undefined
}

const BashIconButton = ({
  type = BashButtonType.PRIMARY,
  loading = false,
  enabled = true,
  onClick = () => {},
  children,
  size = 40,
  sx = [],
  ...props
}: BashIconButtonProps) => {
  const theme = useTheme()

  const disabled = !enabled || loading

  const getType = () => {
    if (theme.extras?.name === 'custom') {
      // if (type === BashButtonType.GREY) return BashButtonType.PRIMARY_LIGHT
      if (type === BashButtonType.SECONDARY_LIGHT) return BashButtonType.PRIMARY
      if (type === BashButtonType.SECONDARY) return BashButtonType.PRIMARY
      return type
    } else {
      return type
    }
  }

  return (
    <IconButton
      onClick={onClick}
      {...props}
      sx={[
        (theme) => ({
          flex: 'none',
          width: size,
          height: size,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          minWidth: 0,
          minHeight: 0,
          p: 0,
          '&  svg': {
            width: 20,
            height: 20,
          },
        }),
        !disabled && getButtonStyles(getType()),
        disabled &&
          ((theme) => ({
            pointerEvents: 'none',
            background:
              (theme.extras?.name === 'custom'
                ? theme.palette.grey[400]
                : theme.palette.grey[400]) + ' !important',
            color: 'white',
            '&:hover': {
              background: theme.palette.grey[400],
            },
          })),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <AnimatedContent
          firstContent={children}
          secondContent={
            <CircularProgress
              size={20}
              sx={(theme) => ({
                color: 'inherit',
                margin: theme.spacing(0, 0.5),
              })}
            />
          }
          showSecondContent={loading}
        />
      </Box>
    </IconButton>
  )
}

export default BashIconButton
