import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Divider, Popover, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCurrentEvent,
  getIsEventsAdmin,
  getIsOrganiser,
  isCurrentEventPublic,
  isHostedByPage,
} from '../../selectors/event'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import ContentBlock from '../../shared-components/event/ContentBlock'
import { DateRangePicker } from 'react-date-range'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import apiClient from 'shared-components/utils/ApiClient'

import { addDays, endOfDay, isSameDay, startOfDay } from 'date-fns'
import { InfoOutlined } from '@mui/icons-material'

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfLast7Days: startOfDay(addDays(new Date(), -6)),
  startOfLast30Days: startOfDay(addDays(new Date(), -29)),
}

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  },
}

function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }))
}

const useStyles = makeStyles()((theme) => ({
  root: {
    // marginBottom: theme.spacing(2),
    // padding: theme.spacing(2),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    border: 'none !important',
    backgroundColor: 'inherit',
  },
  title: {
    fontWeight: 700,
  },
  dialogContent: {
    whiteSpace: 'pre-wrap',
    lineHeight: '24px',
    letterSpacing: '0.25px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  selectDateRange: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%',
    cursor: 'pointer',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  green: {
    color: theme.palette.success.main,
    marginTop: theme.spacing(1),
  },
  red: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  hidden: {
    opacity: 0,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  blocker: {
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    backgroundSize: 'cover',
    backgroundImage: 'url(/images/bashProBlocker.png)',
  },
  blur: {
    backdropFilter: 'blur(7px)',
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  infoIcon: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
  },
}))

const AnalyticsItem = ({ title, item, onInfo }) => {
  const { classes } = useStyles()
  return (
    <div className={classes.item}>
      <Typography variant='subtitle1'>
        {title}
        {onInfo && (
          <InfoOutlined onClick={onInfo} className={classes.infoIcon} />
        )}
      </Typography>
      <div style={{ textAlign: 'end' }}>
        <Typography variant='subtitle1'>{item.count}</Typography>
        {item.percentage && item.percentage > 0 && (
          <Typography className={classes.green} variant='caption'>
            +{Math.round(item.percentage)}%
          </Typography>
        )}
        {item.percentage && item.percentage < 0 && (
          <Typography className={classes.red} variant='caption'>
            {Math.round(item.percentage)}%
          </Typography>
        )}
        {(!item.percentage || item.percentage === 0) && (
          <Typography className={classes.hidden} variant='caption'>
            {Math.round(item.percentage)}
          </Typography>
        )}
      </div>
    </div>
  )
}

const AnalyticsCard = ({
  desktopMode,
  useOrganisation = false,
  isModal = false,
}) => {
  const { classes } = useStyles()
  const isPublicEvent = useSelector(isCurrentEventPublic)
  const page = useSelector((state) => state.organisation.organisation)
  const isOrganiser = useSelector(getIsOrganiser)
  const isEventsAdmin = useSelector(getIsEventsAdmin)
  const hostedByPage = useSelector(isHostedByPage)
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const [dateRange, setDateRange] = useState([
    {
      startDate: dayjs().subtract(7, 'days').toDate(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [state, setState] = React.useState({
    data: {},
  })

  const hasPro =
    (useOrganisation && page?.manager === true) ||
    (event.hosts && event.hosts[0]?.model?.plan === 'PRO') ||
    isEventsAdmin
  const { status, data } = useQuery({
    queryKey: ['analytics', dateRange, useOrganisation ? page.id : event],
    queryFn: () =>
      useOrganisation
        ? apiClient.organisation.getAnalytics(
            page.id,
            dateRange[0].startDate,
            dateRange[0].endDate,
          )
        : apiClient.event.getAnalytics(
            event.id,
            dateRange[0].startDate,
            dateRange[0].endDate,
          ),
    enabled: hasPro && ((useOrganisation && !!page.id) || !!event.id),
  })

  useEffect(() => {
    if (status === 'success') {
      setState({ data })
    }
  }, [status, data])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  if (
    (!(isPublicEvent || hostedByPage) || !isOrganiser) &&
    !hasPro &&
    !isEventsAdmin
  )
    return null

  const defaultStaticRanges = createStaticRanges(
    [
      {
        label: 'Today',
        range: () => ({
          startDate: defineds.startOfToday,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: 'Last 7 days',
        range: () => ({
          startDate: defineds.startOfLast7Days,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: 'Last 30 days',
        range: () => ({
          startDate: defineds.startOfLast30Days,
          endDate: defineds.endOfToday,
        }),
      },
      !useOrganisation
        ? {
            label: 'Since start',
            range: () => ({
              startDate: dayjs(event.createdAt).toDate(),
              endDate: defineds.endOfToday,
            }),
          }
        : undefined,
    ].filter((s) => !!s),
  )

  return (
    <ContentBlock className={classes.root}>
      {!isModal && (
        <div className={classes.header}>
          <Typography variant='h6' className={classes.title}>
            {t('insights')}
          </Typography>
        </div>
      )}
      <div
        className={classes.selectDateRange}
        aria-describedby={id}
        onClick={handleClick}
      >
        <Typography variant='button'>{t('selectDateRange')}</Typography>
        <Typography variant='body2'>
          {dayjs(dateRange[0].startDate).format('MMM D')} -{' '}
          {dayjs(dateRange[0].endDate).format('MMM D')}
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <>
        {state.data.impressions && (
          <>
            <AnalyticsItem
              title={t('impressions')}
              item={state.data.impressions}
            />
          </>
        )}
        {hasPro && state.data.ticketLinkClicks && (
          <>
            <Divider />
            <AnalyticsItem
              title={t('ticketLinkClicks')}
              item={state.data.ticketLinkClicks}
            />
          </>
        )}

        {state.data.eventOpens && (
          <>
            <Divider />
            <AnalyticsItem
              title={t('eventOpens')}
              item={state.data.eventOpens}
            />
          </>
        )}
        {state.data.engagement && (
          <>
            <Divider />
            <AnalyticsItem
              title={t('engagement')}
              item={state.data.engagement}
            />
          </>
        )}
      </>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          onChange={(item) => {
            item.selection.endDate.setHours(
              item.selection.endDate.getHours() + 24,
            )
            item.selection.endDate.setMinutes(
              item.selection.endDate.getMinutes() - 1,
            )
            setDateRange([item.selection])
          }}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={dateRange}
          minDate={
            useOrganisation ? undefined : dayjs(event.createdAt).toDate()
          }
          maxDate={new Date()}
          direction='horizontal'
          staticRanges={defaultStaticRanges}
          inputRanges={[]}
        />
      </Popover>
    </ContentBlock>
  )
}

export default AnalyticsCard
