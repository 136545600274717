import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Checkbox, Typography } from '@mui/material'
import QuestionContainer from './QuestionContainer'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next'
import { fontFamily, gintoHeaderFontFamily } from 'shared-components/typography'
import { PhoneQuestionProps } from './types'

const useStyles = makeStyles()((theme) => ({
  phoneInputContainer: {
    '& .country-name': {
      fontFamily: fontFamily + ' !important',
      marginRight: '8px !important',
      marginLeft: '2px !important',
    },
    '& .country-list': {
      maxHeight: 120,
      width: '100%',
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
      borderRadius: '6px',
      backgroundColor: theme.palette.background.paper + ' !important',
      '.country.highlight': {
        backgroundColor: theme.palette.background.default + ' !important',
      },
      '.country:hover': {
        backgroundColor: theme.palette.background.default + ' !important',
      },
    },
    '& .form-control': {
      background: theme.palette.background.input + ' !important',
      borderRadius: 6,
      fontSize: '16px !important',
      height: '40px',
      width: '100% !important',
      fontFamily: fontFamily + ' !important',
      border: 'none !important',
    },
    '& .selected-flag': {
      borderRadius: '6px 0 0 6px !important',
      border: 'none !important',
      backgroundColor: theme.palette.background.input + ' !important',
      '&:hover': {
        backgroundColor: theme.palette.background.input + ' !important',
      },
    },
    '& .flag-dropdown': {
      borderRadius: '6px 0 0 6px !important',
      backgroundColor: theme.palette.background.input + ' !important',
      border: 'none !important',
    },
    font: gintoHeaderFontFamily,
  },
  emailToggleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  emailToggle: {
    // marginLeft: theme.spacing(-1),
  },
  optionText: {
    marginTop: theme.spacing(1),
    lineHeight: '21px',
  },
}))

const PhoneQuestion: React.FC<PhoneQuestionProps> = ({
  title,
  required,
  isError,
  value,
  onChange,
  id,
}) => {
  const { classes } = useStyles()

  // Add keyboard navigation handler
  const handleKeyPress = (event: KeyboardEvent) => {
    const key = event.key.toLowerCase()
    const countryList = document.querySelector('.country-list')

    if (countryList) {
      const countries = countryList.querySelectorAll('.country')
      for (let i = 0; i < countries.length; i++) {
        const country = countries[i] as HTMLElement
        const countryName =
          country.querySelector('.country-name')?.textContent?.toLowerCase() ||
          ''

        if (countryName.startsWith(key)) {
          country.scrollIntoView({ block: 'nearest' })
          break
        }
      }
    }
  }

  // Add and remove event listener
  React.useEffect(() => {
    document.addEventListener('keypress', handleKeyPress)
    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [])

  return (
    <QuestionContainer title={title} required={required} isError={isError}>
      <PhoneInput
        country={'nl'} // Default country, change as needed
        value={value}
        onChange={(value, country, e, formattedValue) =>
          onChange(formattedValue)
        }
        inputProps={{
          name: id?.toString(),
          required: true,
        }}
        containerClass={classes.phoneInputContainer}
      />
    </QuestionContainer>
  )
}

export default PhoneQuestion
