import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Button, Tooltip, Typography, useTheme } from '@mui/material'
import { formatShortNumber } from '../../utils/eventFunctions'
import { naturalLanguageJoin } from '../../utils/naturalLanguageJoin'
import { useTranslation } from 'react-i18next'
import { EmojiResponse } from './EmojiResponses'
import { useLongPress } from '../../shared-components/utils/hooks'
import { getCurrentEvent } from 'selectors/event'
import { useSelector } from 'react-redux'

const useStyles = makeStyles()((theme) => ({
  response: {
    minWidth: 0,
    padding: '6px 8px',
    border: '1px solid ' + theme.palette.grey[300],
    borderRadius: 100,
    color: theme.palette.grey[800],
    background: theme.palette.background.paper,
    height: 28,
    flexShrink: 0,
  },
  responseLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  responseActive: {
    background: theme.palette.primary[100],
    border: `1px solid ${theme.palette.primary.main}`,
  },
  responseEmoji: {},
  responseTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    cursor: 'pointer',
  },
  tooltipEmoji: {
    padding: theme.spacing(0.5),
    borderRadius: 3,
    background: '#fff',
  },
  tooltipLabel: {
    background: theme.palette.grey[900],
    padding: theme.spacing(1.5),
  },
  tooltipArrow: {
    color: theme.palette.grey[900],
  },
}))

interface EmojiResponseItemProps {
  response: EmojiResponse
  onTooltipClicked: () => void
  onEmojiClicked: (event) => void
}

const EmojiResponseItem = ({
  response,
  onTooltipClicked,
  onEmojiClicked,
}: EmojiResponseItemProps) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const event = useSelector(getCurrentEvent)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const longPressEvent = useLongPress(onTooltipClicked, onEmojiClicked)

  const getHoverText = (): string => {
    const names = response.users.slice(0, 5).map((user) => user.name)
    return naturalLanguageJoin(t, names, response.users.length - names.length)
  }

  return (
    <>
      <Tooltip
        enterDelay={300}
        enterNextDelay={100}
        open={tooltipOpen}
        onClose={() => {
          setTooltipOpen(false)
        }}
        onOpen={() => setTooltipOpen(true)}
        classes={{
          tooltip: classes.tooltipLabel,
          arrow: classes.tooltipArrow,
        }}
        // leaveDelay={300}
        arrow
        placement='top'
        title={
          <div
            onClick={() => {
              setTooltipOpen(false)
              onTooltipClicked()
            }}
            className={classes.responseTooltip}
          >
            <Typography variant='h6' className={classes.tooltipEmoji}>
              {response.emoji}
            </Typography>
            <Typography variant='body2'>{getHoverText()}</Typography>
          </div>
        }
      >
        <Button
          {...longPressEvent}
          className={cx(
            classes.response,
            response.reactedByYou && classes.responseActive,
            classes.responseLabel,
          )}
          style={{
            background:
              response.reactedByYou && event.theme.colourScheme
                ? event.theme.darkMode
                  ? `${event.theme.colourScheme?.colorButtonPrimary}40`
                  : `${event.theme.colourScheme?.colorButtonSecondary}60`
                : undefined,
            color:
              event.theme.colourScheme || event.theme.darkMode
                ? theme.palette.text.primary
                : null,
          }}
        >
          <Typography variant='body1' style={{ color: 'black' }}>
            {response.emoji}
          </Typography>
          <Typography variant='body2' style={{ fontWeight: 500 }}>
            {formatShortNumber(response.count)}
          </Typography>
        </Button>
      </Tooltip>
    </>
  )
}

export default EmojiResponseItem
