import React, { useRef, useState } from 'react'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEventLink } from '../../selectors/event'
import { useTranslation } from 'react-i18next'
import useMixpanelTrackEvent from '../../utils/useMixpanelTrackEvent'
import { setSnackbar } from '../../shared-components/redux/notifications/actions'
import { Check, ChevronRight, ContentCopy } from '@mui/icons-material'
import Row from '../Row'
import { fontFamily } from '../../shared-components/typography'
import Column from '../Column'
import { Menu, MenuItem, Typography } from '@mui/material'
import { useCurrentEvent } from '../../utils/hooks'

const EmbedEventButton = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const event = useCurrentEvent()
  const trackEvent = useMixpanelTrackEvent()
  const eventUrl = useSelector(getCurrentEventLink)

  const [theme, setTheme] = useState<'light' | 'dark' | 'custom'>(
    event.theme.colourScheme != null
      ? 'custom'
      : event.theme.darkMode
        ? 'dark'
        : 'light',
  )

  const [themeAnchor, setThemeAnchor] = useState<HTMLElement | null>(null)
  const themeRef = useRef<HTMLDivElement | null>(null)

  const onTrack = () => trackEvent('Copy Embed Html Event')

  const onCopy = (e) => {
    // e.target.select()
    if (navigator.clipboard) {
      navigator.clipboard.writeText(buttonHtml)
      dispatch(setSnackbar('info', t('copiedHtmlToClipboard')))
    }
    onTrack()
  }

  const themeClicked = (newTheme) => {
    setTheme(newTheme)
    setThemeAnchor(null)
  }

  const cta =
    (event.joinOptions?.some((j) => j.price != null && j.price > 0) ?? false)
      ? 'GET TICKET'
      : 'RSVP'

  const themeBackgroundColor = event.theme.darkMode
    ? event.theme.colourScheme?.colorDarkButtonPrimary
    : event.theme.colourScheme?.colorButtonPrimary
  const themeForegroundColorColor = event.theme.darkMode
    ? event.theme.colourScheme?.colorDarkTextPrimary
    : event.theme.colourScheme?.colorTextPrimary
  const backgroundColor =
    theme === 'light'
      ? '#fff'
      : theme === 'dark'
        ? '#240448'
        : themeBackgroundColor
  const foregroundColor =
    theme === 'light'
      ? '#240448'
      : theme === 'dark'
        ? '#F7F1FE'
        : themeForegroundColorColor

  const buttonHtml = `
  <a href="${eventUrl}" style="background: ${backgroundColor}; border-radius: 100px; display: flex; align-items: center; height: 40px; padding: 0px 42px;" target="_blank">
      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.91536 0.5H1.78158C0.854271 0.5 0.102539 1.2409 0.102539 2.15484V11.8425C0.102539 12.7565 0.854271 13.4974 1.78158 13.4974H3.91536C4.84267 13.4974 5.5944 12.7565 5.5944 11.8425V2.15484C5.5944 1.2409 4.84267 0.5 3.91536 0.5Z" fill="${foregroundColor}"/>
    <path d="M8.48745 13.4986C8.36747 13.4986 8.30766 13.4986 8.25694 13.4955C7.84759 13.471 7.46151 13.2997 7.17152 13.0139C6.88154 12.7281 6.70771 12.3475 6.68284 11.9441C6.67969 11.8941 6.67969 11.8352 6.67969 11.7169V9.48874C6.67969 9.37049 6.67969 9.31153 6.68284 9.26154C6.70771 8.8581 6.88154 8.47758 7.17152 8.19178C7.46151 7.90597 7.84759 7.73465 8.25694 7.71013C8.30766 7.70703 8.36747 7.70703 8.48745 7.70703H9.61766C10.397 7.70703 11.1443 8.01214 11.6954 8.55524C12.2464 9.09834 12.556 9.83494 12.556 10.603C12.556 11.3711 12.2464 12.1077 11.6954 12.6508C11.1443 13.1939 10.397 13.499 9.61766 13.499L8.48745 13.4986Z" fill="${foregroundColor}"/>
    <path d="M9.61703 6.29192C11.2398 6.29192 12.5554 4.99536 12.5554 3.39596C12.5554 1.79657 11.2398 0.5 9.61703 0.5C7.99424 0.5 6.67871 1.79657 6.67871 3.39596C6.67871 4.99536 7.99424 6.29192 9.61703 6.29192Z" fill="${foregroundColor}"/>
    </svg>
    <span style="color: ${foregroundColor}; font-weight: 700; font-family: ${fontFamily};font-size: 12px;line-height: 14.32px;letter-spacing: 1.5px;">
  ${cta}
  </span>
  </a>
  `

  return (
    <Column
      sx={{
        gap: 3,
      }}
    >
      <Column
        sx={{
          gap: 1,
          flexGrow: 1,
          flexBasis: '50%',
        }}
      >
        <Typography variant='body2Medium'>{t('theme')}</Typography>

        <div ref={themeRef}>
          <Row
            sx={{
              height: 40,
              px: 1,
              borderRadius: 1,
              bgcolor: 'grey.100',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'space-between',
            }}
            onClick={(e) => setThemeAnchor(e.currentTarget)}
          >
            <Typography variant='body1'>
              {theme === 'light'
                ? t('themeLight')
                : theme === 'custom'
                  ? t('themeCustom')
                  : t('themeDark')}
            </Typography>

            <ChevronRight
              style={{
                transform: 'rotate(90deg)',
              }}
            />
          </Row>
        </div>
      </Column>

      <Menu
        id='theme-menu'
        anchorEl={themeAnchor}
        keepMounted
        slotProps={{
          paper: {
            style: {
              width: themeRef?.current?.clientWidth,
            },
          },
        }}
        open={!!themeAnchor}
        onClose={() => setThemeAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => themeClicked('light')}
          sx={{
            justifyContent: 'space-between',
          }}
        >
          {t('themeLight')}
          {theme === 'light' && <Check fontSize='small' />}
        </MenuItem>

        <MenuItem
          sx={{
            justifyContent: 'space-between',
          }}
          onClick={() => themeClicked('dark')}
        >
          {t('themeDark')}
          {theme === 'dark' && <Check fontSize='small' />}
        </MenuItem>

        <MenuItem
          sx={{
            justifyContent: 'space-between',
          }}
          onClick={() => themeClicked('custom')}
          disabled={event.theme?.colourScheme == null}
        >
          {t('themeCustom')}
          {theme === 'custom' && <Check fontSize='small' />}
        </MenuItem>
      </Menu>

      <Column
        sx={{
          py: 2.5,
          alignItems: 'center',
          bgcolor: 'grey.150',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
          dangerouslySetInnerHTML={{ __html: buttonHtml }}
        />
      </Column>

      <Row
        sx={{
          gap: 1,
        }}
        onClick={onCopy}
      >
        <Typography
          variant='body2'
          sx={(theme) => ({
            px: 1,
            py: 1.5,
            bgcolor: 'grey.100',
            border: `1px solid ${theme.palette.divider}`,
            color: 'grey.800',
            borderRadius: 1,
            flexGrow: 1,
          })}
          noWrap
        >
          {buttonHtml}
        </Typography>
        <BashButton
          onClick={onCopy}
          extraView={<ContentCopy />}
          viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          type={BashButtonType.PRIMARY}
          height={40}
        >
          {t('copy')}
        </BashButton>
      </Row>
    </Column>
  )
}

export default EmbedEventButton
