import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React from 'react'
import GuestManagementScroller from './GuestManagementScroller'

const useStyles = makeStyles()((theme) => ({
  root: {
    // height: '100%'
  },
  tab: {
    minWidth: 120,
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.grey[200],
  },
}))

const GuestManagementInvited = ({ sortType }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)

  return (
    <div className={classes.root}>
      <GuestManagementScroller
        sortType={sortType}
        event={event}
        status={'NONE'}
        tab={'NONE'}
      />
    </div>
  )
}

export default GuestManagementInvited
