import { SxProps, Typography } from '@mui/material'
import { Check, Close } from '@mui/icons-material'
import { setLocBeforeEdit } from 'actions/event'
import { setCurrentScrapeBotId } from 'actions/scraper'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useMixpanelTrackEvent from 'utils/useMixpanelTrackEvent'
import Column from '../../components/Column'
import Row from '../../components/Row'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../buttons/BashButton'
import { Theme } from '@mui/material/styles'
import { useUser } from '../../utils/userFunctions'
import apiClient from '../utils/ApiClient'

const PendingCohostHeader = ({ event }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const { user } = useUser()

  const pendingHost = event.hosts?.find(
    (h) =>
      h.role === 'CO_HOST' &&
      h.state === 'PENDING' &&
      ((h.type === 'ORGANISATION' && h.model.manager) ||
        (h.type === 'USER' && h.model.id === user.id)),
  )

  const trackEvent = useMixpanelTrackEvent(event)
  const router = useRouter()

  const [accepting, setAccepting] = useState(false)
  const [denying, setDenying] = useState(false)

  const onDenyClicked = async () => {
    if (accepting || denying) return
    setDenying(true)
    const result = await apiClient.event.denyCoHost(pendingHost.id)
    dispatch({
      type: 'FETCH_EVENT_FULFILLED',
      payload: result,
    })
    setDenying(false)
  }

  const onEditClicked = () => {
    trackEvent('Edit Bash')
    dispatch(setLocBeforeEdit(router.asPath))
    dispatch(setCurrentScrapeBotId(null))
    router.push(`/edit/${event.code}`)
  }

  const onAcceptClicked = async () => {
    if (accepting || denying) return
    setAccepting(true)
    const result = await apiClient.event.acceptCoHost(pendingHost.id)
    dispatch({
      type: 'FETCH_EVENT_FULFILLED',
      payload: result,
    })
    setAccepting(false)
  }

  const buttonSx: SxProps<Theme> = (theme) => ({
    // px: '8px !important',
    color:
      (theme.extras.name === 'custom'
        ? theme.extras.darkMode
          ? 'white'
          : theme.palette.grey[100]
        : theme.palette.primary.main) + ' !important',
    background:
      (theme.extras.name === 'custom'
        ? theme.extras.darkMode
          ? theme.palette.primary.dark
          : theme.palette.grey[800]
        : theme.palette.primary[100]) + ' !important',
    '&:hover': {
      background:
        (theme.extras.name === 'custom'
          ? theme.extras.darkMode
            ? theme.palette.primary.main
            : theme.palette.grey[600]
          : theme.palette.primary[200]) + ' !important',
    },
  })

  return (
    <Column
      sx={(theme) => ({
        gap: 1.25,
        width: '100%',
        alignItems: 'center',
        [theme.breakpoints.down(870)]: {
          px: 2,
          gap: 2,
          mt: 1.5,
        },
      })}
    >
      <Typography
        sx={{
          color: 'primary.main',
          lineHeight: '1.3rem',
        }}
        variant='overline'
      >
        <Trans
          t={t}
          i18nKey={'nameWantsToCohost'}
          components={{
            b: <b />,
          }}
          values={{
            ownerName: event.hosts?.find((h) => h.role === 'OWNER')?.model
              ?.name,
            cohostName: pendingHost?.model?.name,
          }}
        />
      </Typography>

      <Row
        sx={(theme) => ({
          px: 1,
          py: 0.75,
          borderRadius: 1,
          width: '100%',
          gap: 2,
          background:
            theme.extras.name === 'custom'
              ? theme.extras.darkMode
                ? theme.palette.primary.dark
                : theme.palette.grey[800]
              : theme.palette.primary[100],
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        })}
      >
        <BashButton
          type={BashButtonType.PRIMARY_LIGHT}
          onClick={onDenyClicked}
          extraView={<Close />}
          loading={denying}
          viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          // sx={{
          //   ...buttonSx,
          //   color: 'grey.800',
          // }}
          sx={[
            buttonSx,
            (theme) => ({
              color:
                (theme.extras.name === 'custom'
                  ? theme.extras.darkMode
                    ? 'white'
                    : theme.palette.grey[100]
                  : theme.extras.darkMode
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary) + ' !important',
            }),
          ]}
        >
          {t('deny')}
        </BashButton>

        <BashButton
          type={BashButtonType.PRIMARY_LIGHT}
          onClick={onEditClicked}
          sx={buttonSx}
        >
          {t('editAndAccept')}
        </BashButton>

        <BashButton
          loading={accepting}
          type={BashButtonType.PRIMARY_LIGHT}
          onClick={onAcceptClicked}
          extraView={<Check />}
          viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          sx={buttonSx}
        >
          {t('accept')}
        </BashButton>
      </Row>
    </Column>
  )
}

export default PendingCohostHeader
