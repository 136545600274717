import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: theme.breakpoints.values.visual,
    flexDirection: 'column',
    transition: `max-width 0.3s ${theme.transitions.easing.easeInOut}`,
    paddingBottom: theme.spacing(12),
    minHeight: 'calc(100vh - 48px)',
  },
  small: {
    maxWidth: theme.breakpoints.values.container,
  },
  fullHeight: {
    height: '100vh',
  },
  fullHeightWithHeader: {
    height: 'calc(100vh - 56px)',
  },
  padding: {
    padding: theme.spacing(2),
  },
  large: {
    maxWidth: theme.breakpoints.values.containerMaxWidth,
  },
  fullWidth: {
    maxWidth: '100vw',
  },
  blurred: {
    minHeight: '100vh',
    filter: 'opacity(0.5)',
  },
}))

const PageContentWrapper = ({
  className,
  children,
  small,
  fullHeight,
  fullHeightWithHeader,
  padding,
  large,
  fullWidth,
  blurred,
  ...other
}) => {
  const { classes, cx } = useStyles()
  return (
    <div
      className={cx(
        classes.root,
        {
          [classes.small]: small,
          [classes.fullHeight]: fullHeight,
          [classes.fullHeightWithHeader]: fullHeightWithHeader,
          [classes.padding]: padding,
          [classes.large]: large,
          [classes.fullWidth]: fullWidth,
          [classes.blurred]: blurred,
        },
        className,
      )}
      id='page-content-wrapper'
      is-small={small && 'true'}
      {...other}
    >
      {children}
    </div>
  )
}

export default PageContentWrapper
