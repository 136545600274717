import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Menu, MenuItem, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getCurrentEvent, getCurrentUser } from '../../../selectors/event'
import apiClient from '../../../shared-components/utils/ApiClient'
import relativeTime from 'dayjs/plugin/relativeTime'
import MixpanelLinkify from '../../../shared-components/common/MixpanelLinkify'
import { MoreHoriz } from '@mui/icons-material'
import YesNoDialog from 'components/dialog/YesNoDialog'
import EmojiResponses from '../../common/EmojiResponses'
import { useRouter } from 'next/router'

dayjs.extend(relativeTime)

export const remirrorStyles = (theme) => ({
  ['& :first-child']: {
    paddingTop: 0,
    marginTop: 0,
  },
  ['& :last-child']: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  ['& h1']: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  ['& h3']: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 700,
  },
  ['& p']: {
    fontSize: '14px',
    lineHeight: '21px',
  },
  ['& a']: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  ['& a:active']: {
    pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
  },
})

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  rootTiny: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  content: {
    gap: theme.spacing(1),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  largeContent: {
    paddingLeft: 0,
    marginTop: theme.spacing(2),
  },
  rootAvatarTop: {
    alignItems: 'flex-start',
  },
  userAvatarTop: {},
  userAvatar: {
    width: 32,
    height: 32,
    borderRadius: '16px',
    border: '1px solid ' + theme.palette.divider,
    alignSelf: 'start',
  },
  smallAvatar: {
    width: 24,
    height: 24,
    borderRadius: '12px',
  },
  largeAvatar: {
    width: 40,
    height: 40,
    borderRadius: '20px',
  },
  name: {
    // marginLeft: theme.spacing(0.5),
    fontWeight: 500,
    fontSize: '14px',
    cursor: 'pointer',
    lineHeight: '1.3125rem',
  },
  date: {
    textAlign: 'left',
    marginTop: theme.spacing(0),
    // marginLeft: theme.spacing(0.5),
    fontWeight: 400,
    fontSize: '12px',
    flexShrink: 0,
  },
  simpleText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '1.3125rem',
  },
  tinyText: {
    lineClamp: 2,
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
    ['&:hover svg']: {
      display: 'block',
    },
  },
  innerNameContainer: {
    marginLeft: theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  raspEmoji: {
    marginTop: theme.spacing(1),
  },
  remirror: {
    ...remirrorStyles(theme),
  },
  goingIcon: {
    flexShrink: 0,
    width: 12,
    height: 12,
    color: theme.palette.secondary.main,
    marginLeft: 6,
  },
  maybeIcon: {
    flexShrink: 0,
    width: 12,
    height: 12,
    color: theme.palette.secondary.main,
    marginLeft: 6,
  },
  cantIcon: {
    flexShrink: 0,
    width: 12,
    height: 12,
    color: theme.palette.red.main,
    marginLeft: 6,
  },
  moreButton: {
    marginLeft: 'auto',
  },
  rsvpText: {
    textWrap: 'nowrap',
  },
  clickable: {
    cursor: 'pointer',
  },
  alignEnd: {
    marginLeft: 'auto',
    maxWidth: 120,
    flexShrink: 0,
    ['& > *']: {
      justifyContent: 'flex-end',
    },
  },
  reply: {
    fontWeight: 500,
    color: theme.palette.text.tertiary,
    cursor: 'pointer',
  },
  replyIcon: {
    color: theme.palette.text.tertiary,
    display: 'none',
    width: 14,
    height: 14,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  replyContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  replies: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.text.tertiary,
  },
  dividerLine: {
    width: 32,
    height: 1,
    backgroundcolor: theme.palette.text.tertiary,
  },
  repliesContainer: {
    marginLeft: 36,
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
  },
  noPaddingBottom: {
    marginBottom: 0,
  },
  tinyContent: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
}))

const Comment = ({
  comment,
  onDelete,
  onReply,
  setComment,
  emojiLimit = 13,
  small = false,
  tiny = false,
}) => {
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const sender = comment.poster.model.name
  const senderAvatarUrl = comment.poster.model.avatarUrls.lg
  const [loadingThreads, setLoadingThreads] = useState(false)
  const ownUser = useSelector(getCurrentUser)
  const router = useRouter()
  const time = dayjs(comment.createdAt).fromNow(true)

  const [moreAnchor, setMoreAnchor] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deletingState, setDeletingState] = useState('NONE')

  const deletePost = async () => {
    setDeletingState('LOADING')
    try {
      await apiClient.event.deleteComment(comment.id)
      onDelete(comment)
      setDeletingState('SUCCESS')
    } catch (e) {
      setDeletingState('FAILED')
      console.error(e)
    }
    setDeleteDialogOpen(false)
  }

  const loadThreads = async () => {
    setLoadingThreads(true)
    const threads = await apiClient.event.getCommentThreads(comment.id, 0, 1000)
    setComment({
      ...comment,
      threads: threads,
    })
    setLoadingThreads(false)
  }

  const moreClicked = (e) => {
    e.stopPropagation()
    setMoreAnchor(e.currentTarget)
  }

  const onDeletePostClicked = () => {
    setMoreAnchor(null)
    setDeleteDialogOpen(true)
  }

  const onReact = (emoji) => {
    apiClient.event.reactComment(comment.id, emoji)
    const index = comment.emojiResponses.findIndex(
      (response) => response.emoji === emoji,
    )
    if (index !== -1) {
      comment.emojiResponses[index].reactedByYou = true
      comment.emojiResponses[index].count += 1
      comment.emojiResponses[index].users.push(ownUser)
    } else {
      comment.emojiResponses.push({
        emoji: emoji,
        users: [ownUser],
        reactedByYou: true,
        count: 1,
      })
    }
    setComment({ ...comment })
  }
  const onRemoveReaction = (emoji) => {
    apiClient.event.removeReactionComment(comment.id, emoji)
    const index = comment.emojiResponses.findIndex(
      (response) => response.emoji === emoji,
    )
    if (index !== -1) {
      if (comment.emojiResponses[index].count === 1) {
        comment.emojiResponses.splice(index, 1)
      } else {
        comment.emojiResponses[index].reactedByYou = false
        comment.emojiResponses[index].count -= 1
        comment.emojiResponses[index].users = comment.emojiResponses[
          index
        ].users.filter((user) => user.id !== ownUser?.id)
      }
      setComment({ ...comment })
    }
  }

  const userId = comment.poster.type === 'USER' ? comment.poster.model.id : null

  const onUserClicked = (e) => {
    e.stopPropagation()
    if (userId) {
      router.push(`/users/${userId}`)
    }
  }

  const showEditButton =
    (comment.poster.type === 'GUEST' &&
      comment.poster.model.id === event.myGuest?.id) ||
    (comment.poster.type === 'USER' &&
      comment.poster.model.id === ownUser.id) ||
    (comment.poster.type === 'ORGANISATION' && event.host === true)

  if (tiny) {
    return (
      <div className={classes.rootTiny}>
        <div className={classes.tinyContent}>
          <Typography variant='body2' className={classes.tinyText}>
            <b style={{ fontWeight: 500 }}>{sender}</b>
            {' ' + comment.text}
          </Typography>

          {/*<Typography*/}
          {/*  className={classes.name}*/}
          {/*  noWrap*/}
          {/*  variant='body2'*/}
          {/*  onClick={onUserClicked}*/}
          {/*>*/}
          {/*  {sender}*/}
          {/*</Typography>*/}
          {/*<Typography className={classes.simpleText} noWrap variant='body2'>*/}
          {/*  {comment.text}*/}
          {/*</Typography>*/}
        </div>
        <EmojiResponses
          responses={comment.emojiResponses}
          onReact={onReact}
          onRemoveReaction={onRemoveReaction}
          limit={2}
          singleLine={true}
        />
      </div>
    )
  }

  return (
    <div
      className={cx(
        classes.root,
        (comment.threads ?? []).length > 0 && classes.noPaddingBottom,
      )}
      id={comment.id}
      key={comment.id}
    >
      <div className={cx(classes.topRow)}>
        <img
          alt='user avatar'
          src={senderAvatarUrl}
          className={cx(
            classes.userAvatar,
            small && classes.smallAvatar,
            userId && classes.clickable,
          )}
          onClick={onUserClicked}
        />

        <div className={classes.content}>
          <div className={classes.innerNameContainer}>
            <Typography
              className={cx(classes.name, userId && classes.clickable)}
              noWrap
              variant='body2'
              onClick={onUserClicked}
            >
              {sender}
            </Typography>
            <Typography className={classes.date} variant='caption'>
              {' · '}
              {time}
            </Typography>
            {showEditButton && (
              <MoreHoriz className={classes.replyIcon} onClick={moreClicked} />
            )}
          </div>
          <MixpanelLinkify>
            <Typography variant='body2' className={classes.simpleText}>
              {comment.text}
            </Typography>
          </MixpanelLinkify>
          <div className={classes.replyContainer}>
            {onReply && (
              <Typography
                variant='caption'
                className={classes.reply}
                onClick={onReply}
              >
                {t('reply')}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.alignEnd}>
          <EmojiResponses
            responses={comment.emojiResponses}
            onReact={onReact}
            onRemoveReaction={onRemoveReaction}
            limit={emojiLimit}
          />
        </div>
        <Menu
          id='more-menu'
          anchorEl={moreAnchor}
          open={!!moreAnchor}
          onClose={() => setMoreAnchor(null)}
          disableAutoFocusItem
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem onClick={onDeletePostClicked}>
            <Typography variant='body2' color='error'>
              {t('removeComment')}
            </Typography>
          </MenuItem>
        </Menu>
        <YesNoDialog
          title={t('removeComment')}
          subtitle={t('removeCommentSub')}
          confirmText={t('delete')}
          cancelText={t('cancel')}
          destructive
          open={deleteDialogOpen}
          loading={deletingState === 'LOADING'}
          onConfirm={deletePost}
          onClose={() => setDeleteDialogOpen(false)}
        />
      </div>
      {comment.threadCount > 0 && (
        <div className={classes.repliesContainer}>
          {comment.threadCount > 0 &&
            (comment.threads ?? []).length === 0 &&
            !loadingThreads && (
              <div onClick={() => loadThreads()} className={classes.replies}>
                <div className={classes.dividerLine} />
                <Typography variant='caption' className={classes.viewReplies}>
                  {comment.threadCount === 1
                    ? t('view1Reply', { count: comment.threadCount })
                    : t('viewXReplies', { count: comment.threadCount })}
                </Typography>
              </div>
            )}
          {loadingThreads && (
            <CircularProgress style={{ width: 14, height: 14 }} />
          )}
          {(comment.threads ?? []).map((thread) => (
            <Comment
              comment={thread}
              onDelete={onDelete}
              onReply={null}
              // onReply={onReply}
              setComment={setComment}
              small
              emojiLimit={2}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Comment
