import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useState } from 'react'
import { useRouter } from 'next/router'
import apiClient from '../../../shared-components/utils/ApiClient'
import { Check, Close } from '@mui/icons-material'
import GuestInfoSheet from '../GuestInfoSheet'
import dayjs from 'dayjs'
import { getGuestName } from '../../../shared-components/utils/eventDisplay'
import { updateStatusCounts } from './GuestManagementChangeStatus'
import BashButton, {
  BashButtonType,
} from '../../../shared-components/buttons/BashButton'
import Row from '../../../components/Row'
import { GuestDto } from '../../../types/guest'
import { RsvpStatus } from '../../../types/types'

// Define TypeScript interfaces for the component props
interface GuestManagementItemProps {
  guest: GuestDto // Replace with proper type when available
  showRightDetail?: boolean
  listStatus?: string
  refresh: () => void
}

const GuestManagementItem: React.FC<GuestManagementItemProps> = ({
  guest,
  showRightDetail = false,
  listStatus,
  refresh,
}) => {
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const router = useRouter()
  const { t } = useTranslation('common')
  const [localGuest, setLocalGuest] = useState(guest)
  const guestToUse = localGuest
  const setGuestToUse = setLocalGuest
  const [approving, setApproving] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [detailOpen, setDetailOpen] = useState(false)

  const showChecked =
    guestToUse.status === 'JOINED' &&
    guestToUse.activeTicketCount === 0 &&
    (guestToUse.totalTicketCount ?? 0) > 0

  const secondaryText = () => {
    const timestamp = dayjs(getTimestamp()).fromNow(false)
    const ticketName =
      guestToUse.joinOption?.idInfo?.source === 'EVENTIX'
        ? 'Weeztix ticket'
        : guestToUse.tickets
          ? (guestToUse.tickets?.[0]?.name ?? t('addedByOrganiser'))
          : null
    if (
      (guestToUse.totalTicketCount ?? 0) > 1 ||
      guestToUse.tickets?.length > 1
    ) {
      const toAdd = `(${guestToUse.status === 'REQUESTED' ? guestToUse.tickets?.length : guestToUse.totalTicketCount})`
      if (!ticketName) return toAdd
      return ticketName + ` ${toAdd} • ${timestamp}`
    } else {
      return ticketName ? ticketName + ` • ${timestamp}` : timestamp
    }
  }

  const getTimestamp = () => {
    let dateToUse = guestToUse.lastActivityAt
    if (listStatus === 'WAITLIST') {
      dateToUse = guestToUse.waitingListUpdatedAt
    } else if (listStatus === 'HYPE') {
      dateToUse = guestToUse.hypeUpdatedAt
    } else if (listStatus === 'NONE' || dateToUse == null) {
      dateToUse = guestToUse.createdAt
    }
    return dateToUse
  }

  const rightText = () => {
    if (
      guestToUse.status === 'JOINED' &&
      (guestToUse.totalTicketCount ?? 0) > 1
    ) {
      if (guestToUse.activeTicketCount == null)
        return guestToUse.totalTicketCount
      return `${(guestToUse.totalTicketCount ?? 0) - guestToUse.activeTicketCount}/${guestToUse.totalTicketCount}`
    } else if (showRightDetail) {
      if (event.type === 'PINNING' && guestToUse.gaveDateOptions === true) {
        return t('going')
      }
      if (
        (
          [RsvpStatus.JOINED, RsvpStatus.GOING] as (RsvpStatus | null)[]
        ).includes(guestToUse.status)
      ) {
        return t('going')
      }
      if (guestToUse.status === 'REQUESTED') {
        return null
      }
      if (guestToUse.status === 'REQUEST_DENIED') {
        return null
      }
      if (guestToUse.status === 'MAYBE') {
        return `'${t('maybe')}'`
      }
      if (guestToUse.status === 'CANT') {
        return `'${t('cant')}'`
      }
      if (!guestToUse.status) {
        if (guestToUse.hype) {
          return t('hyped')
        } else if (guestToUse.waitingList) {
          return t('waitList')
        } else {
          return t('invited')
        }
      }
    }

    return null
  }

  const onGuestClicked = () => {
    setDetailOpen(true)
  }

  const onApprove = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setApproving(true)
    await apiClient.guest.approve(guest.id)
    setGuestToUse((prev) => ({
      ...prev,
      status: RsvpStatus.JOINED,
      totalTicketCount: prev.tickets?.length,
    }))
    updateStatusCounts(event, dispatch, guestToUse, 'REQUESTED', 'JOINED')
    setApproving(false)
  }

  const onReject = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setRejecting(true)
    await apiClient.guest.reject(guest.id)
    setGuestToUse((prev) => ({
      ...prev,
      status: RsvpStatus.REQUEST_DENIED,
    }))
    updateStatusCounts(
      event,
      dispatch,
      guestToUse,
      'REQUESTED',
      'REQUEST_DENIED',
    )
    setRejecting(false)
  }

  const secondary = secondaryText()
  const right = rightText()

  return (
    <>
      <Row
        onClick={onGuestClicked}
        sx={{
          alignItems: 'center',
          gap: 2,
          p: '16px 0',
          mx: 4,
          '&:not(:last-child)': {
            borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
          },
          cursor: 'pointer',
          ...((showChecked || localGuest.status !== guest.status) && {
            color: (theme) => theme.palette.text.tertiary,
          }),
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Box
            component='img'
            src={
              guestToUse.user?.avatarUrls?.lg ?? guestToUse.avatarUrls?.lg ?? ''
            }
            sx={{
              width: 40,
              height: 40,
              borderRadius: 100,
              ...(localGuest.status !== guest.status && {
                opacity: 0.5,
              }),
            }}
            alt='Avatar'
          />
          {showChecked && (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: 40,
                height: 40,
                borderRadius: 100,
                background: 'rgba(0,82,37,0.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Check sx={{ color: '#fff' }} />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            ...(guestToUse.status === 'REQUEST_DENIED' && {
              color: (theme) => theme.palette.text.tertiary,
            }),
          }}
        >
          <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
            {getGuestName(guestToUse) ?? guestToUse.emailAddress}
          </Typography>
          {secondary && (
            <Typography variant='caption' sx={{ mt: 0.5 }}>
              {secondary}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            alignItems: 'center',
            justifySelf: 'flex-end',
          }}
        >
          {guestToUse.status === 'REQUESTED' && (
            <>
              <Button
                sx={{
                  width: 'auto',
                  height: 32,
                  borderRadius: 100,
                  minWidth: 0,
                  minHeight: 0,
                  border: '1px solid',
                  color: (theme) => theme.palette.red.main,
                }}
                onClick={onReject}
              >
                {rejecting ? <CircularProgress size={20} /> : <Close />}
              </Button>

              <BashButton
                loading={approving}
                height={32}
                type={BashButtonType.WHITE_PRIMARY_BORDERED}
                onClick={onApprove}
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  width: 'auto',
                  p: (theme) => theme.spacing(0, 2),
                }}
              >
                {t('accept')}
              </BashButton>
            </>
          )}

          {guestToUse.status === 'JOINED' && guest.status === 'REQUESTED' && (
            <Typography variant='caption'>{t('approved')}</Typography>
          )}
          {guestToUse.status === 'REQUEST_DENIED' && (
            <Typography variant='caption'>{t('rejected')}</Typography>
          )}

          {right != null && <Typography variant='caption'>{right}</Typography>}
        </Box>
      </Row>
      <GuestInfoSheet
        onClose={() => setDetailOpen(false)}
        open={detailOpen}
        activeInfoGuest={guestToUse}
        setGuest={setLocalGuest}
        refresh={refresh}
      />
    </>
  )
}

export default GuestManagementItem
