import React, { useCallback, useEffect } from 'react'
import { useCurrentEvent } from 'utils/hooks'
import {
  useChainedCommands,
  useExtensionEvent,
  useRemirrorContext,
} from '@remirror/react'
import { extractHref, LinkExtension } from 'remirror/extensions'

// This component resets the content of a Remirror node everytime the descriptionMarkdown updates
// This is necessary, as otherwise the Remirror node isn't aware its content has changed and doesn't rerender
const EventDescriptionMarkdownUpdater = ({}) => {
  const { setContent } = useRemirrorContext()
  const chain = useChainedCommands()
  const event = useCurrentEvent()

  useExtensionEvent(
    LinkExtension,
    'onClick',
    useCallback((e, data) => {
      const ref = extractHref({
        url: data.href,
        defaultProtocol: 'https:',
      })
      window.open(ref, '_blank')
      return true
    }, []),
  )

  // Helper function to identify YouTube links
  const isYouTubeUrl = (url) => {
    return /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/.test(
      url,
    )
  }

  // Extract YouTube video ID
  const getYouTubeId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    )
    return match ? match[1] : null
  }

  // Function to check if read more button should be shown
  const recheckReadMore = () => {
    const eventDescriptionEl = document.getElementById('event-description')
    if (eventDescriptionEl) {
      const descHeight = eventDescriptionEl.clientHeight
      const TEXT_MAX_HEIGHT = 200

      // Find the closest parent with setShowReadMore function
      let customEvent = new CustomEvent('checkForReadMore', {
        bubbles: true,
        detail: { height: descHeight, maxHeight: TEXT_MAX_HEIGHT },
      })

      // Dispatch event so the parent component can handle it
      eventDescriptionEl.dispatchEvent(customEvent)
    }
  }

  useEffect(() => {
    if (event.descriptionMarkdown && event.descriptionMarkdown.length > 0) {
      try {
        // Clear editor content first
        setContent({
          type: 'doc',
          content: [],
        })

        // Simple approach - just use the markdown as is
        setTimeout(() => {
          try {
            chain.insertMarkdown(event.descriptionMarkdown).run()

            // Look for YouTube links after inserting content
            setTimeout(() => {
              const youtubeLinks = document.querySelectorAll(
                '#event-description a[href*="youtube.com/watch"], #event-description a[href*="youtu.be/"]',
              )
              let embedsAdded = false

              youtubeLinks.forEach((link) => {
                const videoId = getYouTubeId(link.href)
                if (videoId) {
                  // Create YouTube embed
                  const iframe = document.createElement('iframe')
                  iframe.width = '560'
                  iframe.height = '315'
                  iframe.src = `https://www.youtube.com/embed/${videoId}`
                  iframe.frameBorder = '0'
                  iframe.allowFullscreen = true
                  iframe.allow =
                    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'

                  // Create wrapper div
                  const wrapper = document.createElement('div')
                  wrapper.className = 'youtube-embed'
                  wrapper.appendChild(iframe)

                  // Replace the link with the embed
                  const parentElement = link.parentNode
                  if (parentElement) {
                    parentElement.insertBefore(wrapper, link)
                    link.style.display = 'none'
                    embedsAdded = true
                  }
                }
              })

              // Wait for iframes to load then trigger a recheck for read more
              if (embedsAdded) {
                setTimeout(() => {
                  recheckReadMore()
                }, 300)
              }
            }, 100)
          } catch (err) {
            console.error('Error inserting markdown:', err)

            // Fallback to basic text insertion without YouTube embeds
            setContent({
              type: 'doc',
              content: [
                {
                  type: 'paragraph',
                  content: [{ type: 'text', text: event.descriptionMarkdown }],
                },
              ],
            })
          }
        }, 10)
      } catch (error) {
        console.error('Error processing markdown content:', error)

        // Ultimate fallback
        setContent({
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: event.descriptionMarkdown }],
            },
          ],
        })
      }
    }
  }, [event.descriptionMarkdown])

  return <div />
}

export default EventDescriptionMarkdownUpdater
