import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Button, Popover, SvgIcon, Typography, useTheme } from '@mui/material'
import EmojiPlus from '../../svg/EmojiPlus'
import dynamic from 'next/dynamic'
import { openSignInModal, openVerifyEmailModal } from '../../actions/modals'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import EmojiResponsesModal from './EmojiResponsesModal'
import EmojiResponseItem from './EmojiResponseItem'
import { decapitalizeFirstLetter } from '../../shared-components/utils/copy'
import { useUser } from '../../utils/userFunctions'
import { mdiChatOutline } from '@mdi/js'
import { Lock } from '@mui/icons-material'
import FlatButton from '../../shared-components/buttons/FlatButton'
import BottomSheet from './BottomSheet'
import {
  getActiveGuestWithoutNew,
  getCurrentEvent,
} from '../../selectors/event'
import { Theme } from 'emoji-picker-react'

const Picker = dynamic(
  () => {
    return import('emoji-picker-react')
  },
  { ssr: false },
)

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    gap: theme.spacing(0.5),
    flexWrap: 'wrap',
    flexShrink: 0,
  },
  singleLine: {
    flexWrap: 'nowrap',
  },
  response: {
    flexShrink: 0,
    minWidth: 0,
    padding: '6px 8px',
    border: '1px solid ' + theme.palette.grey[300],
    borderRadius: 100,
    color: theme.palette.grey[800],
    height: 28,
  },
  responseLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  gotItButton: {
    height: 40,
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  lockedTop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  lockIcon: {
    width: 24,
    height: 24,
  },
  lockedDivider: {
    width: '100%',
    height: '1px',
    background: theme.palette.grey[200],
    [theme.breakpoints.up('md')]: {
      height: 0,
    },
  },
}))

export interface EmojiResponse {
  emoji: string
  count: number
  reactedByYou: boolean
  users: {
    id: number
    name: string
    firstName: string
    lastName: string
    avatarUrls: {
      lg: string | null
    }
  }[]
}

interface EmojiResponsesProps {
  responses: EmojiResponse[]
  onReact: (emoji: string) => void
  onRemoveReaction: (emoji: string) => void
  onComment: () => void | undefined
  commentCount: number | undefined
  limit: number
  singleLine: boolean
  className: never
}

const EmojiResponses = ({
  responses,
  onReact,
  onRemoveReaction,
  onComment,
  commentCount,
  limit = 13,
  singleLine = false,
  className,
}: EmojiResponsesProps) => {
  const { t } = useTranslation('common')
  const { user, ready, isLoggedIn } = useUser()
  const [selectedResponse, setSelectedResponse] =
    useState<EmojiResponse | null>(responses[0])
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const event = useSelector(getCurrentEvent)
  const open = Boolean(anchorEl)
  const [modalOpen, setModalOpen] = useState<EmojiResponse | null>(null)
  const popoverRef = useRef(null)
  const [lockedOpen, setLockedOpen] = useState(false)
  const activeGuest = useSelector(getActiveGuestWithoutNew)
  const theme = useTheme()
  const onOpenPicker = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!isLoggedIn) {
      if (!activeGuest) {
        setLockedOpen(true)
      } else {
        dispatch(openVerifyEmailModal())
      }
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event?.stopPropagation()
    setAnchorEl(null)
  }

  useEffect(() => {
    if (popoverRef.current) {
      setTimeout(function () {
        popoverRef.current.updatePosition()
      }, 200)
    }
  }, [anchorEl])

  const onEmojiPicked = (emoji) => {
    if (!isLoggedIn) {
      dispatch(openSignInModal(null, null, false))
      return
    }
    onReact(emoji)
  }

  const removeEmoji = (emoji) => {
    onRemoveReaction(emoji)
  }

  const onEmojiClicked = (event, emoji) => {
    if (
      responses.some(
        (response) => response.emoji === emoji && response.reactedByYou,
      )
    ) {
      removeEmoji(emoji)
    } else {
      onEmojiPicked(emoji)
    }
  }

  const openModal = (response) => {
    setSelectedResponse(response)
    setModalOpen(response)
  }

  const EmojiPicker = () => {
    return (
      <Picker
        previewConfig={{ showPreview: false }}
        skinTonesDisabled
        autoFocusSearch={false}
        onEmojiClick={(emoji) => {
          handleClose(null)
          onEmojiClicked(null, emoji.emoji)
        }}
        theme={theme.palette.mode === 'dark' ? Theme.DARK : Theme.LIGHT}
      />
    )
  }

  return (
    <div
      className={cx(classes.root, className, singleLine && classes.singleLine)}
    >
      {responses.slice(0, limit).map((r, index) => (
        <EmojiResponseItem
          key={index}
          onEmojiClicked={(e) => onEmojiClicked(e, r.emoji)}
          onTooltipClicked={() => openModal(r)}
          response={r}
        />
      ))}
      {responses.length > limit && (
        <Button
          className={cx(classes.response, classes.responseLabel)}
          style={{
            background: event.theme.colourScheme
              ? theme.palette.background.paper
              : undefined,
            color:
              event.theme.colourScheme || event.theme.darkMode
                ? theme.palette.text.primary
                : null,
          }}
          onClick={() => openModal(responses[0])}
        >
          {limit > 3 && (
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {responses.length - limit} {decapitalizeFirstLetter(t('more'))}
            </Typography>
          )}
          {limit <= 3 && (
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              +{responses.length - limit}
            </Typography>
          )}
        </Button>
      )}
      <Button
        className={cx(classes.response, classes.responseLabel)}
        style={{
          background: event.theme.colourScheme
            ? theme.palette.background.paper
            : undefined,
          color:
            event.theme.colourScheme || event.theme.darkMode
              ? theme.palette.text.primary
              : null,
        }}
        onClick={onOpenPicker}
      >
        <EmojiPlus style={{ width: 18, height: 18 }} />
      </Button>
      {onComment && (
        <Button
          className={cx(classes.response, classes.responseLabel)}
          style={{
            background: event.theme.colourScheme
              ? theme.palette.background.paper
              : undefined,
            color:
              event.theme.colourScheme || event.theme.darkMode
                ? theme.palette.text.primary
                : null,
          }}
          onClick={onComment}
        >
          <SvgIcon style={{ width: 18, height: 18 }}>
            <path d={mdiChatOutline} />
          </SvgIcon>
          {commentCount && commentCount > 0 ? (
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {commentCount}
            </Typography>
          ) : null}
        </Button>
      )}
      {/* Comment button */}
      {/*<Button*/}
      {/*  className={classes.response}*/}
      {/*>*/}
      {/*  <SvgIcon style={{ width: 18, height: 18 }}>*/}
      {/*    <path d={mdiChatOutline} />*/}
      {/*  </SvgIcon>*/}
      {/*</Button>*/}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        action={popoverRef}
      >
        <EmojiPicker />
      </Popover>
      <EmojiResponsesModal
        responses={responses}
        onClose={() => setModalOpen(null)}
        open={Boolean(modalOpen)}
        selectedResponse={selectedResponse}
        setSelectedResponse={setSelectedResponse}
      />
      <BottomSheet open={lockedOpen} onClose={() => setLockedOpen(false)}>
        <div className={classes.lockedContainer}>
          <div className={classes.lockedTop}>
            <Lock className={classes.lockIcon} />
            <Typography align='center' variant='subtitle1'>
              {t('lockedDialog.title')}
            </Typography>
          </div>
          <Typography align='center' variant='body2'>
            {t('lockedDialog.commentDescription')}
          </Typography>

          <div className={classes.lockedDivider} />

          <FlatButton
            onClick={() => setLockedOpen(false)}
            color='primary'
            fullWidth
            className={classes.gotItButton}
          >
            {t('okGotIt')}
          </FlatButton>
        </div>
      </BottomSheet>
    </div>
  )
}

export default EmojiResponses
