import Dialog, { DialogProps } from '@mui/material/Dialog'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import FullScreenSheetTransition from './FullScreenSheetTransition'

const FullscreenDialog = (
  props: Omit<DialogProps, 'fullScreen' | 'TransitionComponent'>,
) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      {...props}
      slotProps={{
        ...props.slotProps,
        backdrop: {
          sx: {
            bgcolor: 'background.backdrop',
          },
        },
        paper: {
          ...props.slotProps?.paper,
          sx: [
            (theme) => ({
              backgroundImage: 'none !important', // Disables lighter background in darkmode
              paddingBottom: 'calc(0px + env(safe-area-inset-bottom))',
              transition: 'padding 200ms ease',
              maxWidth: 480,
              width: 1,
              backgroundColor: theme.palette.background.paper,
              border: theme.palette.background.modalBorder,
              borderRadius: 2,
            }),
            // @ts-expect-error sx does exist
            ...(Array.isArray(props.slotProps?.paper?.sx)
              ? // @ts-expect-error sx does exist
                props.slotProps?.paper?.sx
              : // @ts-expect-error sx does exist
                [props.slotProps?.paper?.sx]),
          ],
        },
      }}
      // @ts-expect-error works
      TransitionComponent={FullScreenSheetTransition}
      fullScreen={fullScreen}
    />
  )
}

export default FullscreenDialog
