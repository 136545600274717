import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { OpenInNew } from '@mui/icons-material'
import HostCard from 'components/Page/HostCard'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import { EURO } from '../EventCreation/TicketOption'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  lowJoinButton: {
    height: '40px',
    width: '100%',
  },
  lowJoinDisabled: {
    backgroundColor: theme.palette.grey[300] + ' !important',
  },
  lowJoinWrapper: {
    width: '100%',
    position: 'relative',
  },
  ticketBadge: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    top: '50%',
    left: theme.spacing(1),
    transform: 'translateY(-50%)',
    background: theme.palette.secondary[950],
    padding: theme.spacing('5px', 1),
    gap: '3px',
    color: 'white',
    borderTopLeftRadius: '100px',
    borderBottomLeftRadius: '100px',
  },
  ticketBadgeDisabled: {
    background: theme.palette.grey[600],
  },
  ticketText: {
    fontWeight: 500,
  },
  ticketDot: {
    backgroundColor: 'green',
    borderRadius: '50%',
    width: '8px',
    height: '8px',
  },
  ticketDotRed: {
    backgroundColor: theme.palette.red.main,
  },
  externalTicketInfoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: theme.spacing(2),
    border: '1px solid ' + theme.palette.grey[200],
    borderRadius: '8px',
    background: theme.palette.grey[150],
    fontWeight: 500,
  },
  externalTicketButton: {
    borderRadius: '1000px',
    padding: theme.spacing(1.5),
  },
  joinInfoMessage: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1.5),
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0),
    '& > *': {
      fontWeight: '500 !important',
    },
    marginLeft: -8,
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  goingIcon: {
    color: theme.palette.secondary.main,
  },
  goingButton: {
    width: 40,
    height: 40,
    background: theme.palette.secondary[100],
    '&:hover': {
      background: theme.palette.secondary[200],
    },
  },
  redDot: {
    height: 8,
    width: 8,
    background: theme.palette.red.main,
    borderRadius: '100px',
    marginRight: theme.spacing(2),
  },
  greenDot: {
    height: 8,
    width: 8,
    background: 'green',
    borderRadius: '100px',
    marginRight: theme.spacing(2),
  },
  externalTicketInfoText: {
    fontWeight: 500,
    lineHeight: '21px',
  },
  updatedAtText: {
    fontSize: 10,
  },
  margin: {
    margin: theme.spacing(2),
  },
}))

export const JoinOptionMessage = ({ withMargin }) => {
  const { classes, cx } = useStyles()
  const event = useSelector(getCurrentEvent)

  const ownerHost =
    event.hosts.find((host) => host.role === 'OWNER') ?? event.hosts[0]
  const text = event.myGuest?.joinOption?.text

  if (text == null || text.trim() === '') return null
  return (
    <div
      className={cx(
        classes.externalTicketInfoItem,
        classes.joinInfoMessage,
        withMargin && classes.margin,
      )}
    >
      {ownerHost && (
        <HostCard
          key={ownerHost.id}
          host={ownerHost}
          noBorder
          noFollow
          noSocial
          noBackgroundColor
        />
      )}
      <Typography variant='body2' className={classes.externalTicketInfoText}>
        "{text}"
      </Typography>
    </div>
  )
}

const ExternalTicketsDesktop = () => {
  const { t } = useTranslation('common')
  const { classes } = useStyles()
  const event = useSelector(getCurrentEvent)

  const getExternalTicketText = () => {
    const info = event.externalTicketInfo
    switch (info?.type) {
      case 'LINK_ONLY':
        return null
      // return t('externalTickets.viewInfo')
      case 'NOT_AVAILABLE_YET':
        if (event.externalTicketInfo.availableFrom != null) {
          return t('externalTickets.availableFromDate', {
            date: dayjs(event.externalTicketInfo.availableFrom).format(
              'EEE d MMM HH:mm',
            ),
          })
        } else {
          return t('externalTickets.availableSoon')
        }
      case 'DOOR_SALE':
        if (info.priceRangeMin != null) {
          return t('externalTickets.doorSaleFrom', {
            price: EURO.format(info.priceRangeMin),
          })
        } else {
          return t('externalTickets.doorSale')
        }
      case 'UNAVAILABLE':
        return t('externalTickets.unavailable')
      default:
        if (info?.priceRangeMin != null) {
          return t('externalTickets.availableFromPrice', {
            price: EURO.format(info.priceRangeMin),
          })
        } else {
          return t('externalTickets.available')
        }
    }
  }

  return (
    <div className={classes.root}>
      <JoinOptionMessage />
      {event.externalTicketInfo != null && getExternalTicketText() !== null && (
        <a href={event.externalTicketInfo?.url} target='_blank'>
          <div className={classes.externalTicketInfoItem}>
            <div
              className={
                event.externalTicketInfo?.type === 'REGISTRATION_AVAILABLE'
                  ? classes.greenDot
                  : classes.redDot
              }
            />
            <div>
              <Typography
                variant='body2'
                className={classes.externalTicketInfoText}
              >
                {getExternalTicketText()}
              </Typography>
              {event.externalTicketInfo?.updatedAt && (
                <Typography
                  color='textSecondary'
                  className={classes.updatedAtText}
                  variant='caption'
                >
                  {t('lastUpdatedAt')}:{' '}
                  {dayjs(event.externalTicketInfo?.updatedAt).format(
                    'd MMM HH:mm',
                  )}
                </Typography>
              )}
            </div>
          </div>
        </a>
      )}

      <BashButton
        type={BashButtonType.GREY_LIGHT}
        onClick={null}
        href={event.externalTicketInfo?.url ?? event.sourceUrl}
        target='_blank'
        extraView={<OpenInNew />}
        viewAlignment={ButtonViewAlignment.ALIGN_VIEW_END}
      >
        {t('externalTickets.goToWebsite')}
      </BashButton>
    </div>
  )
}

export default ExternalTicketsDesktop
