import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'underscore'
import { makeStyles } from 'tss-react/mui'
import { CloudUpload } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { changeDraft } from '../../actions/event'
import { useDispatch, useSelector } from 'react-redux'
import FullScreenSheet from './FullScreenSheet'
import { getCurrentEvent } from '../../selectors/event'
import { useDrop } from 'react-dnd'
import DraggableMediaItem from './DraggableMediaItem'
import FlatButton from '../../shared-components/buttons/FlatButton'
import DropZone from '../editEvent/DropZone'
import { useCreationDialogStyles } from '../editEvent/CreationList'

const useImageStyles = makeStyles()((theme) => ({
  root: {
    maxHeight: theme.breakpoints.values.container,
    width: 'auto',
    overflow: 'hidden',
    maxWidth: 580,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      maxHeight: 'none',
    },
  },
  imagesContainer: {
    width: '100%',
    overflowY: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      overflowX: 'hidden',
    },
  },
  imageItem: {
    overflow: 'hidden',
    height: 170,
    width: 170,
    // width: '100%',
    // height: 'auto',
    // border: `1px solid ${theme.palette.divider}`,
    objectFit: 'fill',
    transition: 'opacity 200ms ease',
    borderRadius: 8,
    cursor: 'pointer',
    aspectRatio: '1.5',
    '&:hover': {
      opacity: 0.8,
    },
  },
  scrollContainer: {
    marginLeft: theme.spacing(-3),
    marginRight: -25,
    padding: 0,
  },
  chooseImage: {
    width: 170,
    height: 170,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.palette.grey[150],
    cursor: 'pointer',
    border: `1px dashed ${theme.palette.divider}`,
    transition: 'opacity 200ms ease',
    '&:hover': {
      opacity: 0.6,
    },
    '& > svg': {
      width: 48,
      height: 48,
    },
  },
  mediaImage: {
    width: 170,
    height: 170,
    objectFit: 'cover',
  },
  uploadIcon: {
    color: theme.palette.grey[500],
  },
}))
const ChooseImageModal = ({
  mediaToUse,
  setMedia,
  inputRef,
  onClose,
  onNewFile,
  ...other
}) => {
  const { classes } = useImageStyles()
  const { classes: dialogClasses, cx } = useCreationDialogStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('event')
  const imageUrls = useSelector((state) => state.event.themeImages.list)
  const event = useSelector(getCurrentEvent)
  const media = useMemo(
    () =>
      (
        (mediaToUse ?? event.media)?.filter(
          (m) =>
            m.content.status === 'FINAL' ||
            m.content.status === 'CREATED' ||
            m.newUrl,
        ) ?? []
      ).sort((a, b) => a.position - b.position),
    [event.media, mediaToUse],
  )

  const onChange = (field, value) => {
    dispatch(changeDraft(field, value))
  }

  const onRemoveMediaItem = (item) => {
    setMedia(
      media.map((m) => ({
        ...m,
        removed: m.id === item.id ? !m.removed : m.removed,
      })),
    )
  }

  const findMediaItem = useCallback(
    (id) => {
      const mediaItem = media.filter((c) => c.id === id)[0]
      return {
        mediaItem,
        index: media.indexOf(mediaItem),
      }
    },
    [media],
  )
  const moveMediaItem = useCallback(
    (id, atIndex) => {
      const { mediaItem, index } = findMediaItem(id)

      if (index !== atIndex) {
        const filteredMedia = media.filter((m) => m.id !== id)
        const newMedia = [
          ...filteredMedia.slice(0, atIndex),
          mediaItem,
          ...filteredMedia.slice(atIndex),
        ]

        setMedia(
          newMedia.map((m, idx) => ({
            ...m,
            position: idx,
          })),
        )
      }
    },
    [findMediaItem, media, onChange],
  )
  const [, drop] = useDrop(() => ({ accept: 'MEDIA' }))

  const onSetImageClicked = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const onDefaultImageClicked = (key) => {
    onChange('themeImageKey', key)
    onChange('imageUrls', {
      lg: `${process.env.NEXT_PUBLIC_API_HOST}/events/themeImages/${key}`,
    })
    onChange('imageVersion', Math.random() * 100)
    onChange('duplicateOptions', null)
    onClose()
  }

  return (
    <FullScreenSheet
      onClose={onClose}
      className={classes.root}
      scrollContainerClassName={classes.scrollContainer}
      alignStart
      title={t('event.edit.chooseImage')}
      {...other}
    >
      <div className={classes.imagesContainer} ref={drop}>
        <DropZone
          customAccept='video/mp4, video/quicktime, video/webm, image/jpeg, image/png, image/gif, image/webp'
          multiple
          fileInputRef={inputRef}
          onNewFile={onNewFile}
          className={classes.dropZone}
          withPaste
        >
          <div className={classes.chooseImage} onClick={onSetImageClicked}>
            <CloudUpload className={classes.uploadIcon} />
            <Typography
              variant='button'
              style={{
                textAlign: 'center',
                padding: 4,
              }}
            >
              {t('common:uploadYourFiles')}
            </Typography>
          </div>
        </DropZone>
        {media.length === 0 &&
          map(imageUrls, (url) => (
            <img
              key={url}
              onClick={() => onDefaultImageClicked(url)}
              className={classes.imageItem}
              alt='image'
              src={`${process.env.NEXT_PUBLIC_API_HOST}/events/themeImages/${url}?eventImageSize=md`}
            />
          ))}
        {media.length > 0 &&
          media
            .filter((m) => !m.removed)
            .map((m, index) => (
              <DraggableMediaItem
                onRemove={
                  media.filter((m) => !m.removed).length > 1
                    ? () => onRemoveMediaItem(m)
                    : null
                }
                mediaItem={m}
                key={m.id}
                id={m.id}
                findMediaItem={findMediaItem}
                moveMediaItem={moveMediaItem}
              />
            ))}
      </div>
      <div style={{ margin: 16 }}>
        <FlatButton
          fullWidth
          onClick={() => {
            onClose()
          }}
          className={dialogClasses.saveButton}
        >
          {t('common:done')}
        </FlatButton>
      </div>
    </FullScreenSheet>
  )
}

export default ChooseImageModal
