import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Checkbox, Typography } from '@mui/material'
import QuestionContainer from './QuestionContainer'
import WizardTextField from '../WizardTextField'
import { useTranslation } from 'react-i18next'
import { ChoiceQuestionProps } from './types'

const useStyles = makeStyles()((theme) => ({
  option: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    color: theme.palette.text.tertiary,
    padding: theme.spacing(1),
  },
  optionText: {
    lineHeight: '21px',
    wordBreak: 'break-word',
    maxWidth: '100%',
    textAlign: 'left',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  otherText: {
    marginRight: theme.spacing(1),
  },
}))

const CheckboxQuestion: React.FC<ChoiceQuestionProps> = ({
  title,
  required,
  isError,
  options,
  value,
  onChange,
  allowOther,
  otherValue,
  onOtherChange,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <QuestionContainer title={title} required={required} isError={isError}>
      <div className={classes.optionsContainer}>
        {options.map((option, index) => (
          <div
            key={index}
            className={classes.option}
            onClick={() => onChange(option.id)}
          >
            <Checkbox
              checked={value.includes(option.id)}
              className={classes.checkBox}
              color='primary'
            />
            <Typography variant='body1' className={classes.optionText}>
              {option.translated}
            </Typography>
          </div>
        ))}
        {allowOther && (
          <div className={classes.option}>
            <Checkbox
              checked={otherValue?.checked ?? false}
              className={classes.checkBox}
              color='primary'
              onClick={() => {
                onOtherChange?.({
                  checked: !otherValue?.checked,
                  text: otherValue?.text ?? '',
                })
              }}
            />
            <Typography
              className={classes.otherText}
              variant='body1'
              onClick={() => {
                onOtherChange?.({
                  checked: !otherValue?.checked,
                  text: otherValue?.text ?? '',
                })
              }}
            >
              {t('questions.other')}
            </Typography>
            {otherValue?.checked && (
              <WizardTextField
                placeholder={t('questions.otherHint')}
                value={otherValue.text}
                full
                noMargin
                title=''
                helperText=''
                onSubmit={() => {}}
                className=''
                onClick={() => {}}
                onChange={(e) =>
                  onOtherChange?.({
                    checked: true,
                    text: e.target.value,
                  })
                }
              />
            )}
          </div>
        )}
      </div>
    </QuestionContainer>
  )
}

export default CheckboxQuestion
