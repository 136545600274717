import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import mixpanel from 'shared-components/utils/mixpanel'
import WizardTextField from './WizardTextField'

import {
  getActiveGuestWithoutNew,
  getCurrentEvent,
  getCurrentJoinOption,
  getHasResponded,
} from 'selectors/event'
import {
  editNewGuest,
  onChangeAddGuestFirstName,
  onChangeAddGuestLastName,
  onChangeAddGuestMessage,
  rsvpWaitList,
  setCurrentTicketOption,
  setEmail,
  setNewGuestStatus,
} from 'actions/rsvp'
import MaybeIcon from '../../svg/status/Maybe'
import CantIcon from '../../svg/status/Cant'
import { validate } from 'email-validator'
import { useIsLoggedIn } from '../../utils/hooks'
import apiClient from '../../shared-components/utils/ApiClient'
import { RsvpStatus } from 'types/types'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { CheckCircle, Edit, InfoOutlined, MoreVert } from '@mui/icons-material'
import AddRsvpMessageInput from '../event/posts/AddRsvpMessageInput'
import { rsvpFlow } from '../../actions/event'
import dayjs from 'dayjs'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'
import { useRouter } from 'next/router'
import {
  palettePrimary,
  paletteRed,
  paletteSecondary,
} from 'shared-components/colors'
import EventTickets from '../event/rsvp/EventTickets'
import { openSignInModal } from 'actions/modals'
import {
  CheckboxQuestion,
  DateQuestion,
  NumberQuestion,
  PhoneQuestion,
  QuestionContainer,
  RadioQuestion,
  TextQuestion,
} from './questions'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { defLang } from 'config/i18n'
import { setSnackbar } from 'shared-components/redux/notifications/actions'
import phone from 'phone'
import {
  createDebouncedAddressLookup,
  validateHouseNumber,
  validateZipCode,
} from 'components/OrgSettings/billingUtilites'

const useStyles = makeStyles()((theme) => ({
  root: {
    // padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    // minHeight: 600
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  nameFields: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1.5),
    },
  },
  nameField: {
    marginBottom: 0,
  },
  divider: {
    marginRight: '-24px',
    marginLeft: '-24px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  nextButton: {
    flexGrow: 1,
    height: 40,
  },
  emailField: {},
  statusField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  statusIcon: {
    height: 48,
    width: 48,
    border: '1px solid ' + theme.palette.grey.dark,
    borderRadius: 100,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  goingIcon: {
    color:
      theme.palette.mode === 'dark'
        ? paletteSecondary.dark.main
        : paletteSecondary.light.main,
  },
  goingSelected: {
    background:
      theme.palette.mode === 'dark'
        ? paletteSecondary.dark[100]
        : paletteSecondary.light[100],
    border:
      '2px solid ' +
      (theme.palette.mode === 'dark'
        ? paletteSecondary.dark[800]
        : paletteSecondary.light[800]),
  },
  maybeIcon: {
    color:
      theme.palette.mode === 'dark'
        ? palettePrimary.dark.main
        : palettePrimary.light.main,
    marginRight: '2px',
  },
  maybeSelected: {
    background:
      theme.palette.mode === 'dark'
        ? palettePrimary.dark[100]
        : palettePrimary.light[100],
    border:
      '2px solid ' +
      (theme.palette.mode === 'dark'
        ? palettePrimary.dark[800]
        : palettePrimary.light[800]),
  },
  cantIcon: {
    color:
      theme.palette.mode === 'dark'
        ? paletteRed.dark.main
        : paletteRed.light.main,
  },
  cantSelected: {
    background:
      theme.palette.mode === 'dark'
        ? paletteRed.dark[100]
        : paletteRed.light[100],
    border:
      '2px solid ' +
      (theme.palette.mode === 'dark'
        ? paletteRed.dark[800]
        : paletteRed.light[800]),
  },
  svgIcon: {
    width: '30px',
    height: '30px',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    gap: theme.spacing(8),
  },
  scrollContainer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(2),
    '&:empty': {
      padding: 0,
    },
    paddingBottom: theme.spacing(3),
    borderBottom: '1px solid ' + theme.palette.divider,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  stripe: {
    padding: 4,
  },
  paymentAfter: {
    lineHeight: '21px',
  },
  paymentAfterContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  dividerStripe: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: theme.spacing(2),
  },
  signInContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
  },
  userInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  avatarContainer: {
    width: 52,
    height: 52,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  avatar: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  userTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  marginTop1: {
    marginTop: theme.spacing(1),
  },
  profileInfoContainer: {
    // background: theme.palette.background.paper,
    borderRadius: 8,
  },
  infoList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& li': {
      paddingLeft: theme.spacing(2),
      position: 'relative',
      marginTop: theme.spacing(0.75),
      fontWeight: 500,
      '&::before': {
        content: '"•"',
        position: 'absolute',
        left: 0,
      },
    },
  },
  profileQuestionsContainer: {
    // background: theme.palette.background.paper,
    borderRadius: 8,
    gap: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  profileQuestionsTitle: {
    marginBottom: theme.spacing(2),
  },
  messageContainer: {
    // borderBottom: '1px solid ' + theme.palette.divider,
    // paddingBottom: theme.spacing(3),
  },
  addressFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  postalCodeRow: {
    display: 'flex',
    gap: theme.spacing(2),
    '& > *': {
      flex: 1,
    },
  },
  addressDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid ' + theme.palette.divider,
    paddingTop: theme.spacing(1),
  },
}))

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const InfoStep = ({}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { classes, cx } = useStyles()
  const router = useRouter()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const [loading, setLoading] = useState(false)
  const activeGuest = useSelector(getActiveGuestWithoutNew)
  const user = useSelector((state) => state.user.user)
  const hasResponded = useSelector(getHasResponded)
  const newGuestStatus = useSelector((state) => state.rsvp.newGuest.status)
  const newGuest = useSelector((state) => state.rsvp.newGuest)
  const addGuestName = useSelector((state) => state.rsvp.newGuest.name)
  const addGuestFirstName = useSelector(
    (state) => state.rsvp.newGuest.firstName,
  )
  const addGuestLastName = useSelector((state) => state.rsvp.newGuest.lastName)
  const addGuestEmail = useSelector((state) => state.rsvp.newGuest.emailAddress)
  const addGuestMessage = useSelector((state) => state.rsvp.newGuest.message)
  const waitingList = useSelector((state) => state.rsvp.newGuest.waitingList)
  const rsvpPending = useSelector((state) => state.event.rsvpPending)
  const inWidget = useSelector((state) => state.rsvp.inWidget)
  const selectedTicketOption = useSelector(getCurrentJoinOption)
  const [invalidFirstName, setInvalidFirstName] = useState(false)
  const [invalidLastName, setInvalidLastName] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const isLoggedIn = useIsLoggedIn()
  const hasBashTickets = event.joinOptions && event.joinOptions.length > 0
  const allFree =
    (selectedTicketOption?.ticketOption.price === 0 ||
      selectedTicketOption?.ticketOption.price == null) &&
    !selectedTicketOption?.ticketOption?.chooseYourOwnPrice

  const [postText, setPostText] = useState('')
  const [_postMedia, setPostMedia] = useState([])
  const postMedia = _postMedia.filter((m) => !m.removed)
  const [rememberInfo, setRememberInfo] = useState(false)

  const availableTickets = event.joinOptions?.filter((t) => t.available)
  const noAvailableTickets = !availableTickets || availableTickets.length === 0
  const ticketsAvailableLater = event.joinOptions?.find(
    (e) => e.availableFrom && dayjs(e.availableFrom).isAfter(dayjs()),
  )

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const profileQuestions =
    selectedTicketOption?.ticketOption?.questions?.filter(
      (q) => q.question?.profileType,
    )

  const profileQuestionsNotAnswered = profileQuestions?.filter(
    (q) => q.question?.profileType && !q.question?.hasProfileInfo,
  )

  const showAddMessage =
    !noAvailableTickets &&
    activeGuest?.status !== newGuestStatus &&
    event.invitedBy != null

  const handleError = (error) => {
    setLoading(false)
    setErrorMessage(error.message)
  }

  const nameInputRef = useRef()
  const emailInputRef = useRef()
  const messageInputRef = useRef()

  // Helper function to safely get the ID from idInfo
  const getQuestionId = (question) => {
    return question?.question?.idInfo?.id || question?.question?.idInfo?.uid
  }

  const [localResponses, setLocalResponses] = useState(() => {
    // Find the country question ID if it exists
    const countryQuestion = profileQuestions?.find(
      (q) => q.question?.profileType === 'COUNTRY',
    )

    // Set initial state with NL as default country if the question exists
    return countryQuestion
      ? {
          [getQuestionId(countryQuestion)]: {
            text: 'NL',
          },
        }
      : {}
  })
  const [erroredQuestions, setErroredQuestions] = useState([])

  const [showAddressEdit, setShowAddressEdit] = useState(false)
  const [loadingAddress, setLoadingAddress] = useState(false)

  // Modify onChangeText to handle address lookup with ID helper
  const onChangeText = (questionId, text) => {
    setErroredQuestions((prev) =>
      prev.filter((id) => String(id) !== String(questionId)),
    )
    setLocalResponses((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        text,
      },
    }))

    // Find question by ID with string comparison
    const question = profileQuestions.find(
      (q) => String(getQuestionId(q)) === String(questionId),
    )
    if (!question) return

    if (!question?.question?.profileType) return

    if (
      ['POSTAL_CODE', 'HOUSE_NUMBER'].includes(question.question.profileType)
    ) {
      const postalCodeQuestion = profileQuestions?.find(
        (q) => q.question?.profileType === 'POSTAL_CODE',
      )
      const houseNumberQuestion = profileQuestions?.find(
        (q) => q.question?.profileType === 'HOUSE_NUMBER',
      )

      const postalCodeId = getQuestionId(postalCodeQuestion)
      const houseNumberId = getQuestionId(houseNumberQuestion)

      const postalCode =
        question.question.profileType === 'POSTAL_CODE'
          ? text
          : localResponses[postalCodeId]?.text
      const houseNumber =
        question.question.profileType === 'HOUSE_NUMBER'
          ? text
          : localResponses[houseNumberId]?.text

      if (validateZipCode(postalCode) && validateHouseNumber(houseNumber)) {
        setLoadingAddress(true)
        debouncedLookupAddress(postalCode, houseNumber)
      }
    }
  }

  // Update address lookup callback to use ID helper
  const debouncedLookupAddress = React.useCallback(
    createDebouncedAddressLookup(
      (street, city) => {
        setLoadingAddress(false)
        setLocalResponses((prev) => {
          // Find the questions for street and city
          const streetQuestion = profileQuestions?.find(
            (q) => q.question?.profileType === 'STREET',
          )
          const cityQuestion = profileQuestions?.find(
            (q) => q.question?.profileType === 'CITY',
          )
          const countryQuestion = profileQuestions?.find(
            (q) => q.question?.profileType === 'COUNTRY',
          )

          const streetId = getQuestionId(streetQuestion)
          const cityId = getQuestionId(cityQuestion)
          const countryId = getQuestionId(countryQuestion)

          return {
            ...prev,
            ...(streetId
              ? {
                  [streetId]: {
                    ...prev[streetId],
                    text: street,
                  },
                }
              : {}),
            ...(cityId
              ? {
                  [cityId]: {
                    ...prev[cityId],
                    text: city,
                  },
                }
              : {}),
            ...(countryId
              ? {
                  [countryId]: {
                    ...prev[countryId],
                    text: 'NL',
                  },
                }
              : {}),
          }
        })
      },
      () => {
        setLoadingAddress(false)
        setShowAddressEdit(true)
        // Clear street and city fields
        setLocalResponses((prev) => {
          const streetQuestion = profileQuestions?.find(
            (q) => q.question?.profileType === 'STREET',
          )
          const cityQuestion = profileQuestions?.find(
            (q) => q.question?.profileType === 'CITY',
          )

          const streetId = getQuestionId(streetQuestion)
          const cityId = getQuestionId(cityQuestion)

          return {
            ...prev,
            ...(streetId
              ? {
                  [streetId]: {
                    ...prev[streetId],
                    text: '',
                  },
                }
              : {}),
            ...(cityId
              ? {
                  [cityId]: {
                    ...prev[cityId],
                    text: '',
                  },
                }
              : {}),
          }
        })
      },
    ),
    [profileQuestionsNotAnswered],
  )

  // Update effect for country changes to use ID helper
  useEffect(() => {
    const countryQuestion = profileQuestionsNotAnswered?.find(
      (q) => q.question?.profileType === 'COUNTRY',
    )
    if (!countryQuestion) return

    const countryId = getQuestionId(countryQuestion)
    const countryResponse = localResponses[countryId]?.text

    if (countryResponse && countryResponse !== 'NL') {
      setShowAddressEdit(true)
    }
  }, [localResponses, profileQuestionsNotAnswered])

  const theme = useTheme()
  useEffect(() => {
    if (elements) {
      elements.update({
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimary: theme.palette.primary.main,
            colorBackground: theme.palette.background.input,
            colorText: theme.palette.text.primary,
            colorDanger: theme.palette.error.main,
            fontFamily: theme.typography.fontFamily,
            borderRadius: '6px',
            fontSizeBase: '16px',
          },
          rules: {
            '.Input': {
              border: 'none',
              boxShadow: 'none',
              fontSize: '16px',
              padding: theme.spacing(1, 1.5),
              height: '40px',
            },
            '.Input:focus': {
              boxShadow: 'none',
            },
            '.Input--invalid': {
              boxShadow: 'none',
            },
            '.Label': {
              fontWeight: 500,
              fontSize: '14px',
              color: theme.palette.text.primary,
            },
            '.Error': {
              fontSize: '12px',
              color: theme.palette.error.main,
            },
          },
        },
      })
    }
  }, [elements, theme])

  // Update validateProfileQuestions to use ID helper
  const validateProfileQuestions = () => {
    if (!profileQuestions) return true

    const newErroredQuestions = profileQuestions
      .map((q) => {
        if (
          !q.required ||
          q.question.hasProfileInfo ||
          q.question.type === 'EMAIL_ADDRESS'
        )
          return null

        const questionId = getQuestionId(q)
        const response = localResponses[questionId]
        if (!response) return questionId
        const questionType = q.question.type

        const isValid =
          (questionType === 'SHORT_TEXT' && response.text) ||
          (questionType === 'LONG_TEXT' && response.text) ||
          (questionType === 'PHONE_NUMBER' &&
            phone(response.text ?? '').isValid) ||
          (questionType === 'DATE' && response.text) ||
          (questionType === 'NUMBER' && response.text) ||
          (questionType === 'RADIO' && (response.options?.length ?? 0) > 0) ||
          (questionType === 'RADIO_OTHER' &&
            ((response.options?.length ?? 0) > 0 ||
              (response.other?.checked && response.other?.text))) ||
          (questionType === 'CHECKBOX' &&
            (response.options?.length ?? 0) > 0) ||
          (questionType === 'CHECKBOX_OTHER' &&
            ((response.options?.length ?? 0) > 0 ||
              (response.other?.checked && response.other?.text))) ||
          (questionType === 'BOOLEAN' && response.response !== undefined)

        return isValid ? null : questionId
      })
      .filter((id) => id !== null)

    setErroredQuestions(newErroredQuestions)
    return newErroredQuestions.length === 0
  }

  useEffect(() => {
    if (
      addGuestMessage === '' &&
      activeGuest?.message != null &&
      activeGuest?.message !== ''
    ) {
      onChangeMessage({
        target: {
          value: activeGuest?.message,
        },
      })
    }
  }, [])

  const onSubmitName = useCallback(() => {
    mixpanel.people.set('$name', addGuestName)

    focusEmail()
  }, [addGuestName, dispatch])

  const onSubmitEmail = () => {
    if (event.chatGroup && event.chatGroup.state !== 'ADMIN_ONLY') {
      focusMessage()
    } else {
      onRsvp()
    }
  }

  const newGuestStatusIsCantOrMaybe =
    newGuestStatus === RsvpStatus.CANT || newGuestStatus === RsvpStatus.MAYBE
  const cantOrMaybe =
    activeGuest?.status === RsvpStatus.CANT ||
    activeGuest?.status === RsvpStatus.MAYBE
  const approvalRequired =
    selectedTicketOption?.ticketOption?.approvalRequired &&
    !newGuestStatusIsCantOrMaybe
  const showStripe =
    selectedTicketOption?.ticketOption?.approvalRequired &&
    ((selectedTicketOption?.price != null && selectedTicketOption?.price > 0) ||
      (selectedTicketOption.ticketOption?.price != null &&
        selectedTicketOption.ticketOption?.price > 0)) &&
    !newGuestStatusIsCantOrMaybe

  // Add refs for each section that might need scrolling
  const profileQuestionsRef = useRef()
  const nameFieldsRef = useRef()
  const emailFieldRef = useRef()

  // Helper function to scroll to an element
  const scrollToElement = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const onRsvp = async () => {
    if (!isLoggedIn) {
      if (!addGuestFirstName || addGuestFirstName.trim().length === 0) {
        setInvalidFirstName(true)
        scrollToElement(nameFieldsRef)
        return
      }
      if (!addGuestLastName || addGuestLastName.trim().length === 0) {
        setInvalidLastName(true)
        scrollToElement(nameFieldsRef)
        return
      }
      if (!addGuestEmail || !validate(addGuestEmail)) {
        setInvalidEmail(true)
        scrollToElement(emailFieldRef)
        return
      }
    }

    if (!validateProfileQuestions() && !newGuestStatusIsCantOrMaybe) {
      dispatch(setSnackbar('notified', t('fillAllQuestions')))
      // Scroll to the first errored question
      if (erroredQuestions.length > 0) {
        scrollToElement(profileQuestionsRef)
      }
      return
    }

    const responsesDto = newGuestStatusIsCantOrMaybe
      ? []
      : transformLocalResponsesToDtoWithIds(
          selectedTicketOption,
          localResponses,
          profileQuestions,
        )

    console.log(responsesDto, 'responsesDto')

    const existingResponses = newGuest.formResponses || []

    dispatch(
      editNewGuest({
        ...newGuest,
        media: postMedia,
        postText: postText !== '' || postMedia?.length > 0 ? postText : null,
        formResponses: [...existingResponses, ...responsesDto],
      }),
    )

    let stripeCustomerId = null
    let needsStripeSetup = false

    // Only create Stripe setup intent if we're not remembering info
    // If remembering info, we'll create the setup intent after sign-in
    if (showStripe && !rememberInfo) {
      setLoading(true)

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit()
      if (submitError) {
        handleError(submitError)
        return
      }

      const { clientSecret, customerId } = await apiClient.ticket.setupIntent(
        event.myGuest?.code,
        addGuestEmail,
        addGuestName,
      )
      stripeCustomerId = customerId

      if (!stripeCustomerId) {
        // Error?
        return
      }

      // Confirm the SetupIntent using the details collected by the Payment Element
      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
      })
      console.log(error)
    } else if (showStripe && rememberInfo) {
      // Flag that we need to set up Stripe after sign-in
      needsStripeSetup = true

      // Validate the payment form before proceeding
      const { error: submitError } = await elements.submit()
      if (submitError) {
        console.log(submitError)
        handleError(submitError)
        return
      }
    }

    apiClient.general.analyticsEventEvent('complete-rsvp-info', {
      eventId: event.id,
      userId: user?.id,
    })

    setLoading(true)

    if (waitingList) {
      await dispatch(
        rsvpWaitList('web_event_page', isLoggedIn ? null : event.myGuest?.code),
      )
    } else if (rememberInfo) {
      await dispatch(
        openSignInModal(
          addGuestEmail,
          null,
          true,
          stripeCustomerId,
          true,
          false,
          needsStripeSetup ? { elements, stripe } : null,
        ),
      )
    } else {
      await dispatch(
        rsvpFlow(newGuestStatus, 'web_event_page', stripeCustomerId),
      )
    }

    setLoading(false)
    // dispatch(closeAndResetRsvpModal())
    // if (
    //   newGuestStatus === 'GOING' &&
    //   (!hasBashTickets || (hasBashTickets && allFree))
    // ) {
    //   dispatch(showConfetti())
    // }
  }

  const onChangeMessage = useCallback(
    (event) => {
      dispatch(onChangeAddGuestMessage(event.target.value))
    },
    [dispatch],
  )

  const onChangeStatus = useCallback(
    (status) => {
      dispatch(setNewGuestStatus(status))
    },
    [dispatch],
  )

  const focusEmail = () => {
    emailInputRef.current && emailInputRef.current.focus()
  }
  const focusMessage = () => {
    messageInputRef.current &&
      messageInputRef.current.scrollIntoView({ behavior: 'smooth' })
    messageInputRef.current && messageInputRef.current.focus()
  }

  const showChevron = () => {
    if (selectedTicketOption) {
      const { ticketOption } = selectedTicketOption
      if (
        (ticketOption.price != null && ticketOption.price > 0) ||
        (ticketOption.chooseYourOwnPrice &&
          (selectedTicketOption.price ?? 0) > 0)
      ) {
        return 'right'
      }
    }
    return 'none'
  }

  const buttonText = () => {
    if (newGuestStatusIsCantOrMaybe) {
      return t('respondRsvp')
    }
    if (selectedTicketOption) {
      const { ticketOption } = selectedTicketOption
      if (ticketOption.approvalRequired) {
        return t('actionJoinRequest')
      }
      if (
        (ticketOption.price != null && ticketOption.price > 0) ||
        (ticketOption.chooseYourOwnPrice &&
          (selectedTicketOption.price ?? 0) > 0)
      ) {
        return selectedTicketOption.quantity > 1
          ? t('getTickets')
          : t('getTicket')
      }
    }
    return t('respondRsvp')
  }

  const onChangeFirstName = useCallback(
    (event) => {
      setInvalidFirstName(false)
      const newFirstName = event.target.value
      dispatch(onChangeAddGuestFirstName(newFirstName))
      dispatch(
        editNewGuest({
          ...newGuest,
          firstName: newFirstName,
          lastName: addGuestLastName || '',
          name: `${newFirstName} ${addGuestLastName || ''}`.trim(),
        }),
      )
    },
    [dispatch, addGuestLastName, newGuest],
  )

  const onChangeLastName = useCallback(
    (event) => {
      setInvalidLastName(false)
      const newLastName = event.target.value
      dispatch(onChangeAddGuestLastName(newLastName))
      dispatch(
        editNewGuest({
          ...newGuest,
          firstName: addGuestFirstName || '',
          lastName: newLastName,
          name: `${addGuestFirstName || ''} ${newLastName}`.trim(),
        }),
      )
    },
    [dispatch, addGuestFirstName, newGuest],
  )

  const onChangeEmail = useCallback(
    (event) => {
      setInvalidEmail(false)
      dispatch(setEmail(event.target.value))
    },

    [dispatch],
  )

  const showTicketSelection =
    cantOrMaybe && newGuestStatus === 'JOINED' && hasBashTickets && !showStripe

  const infoReceived =
    selectedTicketOption?.ticketOption?.questions?.filter(
      (q) => q.question?.hasProfileInfo,
    ) ?? []

  const [prevUser, setPrevUser] = useState(null)

  // Effect to handle join options reload when user signs in
  useEffect(() => {
    const handleUserChange = async () => {
      // Only proceed if user just signed in (prev was null, now we have user)
      if (user?.id && event?.id && selectedTicketOption?.ticketOption?.id) {
        try {
          const joinOptions = await apiClient.event.getJoinOptions(
            event.id,
            event.code,
          )
          // Find the same join option that was previously selected
          const updatedJoinOption = joinOptions?.find(
            (option) => option.id === selectedTicketOption?.ticketOption?.id,
          )
          if (updatedJoinOption) {
            dispatch(
              setCurrentTicketOption({
                ...selectedTicketOption,
                ticketOption: updatedJoinOption,
              }),
            )
          }
        } catch (error) {
          console.error('Failed to load join options:', error)
        }
      }
      setPrevUser(user)
    }

    handleUserChange()
  }, [
    user,
    event?.id,
    prevUser,
    dispatch,
    selectedTicketOption?.ticketOption?.id,
  ])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onRsvp()
    }
  }

  const renderAddressFields = (questions) => {
    // Get all address-related questions (both with and without profile info)
    const addressProfileTypes = [
      'COUNTRY',
      'POSTAL_CODE',
      'HOUSE_NUMBER',
      'HOUSE_NUMBER_ADDITION',
      'STREET',
      'CITY',
      'STATE',
      'PROVINCE',
    ]

    // Find all address questions from the ticket option
    const allAddressQuestions =
      selectedTicketOption?.ticketOption?.questions?.filter((q) =>
        addressProfileTypes.includes(q.question?.profileType),
      ) || []

    // If no address questions exist at all, return null
    if (allAddressQuestions.length === 0) return null

    // Check if ANY address questions need to be answered (don't have profile info)
    const anyAddressQuestionsNeedAnswer = allAddressQuestions.some(
      (q) => !q.question?.hasProfileInfo,
    )

    // If ALL address questions already have profile info, don't show the fields
    if (!anyAddressQuestionsNeedAnswer) return null

    // From here, we know we need to show address fields
    const countryQuestion = questions.find(
      (q) => q.question?.profileType === 'COUNTRY',
    )
    const postalCodeQuestion = questions.find(
      (q) => q.question?.profileType === 'POSTAL_CODE',
    )
    const houseNumberQuestion = questions.find(
      (q) => q.question?.profileType === 'HOUSE_NUMBER',
    )
    const houseNumberAdditionQuestion = questions.find(
      (q) => q.question?.profileType === 'HOUSE_NUMBER_ADDITION',
    )
    const streetQuestion = questions.find(
      (q) => q.question?.profileType === 'STREET',
    )
    const cityQuestion = questions.find(
      (q) => q.question?.profileType === 'CITY',
    )
    const stateQuestion = questions.find(
      (q) => q.question?.profileType === 'STATE',
    )
    const provinceQuestion = questions.find(
      (q) => q.question?.profileType === 'PROVINCE',
    )

    // Use ID helper to get IDs
    const countryId = getQuestionId(countryQuestion)
    const postalCodeId = getQuestionId(postalCodeQuestion)
    const houseNumberId = getQuestionId(houseNumberQuestion)
    const houseNumberAdditionId = getQuestionId(houseNumberAdditionQuestion)
    const streetId = getQuestionId(streetQuestion)
    const cityId = getQuestionId(cityQuestion)
    const stateId = getQuestionId(stateQuestion)
    const provinceId = getQuestionId(provinceQuestion)

    const hasValidAddress =
      (streetId ? localResponses[streetId]?.text : false) &&
      (cityId ? localResponses[cityId]?.text : false) &&
      (postalCodeId ? localResponses[postalCodeId]?.text : false) &&
      (houseNumberId ? localResponses[houseNumberId]?.text : false) &&
      (countryId ? localResponses[countryId]?.text : false)

    // Always show extra fields if special address fields are present
    const hasSpecialAddressFields =
      houseNumberAdditionId !== undefined ||
      stateId !== undefined ||
      provinceId !== undefined

    const shouldShowExtraFields = showAddressEdit || hasSpecialAddressFields

    const getCommonProps = (questionId) => {
      const question = questions.find(
        (q) => String(getQuestionId(q)) === String(questionId),
      )
      return {
        id: questionId,
        title:
          question.question.translations?.[defLang] ??
          question.question.caption,
        required: question.required,
        isError: erroredQuestions.includes(questionId),
      }
    }

    return (
      <div className={classes.addressFieldsContainer}>
        {postalCodeId && houseNumberId && (
          <div className={classes.postalCodeRow}>
            <TextQuestion
              {...getCommonProps(postalCodeId)}
              type='SHORT_TEXT'
              value={localResponses[postalCodeId]?.text ?? ''}
              onChange={(value) => onChangeText(postalCodeId, value)}
              placeholder={'1234AB'}
              loading={loadingAddress}
              done={validateZipCode(localResponses[postalCodeId]?.text)}
            />
            <TextQuestion
              {...getCommonProps(houseNumberId)}
              type='SHORT_TEXT'
              value={localResponses[houseNumberId]?.text ?? ''}
              onChange={(value) => onChangeText(houseNumberId, value)}
              placeholder={'123'}
              loading={loadingAddress}
              done={validateHouseNumber(localResponses[houseNumberId]?.text)}
            />
          </div>
        )}

        {hasValidAddress && !showAddressEdit && !hasSpecialAddressFields ? (
          <div className={classes.addressDisplay}>
            <Typography variant='body2'>
              {`${localResponses[streetId]?.text} ${localResponses[houseNumberId]?.text}${localResponses[houseNumberAdditionId]?.text ? ' ' + localResponses[houseNumberAdditionId]?.text : ''}, ${localResponses[postalCodeId]?.text} ${localResponses[cityId]?.text}${localResponses[stateId]?.text ? ', ' + localResponses[stateId]?.text : ''}${localResponses[provinceId]?.text ? ', ' + localResponses[provinceId]?.text : ''}, ${localResponses[countryId]?.text || ''}`}
            </Typography>
            <IconButton onClick={() => setShowAddressEdit(true)}>
              <Edit sx={{ fontSize: 20 }} />
            </IconButton>
          </div>
        ) : shouldShowExtraFields ? (
          <>
            {countryId !== undefined && (
              <TextQuestion
                {...getCommonProps(countryId)}
                type='SHORT_TEXT'
                value={localResponses[countryId]?.text ?? 'NL'}
                onChange={(value) => onChangeText(countryId, value)}
                placeholder={'NL'}
              />
            )}
            {streetId !== undefined && (
              <TextQuestion
                {...getCommonProps(streetId)}
                type='SHORT_TEXT'
                value={localResponses[streetId]?.text ?? ''}
                onChange={(value) => onChangeText(streetId, value)}
                placeholder={'Main Street'}
              />
            )}
            {cityId !== undefined && (
              <TextQuestion
                {...getCommonProps(cityId)}
                type='SHORT_TEXT'
                value={localResponses[cityId]?.text ?? ''}
                onChange={(value) => onChangeText(cityId, value)}
                placeholder={'Amsterdam'}
              />
            )}
            {houseNumberAdditionId !== undefined && (
              <TextQuestion
                {...getCommonProps(houseNumberAdditionId)}
                type='SHORT_TEXT'
                value={localResponses[houseNumberAdditionId]?.text ?? ''}
                onChange={(value) => onChangeText(houseNumberAdditionId, value)}
                placeholder={'A'}
              />
            )}
            {stateId !== undefined && (
              <TextQuestion
                {...getCommonProps(stateId)}
                type='SHORT_TEXT'
                value={localResponses[stateId]?.text ?? ''}
                onChange={(value) => onChangeText(stateId, value)}
                placeholder={t('questions.shortHint')}
              />
            )}
            {provinceId !== undefined && (
              <TextQuestion
                {...getCommonProps(provinceId)}
                type='SHORT_TEXT'
                value={localResponses[provinceId]?.text ?? ''}
                onChange={(value) => onChangeText(provinceId, value)}
                placeholder={t('questions.shortHint')}
              />
            )}
          </>
        ) : null}
      </div>
    )
  }

  // Get all profile questions (including those with profile info)
  const allProfileQuestions =
    selectedTicketOption?.ticketOption?.questions?.filter(
      (q) => q.question?.profileType,
    ) || []

  // Check if we have enough fields for a complete address
  const hasAllAddressFields =
    allProfileQuestions.some((q) => q.question?.profileType === 'COUNTRY') &&
    allProfileQuestions.some(
      (q) => q.question?.profileType === 'POSTAL_CODE',
    ) &&
    allProfileQuestions.some((q) => q.question?.profileType === 'CITY') &&
    allProfileQuestions.some((q) => q.question?.profileType === 'STREET') &&
    allProfileQuestions.some((q) => q.question?.profileType === 'HOUSE_NUMBER')

  // Add this new function to InfoStep component
  const transformLocalResponsesToDtoWithIds = (
    selectedJoinOption,
    localResponses,
    questionsToAnswer,
  ) => {
    return Object.keys(localResponses)
      .map((key) => {
        // Find the question by ID, converting both to strings for comparison
        const currentQuestion = questionsToAnswer.find((q) => {
          const questionId = getQuestionId(q)
          // Compare as strings to avoid type mismatches
          return String(questionId) === String(key)
        })

        // Skip if question not found
        if (!currentQuestion) {
          console.log(
            `Question with ID ${key} not found - type mismatch or question removed`,
          )
          return null
        }

        const questionType = currentQuestion.question.type

        let newResponseValue = null

        const responseOptions = localResponses[key].options ?? []

        if (questionType === 'NUMBER' || questionType === 'DATE') {
          newResponseValue = localResponses[key]?.text ?? ''
        } else if (
          questionType === 'SHORT_TEXT' ||
          questionType === 'LONG_TEXT'
        ) {
          newResponseValue = localResponses[key]?.text ?? ''
        } else if (questionType === 'BOOLEAN') {
          newResponseValue = localResponses[key]?.response ?? false
        } else if (questionType === 'RADIO' || questionType === 'CHECKBOX') {
          newResponseValue = responseOptions
        } else if (questionType === 'RADIO_OTHER') {
          if (localResponses[key]?.other?.checked) {
            newResponseValue = [localResponses[key]?.other?.text ?? '']
          } else {
            newResponseValue = responseOptions
          }
        } else if (questionType === 'CHECKBOX_OTHER') {
          if (localResponses[key]?.other?.checked) {
            newResponseValue = [
              ...responseOptions,
              localResponses[key]?.other?.text ?? '',
            ]
          } else {
            newResponseValue = responseOptions
          }
        } else if (
          questionType === 'PHONE_NUMBER' ||
          questionType === 'EMAIL_ADDRESS'
        ) {
          const localResponse = localResponses[key]
          newResponseValue = localResponse?.text ?? localResponse?.value ?? ''
        }

        return {
          idInfo: currentQuestion.question.idInfo,
          response: newResponseValue,
        }
      })
      .filter((response) => response !== null) // Filter out any null responses for questions not found
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={defLang}>
      <div className={classes.root} onKeyPress={handleKeyPress}>
        {cantOrMaybe && (
          <div className={classes.statusContainer}>
            <div
              className={classes.statusField}
              onClick={() =>
                onChangeStatus(approvalRequired ? 'REQUESTED' : 'JOINED')
              }
            >
              <div
                className={cx(
                  classes.statusIcon,
                  (newGuestStatus === 'JOINED' ||
                    newGuestStatus === 'REQUESTED') &&
                    classes.goingSelected,
                )}
              >
                <SvgIcon
                  component={CheckCircle}
                  className={cx(classes.goingIcon, classes.svgIcon)}
                />
              </div>
              <Typography variant='body2'>{t('going')}</Typography>
            </div>
            <div
              className={classes.statusField}
              onClick={() => onChangeStatus('MAYBE')}
            >
              <div
                className={cx(
                  classes.statusIcon,
                  newGuestStatus === 'MAYBE' && classes.maybeSelected,
                )}
              >
                <SvgIcon
                  component={MaybeIcon}
                  className={cx(classes.maybeIcon, classes.svgIcon)}
                />
              </div>
              <Typography variant='body2'>{t('maybe')}</Typography>
            </div>
            <div className={classes.statusField}>
              <div
                className={classes.statusField}
                onClick={() => onChangeStatus('CANT')}
              >
                <div
                  className={cx(
                    classes.statusIcon,
                    newGuestStatus === 'CANT' && classes.cantSelected,
                  )}
                >
                  <SvgIcon
                    component={CantIcon}
                    className={cx(classes.cantIcon, classes.svgIcon)}
                  />
                </div>
                <Typography variant='body2'>{t('cantGo')}</Typography>
              </div>
            </div>
          </div>
        )}
        <div className={classes.scrollContainer}>
          {inWidget && (
            <div className={classes.titleContainer}>
              <Typography variant='h6'>{t('personalProfile')}</Typography>
              <Typography variant='body2'>
                {t('questions.listCaption')}
              </Typography>
            </div>
          )}
          {!isLoggedIn && !activeGuest?.status ? (
            <div className={classes.signInContainer}>
              <Typography
                variant='body2'
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  dispatch(openSignInModal(null, null, false, null, true, null))
                }
              >
                <span style={{ color: palettePrimary.light.main }}>
                  {t('signIn')}
                </span>{' '}
                {t('toPreFillProfile')}
              </Typography>
            </div>
          ) : isLoggedIn ? (
            <Box sx={{}}>
              <div className={classes.userInfoContainer}>
                <div className={classes.avatarContainer}>
                  <img
                    src={user?.avatarUrls.lg}
                    alt='Profile'
                    className={classes.avatar}
                  />
                </div>
                <div className={classes.userTextContainer}>
                  <Typography variant='body2' color='textSecondary'>
                    {t('signedInAs')}
                  </Typography>
                  <Typography
                    variant='body1'
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {user?.name}
                  </Typography>
                </div>
              </div>
            </Box>
          ) : null}
          {showAddMessage && (
            <div className={classes.messageContainer}>
              <AddRsvpMessageInput
                text={postText}
                setText={setPostText}
                media={postMedia}
                setMedia={setPostMedia}
                status={newGuestStatus}
              />
            </div>
          )}

          {selectedTicketOption?.ticketOption?.questions?.some(
            (q) => q.question?.hasProfileInfo,
          ) &&
            !newGuestStatusIsCantOrMaybe && (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: showAddMessage ? 3 : 0,
                  borderTop: showAddMessage
                    ? '1px solid ' + theme.palette.divider
                    : 'none',
                })}
              >
                <div className={classes.profileInfoContainer}>
                  <Typography variant='body1' color='textSecondary'>
                    <b>
                      {event.hosts
                        .map((host) => host.model.name)
                        .join(event.hosts.length > 2 ? ', ' : ' & ')}
                    </b>{' '}
                    {t('willReceive')}:
                  </Typography>
                  <ul className={classes.infoList}>
                    {(() => {
                      const addressFields = infoReceived.filter((q) =>
                        [
                          'COUNTRY',
                          'CITY',
                          'POSTAL_CODE',
                          'HOUSE_NUMBER',
                          'STREET',
                        ].includes(q.question?.profileType),
                      )

                      const otherFields = infoReceived.filter(
                        (q) =>
                          ![
                            'COUNTRY',
                            'CITY',
                            'POSTAL_CODE',
                            'HOUSE_NUMBER',
                            'STREET',
                          ].includes(q.question?.profileType),
                      )

                      return [
                        ...(addressFields.length > 0
                          ? [
                              {
                                question: {
                                  caption: t('newCreation.questions.address'),
                                },
                              },
                            ]
                          : []),
                        ...otherFields,
                      ].map((q, index) => (
                        <li key={index}>
                          <Typography variant='body2' color='textSecondary'>
                            {q.question?.translations?.[defLang] ??
                              q.question?.caption}
                          </Typography>
                        </li>
                      ))
                    })()}
                  </ul>
                </div>
                <div>
                  <IconButton
                    aria-controls='change-profile'
                    aria-haspopup='true'
                    onClick={handleMenuClick}
                    size='large'
                    sx={{
                      marginTop: -1.5,
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id='change-profile'
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      href={'/me/settings/details'}
                      target='_blank'
                      onClick={handleMenuClose}
                      component='a'
                    >
                      {t('editProfile')}
                    </MenuItem>
                  </Menu>
                </div>
              </Box>
            )}

          {!hasResponded && !isLoggedIn && (
            <div ref={nameFieldsRef} className={classes.nameFields}>
              <WizardTextField
                title={t('firstName') + '*'}
                placeholder={t('firstNamePlaceholder')}
                value={addGuestFirstName}
                onChange={onChangeFirstName}
                onSubmit={onSubmitName}
                className={cx(
                  classes.nameField,
                  !showAddMessage && classes.marginTop1,
                )}
                inputRef={nameInputRef}
                helperText={
                  invalidFirstName ? t('invalidFirstName') : undefined
                }
                autoFocus={true}
              />
              <WizardTextField
                title={t('lastName') + '*'}
                placeholder={t('lastNamePlaceholder')}
                value={addGuestLastName}
                onChange={onChangeLastName}
                onSubmit={onSubmitName}
                className={cx(
                  classes.nameField,
                  !showAddMessage && classes.marginTop1,
                )}
                helperText={invalidLastName ? t('invalidLastName') : undefined}
              />
            </div>
          )}

          {!hasResponded && !isLoggedIn && (
            <div ref={emailFieldRef}>
              <WizardTextField
                title={t('emailAddressRequired')}
                placeholder={t('emailExample')}
                value={addGuestEmail}
                full
                helperText={invalidEmail ? t('invalidEmail') : undefined}
                type='email'
                onChange={onChangeEmail}
                onSubmit={onSubmitEmail}
                className={classes.emailField}
                inputRef={emailInputRef}
                inputProps={{
                  autocomplete: 'email',
                }}
              />
            </div>
          )}

          {profileQuestionsNotAnswered &&
            profileQuestionsNotAnswered.length > 0 &&
            !newGuestStatusIsCantOrMaybe && (
              <div
                ref={profileQuestionsRef}
                className={classes.profileQuestionsContainer}
              >
                {isLoggedIn && (
                  <Typography
                    variant='body1'
                    sx={(theme) => ({
                      lineHeight: '24px',
                      paddingTop: 3,
                      borderTop: `1px solid ${theme.palette.grey[200]}`,
                    })}
                  >
                    {t('orgNeedsMoreInfo', {
                      orgName: event.hosts
                        .map((host) => host.model.name)
                        .join(event.hosts.length > 2 ? ', ' : ' & '),
                    })}
                  </Typography>
                )}
                {profileQuestionsNotAnswered.map((question) => {
                  // Skip address fields as they're handled separately
                  const addressProfileTypes = [
                    'COUNTRY',
                    'POSTAL_CODE',
                    'HOUSE_NUMBER',
                    'HOUSE_NUMBER_ADDITION',
                    'STREET',
                    'CITY',
                    'STATE',
                    'PROVINCE',
                  ]

                  if (
                    addressProfileTypes.includes(
                      question.question?.profileType,
                    ) &&
                    hasAllAddressFields
                  ) {
                    return null
                  }

                  const questionId = getQuestionId(question)
                  const questionType = question.question.type
                  const commonProps = {
                    id: questionId,
                    title:
                      question.question.translations?.[defLang] ??
                      question.question.caption,
                    required: question.required,
                    isError: erroredQuestions.includes(questionId),
                  }

                  switch (questionType) {
                    case 'SHORT_TEXT':
                    case 'LONG_TEXT':
                      return (
                        <TextQuestion
                          key={questionId}
                          {...commonProps}
                          type={questionType}
                          value={localResponses[questionId]?.text ?? ''}
                          onChange={(value) => onChangeText(questionId, value)}
                          placeholder={t('questions.shortHint')}
                        />
                      )

                    case 'PHONE_NUMBER':
                      return (
                        <PhoneQuestion
                          key={questionId}
                          {...commonProps}
                          value={localResponses[questionId]?.value ?? ''}
                          onChange={(value) => onChangeText(questionId, value)}
                        />
                      )

                    case 'DATE':
                      return (
                        <DateQuestion
                          key={questionId}
                          {...commonProps}
                          value={localResponses[questionId]?.text ?? ''}
                          onChange={(value) => onChangeText(questionId, value)}
                          validations={question.question.validations}
                        />
                      )

                    case 'NUMBER':
                      return (
                        <NumberQuestion
                          key={questionId}
                          {...commonProps}
                          value={localResponses[questionId]?.text ?? ''}
                          onChange={(value) => onChangeText(questionId, value)}
                        />
                      )

                    case 'RADIO':
                    case 'RADIO_OTHER':
                      return (
                        <RadioQuestion
                          key={questionId}
                          {...commonProps}
                          options={question.question.properties ?? []}
                          value={localResponses[questionId]?.options ?? []}
                          onChange={(value) => {
                            setErroredQuestions((prev) =>
                              prev.filter((id) => id !== questionId),
                            )
                            setLocalResponses((prev) => ({
                              ...prev,
                              [questionId]: {
                                ...prev[questionId],
                                options: value ? [value] : [],
                                other: {
                                  ...prev[questionId]?.other,
                                  checked: false,
                                },
                              },
                            }))
                          }}
                          allowOther={questionType === 'RADIO_OTHER'}
                          otherValue={localResponses[questionId]?.other}
                          onOtherChange={(other) => {
                            setErroredQuestions((prev) =>
                              prev.filter((id) => id !== questionId),
                            )
                            setLocalResponses((prev) => ({
                              ...prev,
                              [questionId]: {
                                ...prev[questionId],
                                other,
                              },
                            }))
                          }}
                        />
                      )

                    case 'CHECKBOX':
                    case 'CHECKBOX_OTHER':
                      return (
                        <CheckboxQuestion
                          key={questionId}
                          {...commonProps}
                          options={question.question.properties ?? []}
                          value={localResponses[questionId]?.options ?? []}
                          onChange={(value) => {
                            setErroredQuestions((prev) =>
                              prev.filter((id) => id !== questionId),
                            )
                            setLocalResponses((prev) => {
                              const prevResponse = prev[questionId]
                              const contains = (
                                prevResponse?.options ?? []
                              ).includes(value)
                              return {
                                ...prev,
                                [questionId]: {
                                  ...prev[questionId],
                                  options: contains
                                    ? (prevResponse?.options ?? []).filter(
                                        (opt) => opt !== value,
                                      )
                                    : [
                                        ...(prev[questionId]?.options ?? []),
                                        value,
                                      ],
                                },
                              }
                            })
                          }}
                          allowOther={questionType === 'CHECKBOX_OTHER'}
                          otherValue={localResponses[questionId]?.other}
                          onOtherChange={(other) => {
                            setErroredQuestions((prev) =>
                              prev.filter((id) => id !== questionId),
                            )
                            setLocalResponses((prev) => ({
                              ...prev,
                              [questionId]: {
                                ...prev[questionId],
                                other,
                              },
                            }))
                          }}
                        />
                      )

                    case 'BOOLEAN':
                      return (
                        <QuestionContainer
                          key={questionId}
                          {...commonProps}
                          isBoolean
                          booleanValue={
                            localResponses[questionId]?.response ?? false
                          }
                          onBooleanChange={() => {
                            setErroredQuestions((prev) =>
                              prev.filter((id) => id !== questionId),
                            )
                            setLocalResponses((prev) => {
                              const prevValue =
                                prev[questionId]?.response ?? false
                              return {
                                ...prev,
                                [questionId]: {
                                  ...prev[questionId],
                                  response: !prevValue,
                                },
                              }
                            })
                          }}
                        />
                      )

                    default:
                      return null
                  }
                })}

                {/* Render address fields */}
                {renderAddressFields(profileQuestions)}
              </div>
            )}

          {showStripe && (
            <div className={classes.stripe}>
              <PaymentElement
                options={{
                  paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
                }}
              />
              {/* {errorMessage && <div>{errorMessage}</div>} */}
              <Divider className={classes.dividerStripe} />
              <div className={classes.paymentAfterContainer}>
                <InfoOutlined className={classes.infoIcon} />
                <Typography className={classes.paymentAfter} variant='body2'>
                  {t('paymentAfterAccept')}
                </Typography>
              </div>
            </div>
          )}
          {!isLoggedIn && !activeGuest?.status && !inWidget && (
            <Box
              onClick={() => setRememberInfo(!rememberInfo)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
              }}
            >
              <Checkbox size='small' checked={rememberInfo} />
              <Typography
                variant='body2'
                sx={{
                  color: 'text.secondary',
                  mt: 1,
                }}
              >
                <Trans
                  i18nKey='rememberInfoForFuture'
                  t={t}
                  components={{
                    span: <span style={{}} />,
                  }}
                />
              </Typography>
            </Box>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          {showTicketSelection && (
            <EventTickets
              selectedRsvpStatus={newGuestStatus}
              setSelectedRsvpStatus={onChangeStatus}
              noPadding
            />
          )}
          {!showTicketSelection && (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              })}
            >
              <BashButton
                type={
                  event.theme.colourScheme || event.theme.darkMode
                    ? BashButtonType.PRIMARY
                    : BashButtonType.SECONDARY_DARK
                }
                onClick={() => onRsvp()}
                className={classes.lowJoinButton}
                loading={loading || rsvpPending}
              >
                {buttonText()}
              </BashButton>
            </Box>
          )}
          {/*{inWidget && (*/}
          {/*  <Typography variant='body2' className={classes.continueInBashText}>*/}
          {/*    s*/}
          {/*  </Typography>*/}
          {/*)}*/}
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default InfoStep
