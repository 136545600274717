import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { QRCode } from 'react-qrcode-logo'
import Typography from '@mui/material/Typography'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import { Download } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { getCurrentEvent, getCurrentEventLink } from '../../selectors/event'
import { useTranslation } from 'react-i18next'
import Column from '../Column'
import Row from '../Row'
import { Box } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  qrcodeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  downloadQrcodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
}))

const EmbedEventQR = () => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  const eventUrl = useSelector(getCurrentEventLink)
  const event = useSelector(getCurrentEvent)
  const firstHost = event.hosts && event.hosts[0]
  const hostAvatar = firstHost?.model?.avatarUrls?.lg

  const downloadCode = () => {
    const canvas = document.getElementById('qrCode')
    if (canvas) {
      const pngUrl = canvas
        // @ts-expect-error is defined
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = `${event.name ?? 'qrCode'}.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  return (
    <Column
      sx={{
        gap: 2,
      }}
    >
      <Typography
        variant='subtitle1'
        sx={{
          color: 'grey.800',
        }}
      >
        Event QR code
      </Typography>
      <Row
        sx={{
          bgcolor: 'grey.150',
          alignItems: 'center',
          justifyContent: 'center',
          py: 2.5,
        }}
      >
        <Box>
          <div style={{ position: 'absolute', display: 'none' }}>
            <QRCode
              value={eventUrl}
              size={800}
              logoImage={hostAvatar}
              logoHeight={200}
              logoWidth={200}
              logoOpacity={1}
              id='qrCode'
              enableCORS={process.env.NEXT_PUBLIC_ENVIRONMENT_STAGE !== 'local'} // enabling CORS, this is the thing that will bypass that DOM check
              // enableCORS
              logoPadding={24}
              ecLevel={'M'}
            />
          </div>
          <QRCode
            value={eventUrl}
            size={275}
            logoImage={hostAvatar}
            logoHeight={68}
            logoWidth={68}
            logoOpacity={1}
            enableCORS={process.env.NEXT_PUBLIC_ENVIRONMENT_STAGE !== 'local'} // enabling CORS, this is the thing that will bypass that DOM check
            // enableCORS
            logoPadding={8}
            ecLevel={'M'}
          />
        </Box>
      </Row>

      <Row
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <BashButton
          extraView={<Download />}
          viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
          type={BashButtonType.PRIMARY}
          onClick={downloadCode}
        >
          {t('download')}
        </BashButton>
      </Row>
    </Column>
  )
}

export default EmbedEventQR
