import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Checkbox, Typography } from '@mui/material'
import QuestionContainer from './QuestionContainer'
import { useTranslation } from 'react-i18next'
import { EmailQuestionProps } from './types'

const useStyles = makeStyles()((theme) => ({
  emailToggleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  emailToggle: {
    // marginLeft: theme.spacing(-1),
  },
  optionText: {
    marginTop: theme.spacing(1),
    lineHeight: '21px',
  },
}))

const EmailQuestion: React.FC<EmailQuestionProps> = ({
  title,
  required,
  isError,
  emailAccess,
  onEmailChange,
  organiserName,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')

  return (
    <QuestionContainer title={title} required={required} isError={isError}>
      <div className={classes.emailToggleContainer} onClick={onEmailChange}>
        <Checkbox
          className={classes.emailToggle}
          checked={emailAccess}
          color='primary'
        />
        <Typography className={classes.optionText} variant='body1'>
          {t('questions.agreeEmail', { name: organiserName })}
        </Typography>
      </div>
    </QuestionContainer>
  )
}

export default EmailQuestion
