import React, { useContext } from 'react'
import { Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { appContext } from './App'

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: theme.spacing(0),
    position: 'relative',
    transition: 'all 100ms',
    marginTop: theme.spacing(1.5),
    // padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: theme.palette.grey.main + ' solid 1px',
    [theme.breakpoints.down(509)]: {
      borderRadius: 0,
      borderTop: theme.palette.grey.main + ' solid 1px',
      borderBottom: theme.palette.grey.main + ' solid 1px',
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  mobilePreview: {
    margin: theme.spacing(1, 0),
    borderRadius: 0,
    borderTop: theme.palette.grey.main + ' solid 1px',
    borderBottom: theme.palette.grey.main + ' solid 1px',
    borderLeft: 'none',
    borderRight: 'none',
    marginTop: theme.spacing(3),
  },
}))

const ContentBlock = ({
  children,
  className,
  forwardRef,
  style,
  display = true,
  ...props
}) => {
  const previewType = useContext(appContext).previewType || null

  const { classes, cx } = useStyles()
  if (!display) return null
  return (
    <Paper
      ref={forwardRef}
      className={cx(
        classes.root,
        className,
        previewType === 'mobile' && classes.mobilePreview,
      )}
      style={style}
      elevation={0}
      {...props}
    >
      {children}
    </Paper>
  )
}

export default ContentBlock
