import {
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import apiClient from '../../../shared-components/utils/ApiClient'
import {
  ArrowBack,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  ChevronLeft,
} from '@mui/icons-material'
import InfiniteScroll from 'react-infinite-scroller'
import { guestManagementBreakpoint } from './GuestManagementModal'
import { getGuestName } from '../../../shared-components/utils/eventDisplay'
import dayjs from 'dayjs'

const shiftResponseCountPoint = 60000

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  header: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: 64,
    padding: theme.spacing(2, 4, 2, 4),
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      borderBottom: '1px solid ' + theme.palette.grey[200],
    },
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(3),
    top: '50%',
    transform: 'translateY(-50%)',
    minWidth: 0,
    borderRadius: 100,
    padding: 8,
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      left: theme.spacing(1),
    },
  },
  responseCount: {
    position: 'absolute',
    right: theme.spacing(4),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.text.tertiary,
    [theme.breakpoints.down(shiftResponseCountPoint)]: {
      right: 0,
      top: 0,
      transform: 'none',
      position: 'relative',
    },
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.grey[200],
    flexShrink: 0,
  },
  answersContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(0, 4, 2, 4),
    overflowY: 'auto',
    [theme.breakpoints.down(guestManagementBreakpoint)]: {
      padding: theme.spacing(0, 2, 2, 2),
    },
  },
  answer: {
    padding: theme.spacing(2),
    borderRadius: 8,
    border: '1px solid ' + theme.palette.grey[200],
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexShrink: 0,
  },
  userAvatar: {
    width: 24,
    height: 24,
    borderRadius: 100,
    border: '1px solid ' + theme.palette.grey[200],
  },
  userName: {
    fontWeight: 500,
  },
  response: {
    lineHeight: '21px',
    wordBreak: 'break-word',
    textAlign: 'right',
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    borderRadius: 8,
    backgroundColor: theme.palette.grey[150],
    flexShrink: 0,
  },
}))

const ANSWERS_PAGE_SIZE = 10

const GuestManagementQuestionAnswers = ({ question, onBack }) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(
    theme.breakpoints.down(guestManagementBreakpoint),
  )
  const shiftResponseCount = true
  const router = useRouter()
  const event = useSelector(getCurrentEvent)
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [counts, setCounts] = useState([])
  const [loadingCounts, setLoadingCounts] = useState(false)

  console.log(question)

  const loadCounts = async () => {
    if (loadingCounts) return
    setCounts([])
    setLoadingCounts(true)
    if (['RADIO', 'CHECKBOX'].includes(question?.type)) {
      const newCounts = await apiClient.event.getQuestionResponseCounts(
        question.question.idInfo.id,
      )
      setCounts(newCounts)
    }
    setLoadingCounts(false)
  }

  useEffect(() => {
    loadCounts()
  }, [])

  const questionText = () => {
    if (question == null) return ''
    if (question.type === 'EMAIL_ADDRESS') {
      return t('questions.emailAddress')
    } else if (question.type === 'PHONE_NUMBER') {
      return t('questions.phoneNumberQuestion')
    } else {
      return question.caption
    }
  }

  const loadMore = async () => {
    setLoading(true)
    const loadPage = Math.floor(answers.length / ANSWERS_PAGE_SIZE)
    const newItems = await apiClient.event.getQuestionResponses(
      question.question.idInfo.id,
      event.id,
      loadPage,
      ANSWERS_PAGE_SIZE,
    )
    // const newItems = await apiClient.organisation.getEvents(page.id, loadPage, DIGEST_SIZE)
    setHasMore(newItems.length === ANSWERS_PAGE_SIZE)
    setAnswers((prev) => [...prev, ...newItems])
    setLoading(false)
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Button className={classes.backButton} onClick={onBack}>
          {!isMobile && <ChevronLeft />}
          {isMobile && <ArrowBack />}
        </Button>
        <Typography
          noWrap
          variant={isMobile ? 'h6' : 'body1'}
          style={{ fontWeight: 500, maxWidth: '80%' }}
        >
          {questionText()}
        </Typography>
        {!shiftResponseCount && question.responseCount > 0 && (
          <Typography varian='body2' className={classes.responseCount}>
            {question.responseCount === 1
              ? t('questions.response1')
              : t('questions.responseCount', {
                  count: question.responseCount,
                })}
          </Typography>
        )}
      </div>
      <div className={classes.answersContainer}>
        {shiftResponseCount && question.responseCount > 0 && (
          <Typography varian='body2' className={classes.responseCount}>
            {question.responseCount === 1
              ? t('questions.response1')
              : t('questions.responseCount', {
                  count: question.responseCount,
                })}
          </Typography>
        )}

        {counts.map((count, index) => (
          <div key={index} className={classes.count}>
            <Typography variant='body2'>{`${count.option}`}</Typography>
            <Typography style={{ fontWeight: 500 }} variant='body2'>
              {count.count}
            </Typography>
          </div>
        ))}

        {['RADIO', 'CHECKBOX'].includes(question?.type) && (
          <>
            <div className={classes.divider} style={{ margin: '24px 0px' }} />
            <Typography variant='body1' style={{ fontWeight: 500 }}>
              {t('guestManagement.individualAnswers')}
            </Typography>
          </>
        )}

        <InfiniteScroll
          loadMore={loadMore}
          useWindow={false}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible',
            gap: '16px',
          }}
          hasMore={
            (answers.length / ANSWERS_PAGE_SIZE) % 1 === 0 &&
            !loading &&
            hasMore
          }
          // loader={<div />}
        >
          {answers.map((answer, index) => (
            <div key={answer.id} className={classes.answer}>
              <div className={classes.userContainer}>
                <img
                  alt={'Guest avatar'}
                  src={
                    answer.guest?.user?.avatarUrls.sm ??
                    answer.guest?.avatarUrls?.sm
                  }
                  className={classes.userAvatar}
                />
                <Typography variant='body2' className={classes.userName}>
                  {getGuestName(answer.guest)}
                </Typography>
              </div>
              {answer.response.type === 'BOOLEAN' && (
                <>
                  {answer.response.model.boolean === true && (
                    <CheckBoxOutlined color='primary' />
                  )}
                  {answer.response.model.boolean !== true && (
                    <CheckBoxOutlineBlank />
                  )}
                </>
              )}
              {answer.response.type !== 'BOOLEAN' && (
                <Typography className={classes.response} variant='body2'>
                  “
                  {answer.response?.model?.text ??
                    answer.response?.model?.value ??
                    answer.response?.model?.number ??
                    answer.response?.model?.options?.join(', ') ??
                    (answer.response?.model?.date
                      ? dayjs(answer.response?.model?.date).format('DD/MM/YYYY')
                      : '')}
                  ”
                </Typography>
              )}
            </div>
          ))}
          {answers.length < 1 && !loading && (
            <div className={classes.answer}>
              <Typography className={classes.response} variant='body2'>
                {t('questions.noAnswers')}
              </Typography>
            </div>
          )}
          {loading && <CircularProgress />}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default GuestManagementQuestionAnswers
