import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { CircularProgress } from '@mui/material'
import QuestionContainer from './QuestionContainer'
import WizardTextField from '../WizardTextField'
import { TextQuestionProps } from './types'
import { Check } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  loadingIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '25%',
    transform: 'translateY(-50%)',
    width: 20,
    height: 20,
  },
  doneIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    width: 20,
    height: 20,
    color: theme.palette.success.main,
  },
  inputContainer: {
    position: 'relative',
  },
}))

const TextQuestion: React.FC<TextQuestionProps> = ({
  title,
  required,
  isError,
  type,
  value,
  onChange,
  placeholder,
  loading,
  done,
}) => {
  const { classes } = useStyles()

  return (
    <QuestionContainer title={title} required={required} isError={isError}>
      <div className={classes.inputContainer}>
        <WizardTextField
          title=''
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          type={type === 'NUMBER' ? 'number' : 'text'}
          multiline={type === 'LONG_TEXT'}
          rows={type === 'LONG_TEXT' ? 4 : 1}
          onSubmit={() => {}}
          className=''
          noMargin
          full
          onClick={() => {}}
          helperText=''
        />
        {loading && (
          <CircularProgress size={20} className={classes.loadingIcon} />
        )}
        {done && !loading && <Check className={classes.doneIcon} />}
      </div>
    </QuestionContainer>
  )
}

export default TextQuestion
