import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import GuestAvatarBadge from '../../shared-components/avatar/GuestAvatarBadge'
import apiClient from '../../shared-components/utils/ApiClient'
import EmojiResponses from '../common/EmojiResponses'
import { getCurrentUser } from 'selectors/event'
import { useSelector } from 'react-redux'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  name: {
    fontWeight: 500,
    lineHeight: '1rem',
  },
  verifiedIcon: {
    width: 16,
    height: 16,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  message: {
    lineHeight: '1.3125rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    webkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  emojiResponse: {
    marginLeft: 'auto',
    justifyContent: 'flex-end',
  },
}))

const SocialProofItem = ({ item, onChange }) => {
  const { classes } = useStyles()
  const { t } = useTranslation('common')
  const ownUser = useSelector(getCurrentUser)
  const name = item.model.guest.user?.name

  const onReact = (emoji) => {
    apiClient.event.reactActivityLog(item.id, emoji)
    const index = item.emojiResponses.findIndex(
      (response) => response.emoji === emoji,
    )
    if (index !== -1) {
      item.emojiResponses[index].reactedByYou = true // Set reactedByYou to true
      item.emojiResponses[index].count += 1 // Increment the count
      item.emojiResponses[index].users.push(ownUser) // Add the current user to the users array
    } else {
      item.emojiResponses.push({
        emoji: emoji,
        users: [ownUser], // Add the current user to the users array
        reactedByYou: true, // Set reactedByYou to true
        count: 1, // Initialize count to 1
      })
    }
    onChange({ ...item }) // Update the post state
  }
  const onRemoveReaction = (emoji) => {
    apiClient.event.removeReactionActivityLog(item.id, emoji)
    const index = item.emojiResponses.findIndex(
      (response) => response.emoji === emoji,
    )
    if (index !== -1) {
      if (item.emojiResponses[index].count === 1) {
        item.emojiResponses.splice(index, 1) // Remove the emoji response
      } else {
        item.emojiResponses[index].reactedByYou = false
        item.emojiResponses[index].count -= 1
        item.emojiResponses[index].users = item.emojiResponses[
          index
        ].users.filter((user) => user.id !== ownUser?.id)
      }
      onChange({ ...item }) // Update the post state
    }
  }

  return (
    <div className={classes.root}>
      <GuestAvatarBadge
        guest={item.model.guest}
        hype={item.type === 'HYPE'}
        size='small'
      />
      <Typography noWrap className={classes.name} variant='body2'>
        {name}
      </Typography>

      <EmojiResponses
        responses={item.emojiResponses}
        onReact={onReact}
        onRemoveReaction={onRemoveReaction}
        className={classes.emojiResponse}
        limit={3}
        singleLine={true}
      />
    </div>
  )
}

export default SocialProofItem
