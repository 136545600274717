import React from 'react'
import { Tooltip } from '@mui/material'
import Row from '../Row'

interface Props {
  children: React.ReactElement
  title: string
  shortcut: string
}

const ShortcutTooltip = ({ title, shortcut, children }: Props) => {
  return (
    <Tooltip
      title={
        <Row
          sx={{
            alignItems: 'center',
          }}
        >
          {title}
          <Row
            sx={{
              ml: 0.5,
              bgcolor: 'grey.700',
              p: 0.5,
              borderRadius: 0.5,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {shortcut}
          </Row>
        </Row>
      }
    >
      {children}
    </Tooltip>
  )
}

export default ShortcutTooltip
