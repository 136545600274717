import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import EventSection from './EventSection'
import {
  formatShortNumber,
  formatShortNumberFixed,
} from '../../utils/eventFunctions'
import SocialProofModal from './SocialProofModal'
import SlotCounter from 'react-slot-counter'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  avatar: {
    width: 24,
    height: 24,
    objectFit: 'cover',
    borderRadius: '100px',
    border: `1px solid ${theme.palette.divider}`,
  },
  avatarSmall: {
    width: 18,
    height: 18,
  },
  firstAvatar: {},
  secondAvatar: {
    marginLeft: '-8px',
  },
  text: {
    marginLeft: theme.spacing(1),
  },
  textSmall: {
    fontSize: '10px',
    marginLeft: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '11.93px',
    letterSpacing: '0.2px',
    fontWeight: 500,
  },
}))

export const EventSocialProofContent = ({
  event,
  className,
  small,
  superSmall,
}) => {
  const { t } = useTranslation('common')
  const proof = event.socialProof
  const { classes, cx } = useStyles()

  const avatarUrl = (guest) =>
    guest.user?.avatarUrls?.sm ?? guest.avatarUrls?.sm

  const getText = () => {
    const friendCount = proof.friendCount
    const textUserLength = proof.textUsers?.length ?? 0
    const countToShow = friendCount - textUserLength
    const firstName =
      textUserLength > 0
        ? (proof.textUsers[0].user?.firstName ??
          proof.textUsers[0].firstName ??
          proof.textUsers[0].name)
        : null
    const secondName =
      textUserLength > 1
        ? (proof.textUsers[1].user?.firstName ??
          proof.textUsers[1].firstName ??
          proof.textUsers[1].name)
        : null

    if (textUserLength === 0) return null

    if (friendCount === 1)
      return {
        stringId: 'respondedNamesLong.one',
        values: { firstName: firstName },
      }
    if (friendCount === 2)
      return {
        stringId: 'respondedNamesLong.two',
        values: { firstName: firstName, secondName: secondName },
      }
    if (friendCount > 2)
      if (small) {
        return {
          stringId: 'respondedNamesLong.othersSmall',
          values: {
            firstName: firstName,
            count: countToShow + 1,
          },
        }
      } else {
        return {
          stringId: 'respondedNamesLong.others',
          values: {
            firstName: firstName,
            secondName: secondName,
            count: countToShow,
          },
        }
      }
    return null
  }

  const text = getText()

  if (!text) {
    return null
  }

  return (
    <div className={cx(classes.root, className)}>
      {proof.avatarUsers.length > 0 && (
        <img
          alt='avatar'
          src={avatarUrl(proof.avatarUsers[0])}
          className={cx(
            classes.avatar,
            classes.firstAvatar,
            small && classes.avatarSmall,
          )}
        />
      )}
      {proof.avatarUsers.length > 1 && (
        <img
          alt='avatar'
          src={avatarUrl(proof.avatarUsers[1])}
          className={cx(
            classes.avatar,
            classes.secondAvatar,
            small && classes.avatarSmall,
          )}
        />
      )}
      {text && !superSmall && (
        <Typography
          variant='body2'
          className={cx(classes.text, small && classes.textSmall)}
        >
          <Trans
            t={t}
            i18nKey={text.stringId}
            values={text.values}
            components={{ 1: <b /> }}
          />
        </Typography>
      )}
    </div>
  )
}

const EventSocialProofCard = ({ desktopMode }) => {
  const event = useSelector(getCurrentEvent)
  const proof = event.socialProof
  const [modalOpen, setModalOpen] = useState(false)

  if (!proof) return null
  if (event.privacyType !== 'PUBLIC') return null
  if (event.hype.hypeScore < 100 && !event.host) return null

  const showHypeScore = event.hype.hypeScore > 100 || event.host

  // Split the formatted number into numbers and letters
  const formattedHypeScore = formatShortNumberFixed(event.hype.hypeScore ?? 0)
  const matches = formattedHypeScore.match(/(\d+\.?\d*)([A-Z]*)/)
  const numericPart = matches ? matches[1] : '0'
  const letterPart = matches ? matches[2] : ''

  return (
    <>
      <EventSection
        customTitle={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant='subtitle2'>
              {`Hype ${showHypeScore ? '· 🔥' : ''}`}
            </Typography>
            {showHypeScore && (
              <Typography
                variant='subtitle2'
                sx={{
                  marginLeft: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    marginBottom: '1px',
                  }}
                >
                  <SlotCounter
                    value={numericPart}
                    sequentialAnimationMode
                    animateOnVisible={false}
                    useMonospaceWidth
                  />
                </span>
                <span
                  style={{
                    lineHeight: 1,
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: '15.27px',
                  }}
                >
                  {letterPart}
                </span>
              </Typography>
            )}
          </Box>
        }
        desktopMode={desktopMode}
        hasContent={(proof.avatarUsers?.length ?? 0) > 0}
        onMoreClicked={
          proof.textUsers.length > 0 ? () => setModalOpen(true) : undefined
        }
        fullClickArea
      >
        {proof.textUsers.length > 0 && (
          <div>
            <EventSocialProofContent event={event} />
          </div>
        )}
      </EventSection>
      <SocialProofModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  )
}

export default EventSocialProofCard
