import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { CSSTransition } from 'react-transition-group'
import cn from 'clsx'

const animDurationMs = 200

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
  },
  transition: {},
  exitTransitionStart: {
    opacity: 1,
  },
  exitTransitionActive: {
    transition: 'opacity 0.05s ease-in-out',
    opacity: 0,
  },
  exitTransitionDone: {
    display: 'none !important',
    opacity: 0,
  },
  enterTransitionStart: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '100%',
  },
  enterTransitionActive: {
    transition: `opacity ${animDurationMs / 1000}s ease-in-out, transform ${animDurationMs / 1000}s ease-in-out`,
    // transition: `all ${animDurationMs / 1000}s ease-in-out`,
    transitionDelay: '0.03s',
    opacity: 1,
    transform: 'scale(1) !important',
    position: 'absolute !important' as any,
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    // ['position']: 'absolute !important'
  },
  enterTransitionStartScale: {
    transform: 'scale(0.98)',
  },
  enterTransitionDone: {
    opacity: 1,
    position: 'relative',
    transform: 'scale(1)',
  },
}))

interface AnimatedContentProps {
  firstContent: React.JSX.Element
  secondContent: React.JSX.Element
  showSecondContent: boolean
  className?: string
  unmountOnExit?: boolean
  animateScale?: boolean
  mountOnEnter?: boolean
  style?: any
}

const AnimatedContent = ({
  firstContent,
  secondContent,
  showSecondContent,
  className,
  unmountOnExit = true,
  animateScale = true,
  mountOnEnter = false,
  style,
}: AnimatedContentProps) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cn(classes.root, className)} style={style}>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        appear
        in={!showSecondContent}
        timeout={animDurationMs}
        classNames={{
          enterActive: classes.enterTransitionActive,
          enterDone: classes.enterTransitionDone,
          enter: cn(
            classes.enterTransitionStart,
            animateScale && classes.enterTransitionStartScale,
          ),
          exit: classes.exitTransitionStart,
          exitActive: classes.exitTransitionActive,
          exitDone: classes.exitTransitionDone,
        }}
      >
        {firstContent}
      </CSSTransition>
      <CSSTransition
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        in={showSecondContent}
        appear
        timeout={animDurationMs}
        classNames={{
          enterActive: classes.enterTransitionActive,
          enterDone: classes.enterTransitionDone,
          enter: cn(
            classes.enterTransitionStart,
            animateScale && classes.enterTransitionStartScale,
          ),
          exit: classes.exitTransitionStart,
          exitActive: classes.exitTransitionActive,
          exitDone: classes.exitTransitionDone,
        }}
      >
        {/*<div*/}
        {/*  className={cn(*/}
        {/*    classes.enterTransitionStart,*/}
        {/*    animateScale && classes.enterTransitionStartScale,*/}
        {/*  )}*/}
        {/*>*/}
        {secondContent}
        {/*</div>*/}
      </CSSTransition>
    </div>
  )
}

export default AnimatedContent
